import React, { useState, useEffect } from "react";
import * as browserImageSize from "browser-image-size";
import { Loading } from "@bigbinary/neeto-icons";
import { createAttachment } from "@/apis/take_header_attachments";
import { showToastrError } from "@/components/commons";

import { HeroImageIcon } from "@/components/commons/PBIcons";
import EmptyTakeHeader from "@/images/empty-take-header.jpg";

const CoverHeader = ({ formik, draftBtnLoader, takeDetail }) => {
  const [initial, setInitial] = useState(true);
  const [coverImage, setCoverImage] = useState();

  useEffect(() => {
    if (!initial && coverImage?.imageLoader) {
      createAttachmentResponse();
    }
  }, [coverImage]);

  const handleChange = (event) => {
    const files = Array.from(event.target.files);

    files.map((file) => {
      let reader = new FileReader();

      browserImageSize(file).then((size) => {
        reader.onload = (e) => {
          let image = {
            ...coverImage,
            file_data: e.target.result,
            file_name: file.name,
            imageLoader: true,
            height: size.height,
            width: size.width,
          };

          setCoverImage(image);
          setInitial(false);
        };
        reader.readAsDataURL(file);
      });
    });
  };

  const createAttachmentResponse = async () => {
    try {
      const { data } = await createAttachment(formik.values.id, {
        attachment: {
          ...coverImage,
          id: null,
        },
      });
      setCoverImage({ ...data.attachment, imageLoader: false });
      formik.resetForm({
        values: { ...formik.values, cover_image_attributes: data.attachment },
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setInitial(true);
    }
  };

  return (
    <div className="relative w-full rounded-lg lg:rounded-2xl">
      <div className="relative h-full w-full rounded-lg object-cover lg:rounded-2xl">
        {(coverImage?.imageLoader || draftBtnLoader) && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-30 transition-all opacity-100 backdrop-blur-sm">
            <Loading size={32} className="animate-spin-slow text-white" />
          </div>
        )}

        <img
          src={
            coverImage?.file_data ||
            coverImage?.src ||
            formik.values.cover_image_attributes?.src ||
            EmptyTakeHeader
          }
          className="h-full w-full object-cover"
        />

        <div className="absolute right-3 bottom-3 cursor-pointer">
          <div className="flex items-center space-x-2.5 px-4 py-1.5 rounded-2xl bg-white shadow-lg">
            <HeroImageIcon size={20} />
            <span className="hidden sm:block text-sm md:text-base">
              Edit cover image
            </span>
            <span className="sm:hidden text-sm md:text-base">Edit</span>
          </div>

          <input
            id="file_data"
            name="file_data"
            type="file"
            className={`h-full w-full absolute opacity-0 top-0 left-0`}
            onChange={(event) => handleChange(event)}
            accept="image/png, image/gif, image/jpeg, image/webp, image/jpg, image/avif"
          />
        </div>
      </div>
    </div>
  );
};

export default CoverHeader;
