import React from "react";
import Flatpickr from "react-flatpickr";
import { Modal, Typography } from "@bigbinary/neetoui";
import { CloseCircle } from "@bigbinary/neeto-icons";

import { Input } from "@/components/commons/SettingsFormUI";
import { Button } from "@/components/commons/AppFormUI";

const ScheduleFormModal = ({
  scheduleFormOpen,
  setScheduleFormOpen,
  formik,
  handleSubmit,
  scheduleBtnLoader,
}) => {
  const flatPickrOption = {
    enableTime: true,
    dateFormat: "Y-m-d h:i K",
    minDate: "today",
    wrap: true,
    allowInput: true,
    disableMobile: true,
  };

  return (
    <Modal
      isOpen={scheduleFormOpen}
      onClose={() => setScheduleFormOpen(false)}
      className="pb-modal"
    >
      <Modal.Header>
        <h4>Schedule your take!</h4>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <p className="m-0">
          Enter the day and time (in your local time-zone) and your take will
          get automatically sent then.
        </p>
        <div className="flex flex-col w-full space-y-6">
          <Flatpickr
            data-input
            options={flatPickrOption}
            value={formik.values.scheduled_at}
            onChange={([date]) => {
              formik.setFieldTouched("scheduled_at", true);
              formik.setFieldValue("scheduled_at", date);
            }}
          >
            <Input
              data-input
              name="scheduled_at"
              id="scheduled_at"
              placeholder="Enter date and time"
              error={
                Boolean(formik.errors.scheduled_at) &&
                formik.errors.scheduled_at
              }
              suffix={<CloseCircle className="cursor-pointer" data-clear />}
            />
            <p className="text-sm leading-5 opacity-60">
              Please note your time will automatically convert to UTC after
              entry.
            </p>
          </Flatpickr>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex gap-x-2">
        <Button
          label="Schedule Send"
          loading={scheduleBtnLoader}
          type="submit"
          onClick={() => handleSubmit("schedule")}
        />

        <Button
          label="Cancel"
          onClick={() => {
            setScheduleFormOpen(false);
            formik.setFieldValue("scheduled_at", null);
            formik.setFieldValue("schedule", false);
          }}
          style="modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ScheduleFormModal;
