import React, { useState, useEffect } from "react";
import { Eye, Mobile } from "@bigbinary/neeto-icons";
import { Toastr, Spinner, Modal } from "@bigbinary/neetoui";
import classnames from "classnames";

import { getUser, updateUser } from "@/apis/users";
import { Button } from "@/components/commons/AppFormUI";
import { destroyAttachment } from "@/apis/settings/attachments";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { useAuthContext } from "@/lib/useAuthContext";
import { getSubscribeUrl } from "@/lib/utils";

import SocialLinksModal from "./SocialLinksModal";
import SubscriberWelcomeModal from "./SubscriberWelcomeModal";
import LinkTreeModal from "./LinkTreeModal";
import DesktopForm from "./DesktopForm";
import MobileForm from "./MobileForm";
import ImageUploader from "./ImageUploader";

const Branding = () => {
  const { user, setUser } = useAuthContext();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [logoImage, setLogoImage] = useState();
  const [coverImage, setCoverImage] = useState();
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [subscriberModalOpen, setSubscriberModalOpen] = useState(false);
  const [socialModalOpen, setSocialModalOpen] = useState(false);
  const [linkTreeModalOpen, setLinkTreeModalOpen] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState();
  const [mobileScreen, setMobileScreen] = useState(false);
  const [hideControls, setHideControls] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    getUserResponse();
  }, []);

  const getUserResponse = async () => {
    try {
      setPageLoader(true);
      const { data } = await getUser(user.id);
      setUser(data.user);
      setLogoImage({ id: data.user.logo_image_id, src: data.user.logo_url });
      setCoverImage({
        id: data.user.cover_image_id,
        src: data.user.cover_image_url,
      });
      setPageLoader(false);
      updateUserResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async () => {
    try {
      const { data } = await updateUser(user.id, {
        user: { first_time_enter: false },
      });
      setUser({ ...user, first_time_enter: data.first_time_enter });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const destroyAttachmentsResponse = async () => {
    try {
      setBtnLoader(true);
      await destroyAttachment(deletingImageId);
      Toastr.success("Image deleted!");
      setDeleteAlertOpen(false);
      getUserResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-settings--layout flex flex-col items-center w-full">
        <div className="flex flex-col items-center mb-12 max-w-[834px] w-full">
          <p className="text-[15px] leading-[22px] text-center">
            Welcome to the Booth designer! Your unique PencilBooth URL is:
          </p>
          <a
            className="underline text-[15px] leading-[22px] text-center mb-6"
            title="Go to subscription page"
            href={getSubscribeUrl(user.url_slug)}
            target="_blank"
          >
            {getSubscribeUrl(user.url_slug)}
          </a>
          <p className="text-[15px] leading-[22px] text-center">
            Your booth is where people will subscribe to your newsletter and access your visual archive.
            It’s also your link in bio so you can use it as a one-stop-shop across your socials to
            capture new subscribers as well as share any links and additional social media accounts
            that you like.
          </p>
        </div>

        <div
          className={classnames(
            "pb-settings--chrome-window font-sf-pro-medium",
            {
              "chrome-mobile-window": mobileScreen,
            },
          )}
        >
          <div className="pb-settings--chrome-window--header">
            <div className="pb-settings--chrome-window--control">
              <span></span>
              <span></span>
              <span></span>
            </div>

            <div className="flex items-center gap-x-6">
              {!hideControls &&
                (coverImage?.id ? (
                  <span
                    className="text-sm leading-[24px] underline cursor-pointer"
                    onClick={() => {
                      setDeletingImageId(coverImage.id);
                      setDeleteAlertOpen(true);
                    }}
                  >
                    Delete Background Image
                  </span>
                ) : (
                  <div className="relative">
                    <span className="text-sm leading-[24px] underline cursor-pointer">
                      Add Background Image
                    </span>
                    <ImageUploader
                      type={"cover"}
                      position={2}
                      setPageLoader={setPageLoader}
                      setCoverImage={setCoverImage}
                      setLogoImage={setLogoImage}
                    />
                  </div>
                ))}

              <div className="flex items-center gap-x-2">
                <Mobile
                  className={classnames(
                    "cursor-pointer transition-all duration-300 hidden sm:block",
                    { "text-gray-400": !mobileScreen },
                  )}
                  size={16}
                  onClick={() => setMobileScreen(!mobileScreen)}
                />
                <Eye
                  className={classnames(
                    "cursor-pointer transition-all duration-300",
                    { "text-gray-400": !hideControls },
                  )}
                  size={16}
                  onClick={() => setHideControls(!hideControls)}
                />
              </div>
            </div>
          </div>

          <div
            className="pb-settings--chrome-window--body"
            style={{
              backgroundImage: `url(${
                coverImage?.file_data || coverImage?.src
              })`,
            }}
          >
            {mobileScreen || windowWidth < 640 ? (
              <AnimatedPage>
                <MobileForm
                  user={{
                    ...user,
                    link_trees: user.link_trees_attributes,
                    social_links_present: user.social_links_present,
                  }}
                  coverImage={coverImage}
                  logoImage={logoImage}
                  setCoverImage={setCoverImage}
                  setLogoImage={setLogoImage}
                  setPageLoader={setPageLoader}
                  hideControls={hideControls}
                  setDeletingImageId={setDeletingImageId}
                  setDeleteAlertOpen={setDeleteAlertOpen}
                  setSubscriberModalOpen={setSubscriberModalOpen}
                  setSocialModalOpen={setSocialModalOpen}
                  setLinkTreeModalOpen={setLinkTreeModalOpen}
                />
              </AnimatedPage>
            ) : (
              <AnimatedPage>
                <DesktopForm
                  user={{
                    ...user,
                    link_trees: user.link_trees_attributes,
                    social_links_present: user.social_links_present,
                  }}
                  logoImage={logoImage}
                  setCoverImage={setCoverImage}
                  setLogoImage={setLogoImage}
                  setPageLoader={setPageLoader}
                  hideControls={hideControls}
                  setDeletingImageId={setDeletingImageId}
                  setDeleteAlertOpen={setDeleteAlertOpen}
                  setSubscriberModalOpen={setSubscriberModalOpen}
                  setSocialModalOpen={setSocialModalOpen}
                  setLinkTreeModalOpen={setLinkTreeModalOpen}
                />
              </AnimatedPage>
            )}
          </div>
        </div>
      </AnimatedPage>

      <SubscriberWelcomeModal
        subscriberModalOpen={subscriberModalOpen}
        setSubscriberModalOpen={setSubscriberModalOpen}
      />

      <LinkTreeModal
        linkTreeModalOpen={linkTreeModalOpen}
        setLinkTreeModalOpen={setLinkTreeModalOpen}
      />

      <SocialLinksModal
        socialModalOpen={socialModalOpen}
        setSocialModalOpen={setSocialModalOpen}
      />

      <Modal
        isOpen={deleteAlertOpen}
        onClose={() => setDeleteAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Destroy Brand / Avatars</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to delete the image?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            loading={btnLoader}
            label="Yes, Destroy"
            type="submit"
            style="danger"
            onClick={() => destroyAttachmentsResponse()}
          />

          <Button
            label="Cancel"
            onClick={() => setDeleteAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Branding;
