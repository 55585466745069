import React from "react";
import classnames from "classnames";

const Input = ({
  className = "",
  type = "text",
  error = "",
  helpText = "",
  prefix = "",
  suffix = "",
  prefixActive = true,
  ...otherProps
}) => {
  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className={classnames("pb-settings--input-wrapper", { "pl-4": prefix, "pr-4": suffix })}>
        {prefix && (
          <span
            className={classnames("pb-settings--input-prefix", {
              "text-gray-400": !prefixActive,
            })}
          >
            {prefix}
          </span>
        )}
        <input
          type={type}
          className={classnames(["pb-settings--input", className], { "pl-4": !prefix, "pr-4": !suffix })}
          {...otherProps}
        />
        {suffix && <span className="pb-settings--input-suffix">{suffix}</span>}
      </div>

      {!!error && (
        <p className="text-sm leading-5 text-red-500 opacity-60">{error}</p>
      )}

      {!!helpText && <p className="text-sm leading-5 opacity-60">{helpText}</p>}
    </div>
  );
};

export default Input;
