import React, { useState } from "react";
import classnames from "classnames";

import Plans from "./Plans";
import Subscriptions from "./Subscriptions";

const Billing = () => {
  const [activeTab, setActiveTab] = useState("plans");

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <div className="pb-settings--layout flex flex-col w-full space-y-8">
        <div className="flex justify-center w-full space-x-6">
          <span
            className={classnames(
              "inline-block cursor-pointer font-semibold py-1 text-base",
              {
                "border-b-2 border-solid border-black": activeTab === "plans",
              },
            )}
            onClick={() => setActiveTab("plans")}
          >
            Plans and Pricing
          </span>

          <span
            className={classnames(
              "inline-block cursor-pointer font-semibold py-1 text-base",
              {
                "border-b-2 border-solid border-black":
                  activeTab === "subscriptions",
              },
            )}
            onClick={() => setActiveTab("subscriptions")}
          >
            Subscriptions
          </span>
        </div>

        {activeTab === "plans" ? <Plans /> : <Subscriptions />}
      </div>
    </div>
  );
};

export default Billing;
