import React from "react";
import NoData from "@/images/no-data.jpg";

const NoDataPage = () => {
  return (
    <div className="w-full flex justify-center flex-col items-center">
      <img src={NoData} className="mb-3" height="500" width="500"/>
      <h3>There’s nothing here yet! But no doubt there will be soon!</h3>
    </div>
  );
};

export default NoDataPage;
