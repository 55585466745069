import React from "react";
import { Tag } from "@bigbinary/neetoui";
import { Down } from "@bigbinary/neeto-icons";
import classnames from "classnames";

import { Button } from "@/components/commons/HomeFormUI";

const Tags = ({
  tagIds,
  loading,
  tagsCount,
  setTagPageIndex,
  tagList,
  handleAllTagClick,
  handleTagClick,
}) => {
  return (
    <>
      <div className="mb-5 flex justify-center flex-wrap">
        <Tag
          key={"all"}
          style="secondary"
          size="large"
          type="solid"
          label="All"
          className={classnames("ml-2 cursor-pointer mt-2 mb-2", {
            "!bg-black !text-white": tagIds.length === 0,
          })}
          onClick={() => handleAllTagClick()}
        />

        {tagList.map((tag) => {
          return (
            <Tag
              key={tag.id}
              style="secondary"
              size="large"
              type="solid"
              label={tag.name}
              className={classnames("ml-2 cursor-pointer mt-2 mb-2", {
                "!bg-black !text-white": tagIds.includes(tag.id),
              })}
              onClick={() => handleTagClick(tag)}
            />
          );
        })}
      </div>

      {tagsCount > tagList.length && (
        <div className="mt-3 flex justify-center mb-5 items-center">
          <Button
            className="!bg-secondary flex items-center"
            label="View more tags"
            style="link"
            loading={loading}
            type="submit"
            icon={<Down size="15" className="cursor-pointer" />}
            onClick={() => setTagPageIndex((data) => data + 1)}
          />
        </div>
      )}
    </>
  );
};

export default Tags;
