import React, { useState, useEffect } from "react";
import { Modal } from "@bigbinary/neetoui";
import { Link, useLocation } from "react-router-dom";

import { Button } from "@/components/commons/SettingsFormUI";
import { createStripeSubscription } from "@/apis/settings/subscriptions";
import { showToastrError } from "@/components/commons";
import { TickIcon } from "@/components/commons/PBIcons";
import { useAuthContext } from "@/lib/useAuthContext";

const UpgradeModal = ({ modalOpen, setModalOpen, stripePlanList }) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const [btnLoader, setBtnLoader] = useState(false);
  const [newPlanId, setNewPlanId] = useState();

  useEffect(() => {
    if (newPlanId) {
      createStripeSubscriptionResponse();
    }
  }, [newPlanId]);

  const createStripeSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createStripeSubscription({
        stripe_subscription: {
          price_id: newPlanId,
          redirect_to: location.pathname,
        },
      });
      window.location.href = data.checkout_url;
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
      setNewPlanId();
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      className="pb-medium-modal"
    >
      <Modal.Header>
        <div className="flex flex-col w-full gap-2 max-w-[683px]">
          <h4 className="modal-header">Upgrade your plan</h4>
          <p className="text-base font-soehne-mono">
            To access this feature, you’ll need to upgrade your plan. Choose
            from one of the options below.
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col lg:flex-row w-full gap-4">
          {stripePlanList.map((stripePlan) => {
            return (
              stripePlan.serial !== 4 &&
              stripePlan.serial !== 5 && (
                <div
                  className={`flex flex-col w-full rounded-2xl p-4 ${stripePlan.serial === 2 ? "bg-white" : "bg-[#E8E1D8]"} gap-4`}
                  key={stripePlan.serial}
                >
                  <div className="flex flex-col w-full gap-3">
                    <h4>{stripePlan.name}</h4>
                    <span
                      className={`inline-block rounded-full py-1.5 px-4 w-fit bg-white text-base ${stripePlan.serial === 2 ? "!bg-primary" : "bg-white"} !`}
                    >
                      {stripePlan.price}
                    </span>
                  </div>

                  <div className="flex flex-col w-full">
                    <p className="text-xs">Subscribers</p>
                    <p className="text-sm font-bold">
                      Up to {stripePlan.limit}
                    </p>
                  </div>

                  {stripePlan.serial == 1 &&
                  stripePlan.id === user.stripe_plan_id ? (
                    <p className="font-bold mb-[16px] mt-[10px]">
                      Current Plan
                    </p>
                  ) : (
                    <Button
                      label="Upgrade now"
                      className="!w-fit"
                      loading={newPlanId === stripePlan.id && btnLoader}
                      onClick={() => setNewPlanId(stripePlan.id)}
                    />
                  )}

                  <div className="flex flex-col gap-2 text-xs pt-4 border-[1px] border-x-0 border-b-0 border-solid border-[#7B7E82]">
                    {stripePlan.id === user.stripe_plan_id &&
                      stripePlan.serial == 1 && (
                        <p>
                          You are currently on the PencilBooth Soft Lead plan,
                          which is free forever for your first 100 true fans.
                        </p>
                      )}
                    {stripePlan.features?.map((feature, index) => {
                      return (
                        <div className="flex gap-2" key={index}>
                          <span className="shrink-0">
                            <TickIcon />
                          </span>
                          <span>{feature}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            );
          })}
        </div>
      </Modal.Body>

      <Modal.Footer className="bg-trnasparent"></Modal.Footer>
    </Modal>
  );
};

export default UpgradeModal;
