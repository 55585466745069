import React from "react";
import InstagramCard from "./InstagramCard";
import ThreadCard from "./ThreadCard";
import TwitterCard from "./TwitterCard";
import TwitterPostCard from "./TwitterPostCard";
import DefaultUrlCard from "./DefaultUrlCard";

export const LinkRollCard = ({
  linkRoll,
  withLink = true,
  showDetails = true,
}) => {
  const url = linkRoll.link;
  const twitterUrl = url.includes("x.com") || url.includes("twitter.com");
  const instagramUrl = url.includes("instagram.com");
  const threadUrl = url.includes("threads.net");

  const renderSpecificSocialComponent = () => {
    if (twitterUrl) {
      if (linkRoll.twitter_post) {
        return <TwitterPostCard linkRoll={linkRoll} withLink={withLink} />;
      } else {
        return <TwitterCard linkRoll={linkRoll} withLink={withLink} />;
      }
    } else if (instagramUrl) {
      return <InstagramCard linkRoll={linkRoll} withLink={withLink} />;
    } else if (threadUrl) {
      return <ThreadCard linkRoll={linkRoll} withLink={withLink} />;
    } else {
      return <DefaultUrlCard linkRoll={linkRoll} withLink={withLink} />;
    }
  };

  return (
    <div className="flex flex-col max-w-[486px] w-full space-y-6">
      {renderSpecificSocialComponent()}

      {showDetails && (
        <>
          <div className="flex flex-col max-w-[486px] w-full space-y-3">
            {linkRoll.title?.length > 0 && (
              <h4 className="pb-take--title-text font-soehne-kraftig dark:text-white">
                {linkRoll.title}
              </h4>
            )}
            {linkRoll.caption?.length > 0 && (
              <p
                className="pb-take--small-text w-full text-[#1D1F21] dark:text-white"
                dangerouslySetInnerHTML={{
                  __html: linkRoll.caption?.split("\n")?.join("<br/>"),
                }}
              ></p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LinkRollCard;
