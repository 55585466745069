import React, { useState, useEffect } from "react";
import * as pluralize from "pluralize";
import { useFormik } from "formik";
import LazyLoad from "react-lazy-load";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Toastr, Modal } from "@bigbinary/neetoui";

import { useAuthContext } from "@/lib/useAuthContext";
import { createSubscription } from "@/apis/subscriptions";
import { showToastrError } from "@/components/commons";
import {
  Button,
  LinkButton,
  InputWithButton,
} from "@/components/commons/AppFormUI";

import { INITIAL_VALUE } from "./constants";
import SubscribePlans from "../../SubscribeForm/SubscribePlans";
import SignUpAlert from "../../SubscribeForm/SignUpAlert";

const BoothCard = ({ boothUser, handleSubscription }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { user } = useAuthContext();
  const [btnLoader, setBtnLoader] = useState(false);
  const [emailField, setEmailField] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [upgradedToPaid, setUpgradedToPaid] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(
    boothUser.subscribed?.id,
  );
  const [signUpAlert, setSignUpAlert] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user ? { email: user.email } : INITIAL_VALUE,
    onSubmit: () => {
      createSubscriptionResponse();
    },
  });

  useEffect(() => {
    if (queryParams.booth_user_id == boothUser.id) {
      if (queryParams.upgraded_to_paid) {
        setUpgradedToPaid(true);
        setSubscriptionId(queryParams.booth_user_id);
      }

      if (queryParams.show_plans && queryParams.failure) {
        setSubscriptionId(queryParams.subscription_id);
        setShowPlans(true);
      }

      if (queryParams.new_user === "true" && queryParams.success) {
        setSignUpAlert(true);
      }
    }
  }, []);

  const createSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: formik.values.email, user_id: boothUser.id },
      });
      setSubscriptionId(data.id);
      setUpgradedToPaid(data.upgraded_to_paid);
      Toastr.success(data.message);
      formik.resetForm();

      if (boothUser.turn_on_paid && !data.upgraded_to_paid) {
        setShowPlans(true);
      }
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col w-full gap-y-4 rounded-2xl bg-white p-4">
      <div className="grid grid-cols-2 gap-0.5 rounded-2xl overflow-hidden flex-grow">
        {boothUser.attachments.map((attachment) => {
          return (
            <img
              src={attachment.thumb_src}
              className="aspect-square object-cover"
            />
          );
        })}
      </div>

      <div className="flex items-center gap-x-3">
        <div className="h-10 w-10 overflow-hidden rounded-full border-2 border-black shrink-0">
          <img
            src={boothUser.logo_url}
            className="aspect-square object-cover"
          />
        </div>
        <div className="flex flex-col items-start">
          <h4 className="text-base font-medium leading-normal line-clamp-1">
            {boothUser.name}
          </h4>
          <div className="flex items-center gap-x-1 text-xs font-normal leading-4 opacity-60">
            {boothUser.location && (
              <>
                <span>{boothUser.location}</span>
                <span>{" • "}</span>
              </>
            )}
            <span>
              {boothUser.takes_count} {pluralize("take", boothUser.takes_count)}
            </span>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-y-3">
        <LinkButton
          to={`/archive/${boothUser.url_slug}`}
          label="View Booth"
          style="secondary"
          onClick={() => window.scrollTo(0, 0)}
        />

        {boothUser.subscribed || subscriptionId ? (
          boothUser.subscribed?.upgraded_to_paid || upgradedToPaid ? (
            <Button label="Upgraded to Paid" style="link" />
          ) : boothUser.turn_on_paid ? (
            <Button
              onClick={() => {
                setSubscriptionId(boothUser.subscribed?.id || subscriptionId);
                setShowPlans(true);
              }}
              label="Upgrade to Paid"
            />
          ) : (
            <Button label="Subscribed" style="link" />
          )
        ) : user ? (
          <Button
            onClick={() => formik.handleSubmit()}
            label="Subscribe"
            loading={btnLoader}
          />
        ) : emailField ? (
          <InputWithButton
            id="email"
            name="email"
            type="email"
            placeholder="Enter email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            onSubmit={formik.handleSubmit}
            loading={btnLoader}
            label="Subscribe"
          />
        ) : (
          <Button
            onClick={() => setEmailField(true)}
            label="Subscribe"
            loading={btnLoader}
          />
        )}
      </div>

      <Modal
        isOpen={showPlans}
        onClose={() => setShowPlans(false)}
        className="pb-modal pb-modal--bg-white pb-modal--medium"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <SubscribePlans
            user={boothUser}
            subscriptionId={subscriptionId}
            setShowPlans={setShowPlans}
            modalContent
          />
        </Modal.Body>
      </Modal>

      <SignUpAlert modalOpen={signUpAlert} setModalOpen={setSignUpAlert} />
    </div>
  );
};

export default BoothCard;
