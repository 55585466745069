import React, { useState } from "react";
import { useFormik } from "formik";
import { Toastr, Modal, Typography } from "@bigbinary/neetoui";

import { useAuthContext } from "@/lib/useAuthContext";
import { getUser, updateUser } from "@/apis/users";
import { showToastrError } from "@/components/commons";
import { Input, Button } from "@/components/commons/SettingsFormUI";

const SocialLinksModal = ({ socialModalOpen, setSocialModalOpen }) => {
  const { user, setUser } = useAuthContext();
  const [btnLoader, setBtnLoader] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user?.id ? user : {},
    onSubmit: () => updateUserResponse(),
  });

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setUser({ ...user, ...data.user });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          facebook_url: formik.values.facebook_url,
          instagram_url: formik.values.instagram_url,
          you_tube_url: formik.values.you_tube_url,
          twitter_url: formik.values.twitter_url,
          dribble_url: formik.values.dribble_url,
          behance_url: formik.values.behance_url,
          threads_url: formik.values.threads_url,
          tiktok_url: formik.values.tiktok_url,
        },
      });
      Toastr.success(data.message);
      await getUserResponse();
      setSocialModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Modal
      isOpen={socialModalOpen}
      onClose={() => setSocialModalOpen(false)}
      className="pb-large-modal"
    >
      <Modal.Header>
        <h4>Social Links</h4>
      </Modal.Header>
      <Modal.Body>
        <Typography style="body2" className="mb-3">Enter the URL for your other social accounts and they will automatically appear along with their respective icon at the footer of your booth. Anything left blank will not appear.</Typography>
        <div className="flex flex-col space-y-6 py-6 border-t border-gray-300">
          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              Facebook URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                key="facebook_url"
                id="facebook_url"
                name="facebook_url"
                defaultValue={formik.values.facebook_url}
                onBlur={formik.handleChange}
                prefix="https://facebook.com/"
                prefixActive={formik.values.facebook_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.facebook_url && formik.errors.facebook_url,
                  ) && formik.errors.facebook_url
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              Instagram URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                id="instagram_url"
                name="instagram_url"
                value={formik.values.instagram_url}
                onChange={formik.handleChange}
                prefix="https://instagram.com/"
                prefixActive={formik.values.instagram_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.instagram_url && formik.errors.instagram_url,
                  ) && formik.errors.instagram_url
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              X URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                id="twitter_url"
                name="twitter_url"
                value={formik.values.twitter_url}
                onChange={formik.handleChange}
                prefix="https://twitter.com/"
                prefixActive={formik.values.twitter_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.twitter_url && formik.errors.twitter_url,
                  ) && formik.errors.twitter_url
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              YouTube URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                id="you_tube_url"
                name="you_tube_url"
                value={formik.values.you_tube_url}
                onChange={formik.handleChange}
                prefix="https://youtube.com/channel/"
                prefixActive={formik.values.you_tube_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.you_tube_url && formik.errors.you_tube_url,
                  ) && formik.errors.you_tube_url
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              Dribbble URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                id="dribble_url"
                name="dribble_url"
                value={formik.values.dribble_url}
                onChange={formik.handleChange}
                prefix="https://dribbble.com/"
                prefixActive={formik.values.dribble_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.dribble_url && formik.errors.dribble_url,
                  ) && formik.errors.dribble_url
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              Behance URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                id="behance_url"
                name="behance_url"
                value={formik.values.behance_url}
                onChange={formik.handleChange}
                prefix="https://behance.net/"
                prefixActive={formik.values.behance_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.behance_url && formik.errors.behance_url,
                  ) && formik.errors.behance_url
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              Threads URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                id="threads_url"
                name="threads_url"
                value={formik.values.threads_url}
                onChange={formik.handleChange}
                prefix="https://threads.net/"
                prefixActive={formik.values.threads_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.threads_url && formik.errors.threads_url,
                  ) && formik.errors.threads_url
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              TikTok URL
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Input
                id="tiktok_url"
                name="tiktok_url"
                value={formik.values.tiktok_url}
                onChange={formik.handleChange}
                prefix="https://tiktok.com/"
                prefixActive={formik.values.tiktok_url?.length > 0}
                error={
                  Boolean(
                    formik.touched.tiktok_url && formik.errors.tiktok_url,
                  ) && formik.errors.tiktok_url
                }
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-2 pt-6 border-t border-gray-300">
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            label="Save"
            loading={btnLoader}
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="bg-trnasparent"></Modal.Footer>
    </Modal>
  );
};

export default SocialLinksModal;
