import React, { useState } from "react";
import { Callout } from "@bigbinary/neetoui";
import { Close, Loading } from "@bigbinary/neeto-icons";
import * as R from "ramda";
import * as dayjs from "dayjs";
import { SCHEDULED_DATE_FORMAT } from "@/lib/constants";

import Header from "./Header";
import TakeComposer from "./TakeComposer";

const TakeForm = ({
  formik,
  handleSubmitWithStatus,
  takeDetailLoading,
  takeDetail,
  user,
  ...otherProps
}) => {
  const [calloutVisible, setCalloutVisible] = useState(true);

  return (
    <div className="flex flex-col items-center w-full">
      <Header formik={formik} {...otherProps} />

      <div className="flex flex-col items-center w-full overflow-y-auto px-4 h-[calc(100vh-72px)] sm:h-[calc(100vh-92px)]">
        {formik.values.schedule &&
          !R.isNil(formik.values.scheduled_at) &&
          calloutVisible && (
            <Callout className="mb-3 w-full">
              <div className="flex justify-between items-center space-x-4 w-full">
                <p>
                  Your take has been scheduled at{" "}
                  {dayjs(formik.values.scheduled_at).format(
                    SCHEDULED_DATE_FORMAT,
                  )}
                  . Click Cancel Schedule Send to cancel and reschedule.
                </p>

                <Close
                  className="cursor-pointer"
                  onClick={() => setCalloutVisible(false)}
                  size={16}
                />
              </div>
            </Callout>
          )}

        {user.under_review && (
          <Callout className="mb-3 w-full">
            <div className="flex justify-between items-center space-x-4 w-full">
              <p>
                Hello! We’re just manually reviewing your account at the moment.
                Please hold tight and you’ll be able to send your take shortly.
              </p>
            </div>
          </Callout>
        )}

        {formik.values.under_review && (
          <Callout className="mb-3 w-full">
            <div className="flex justify-between items-center space-x-4 w-full">
              <p>
                Hello! We’re just manually reviewing your take at the moment.
                Please hold tight take will be sent shortly.
              </p>
            </div>
          </Callout>
        )}

        {takeDetailLoading ? (
          <Loading className="animate-spin-slow" />
        ) : (
          <div className="w-full max-w-[952px] pt-4 pb-32">
            <TakeComposer
              user={user}
              formik={formik}
              takeDetail={takeDetail}
              handleSubmitWithStatus={handleSubmitWithStatus}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TakeForm;
