import React, { useState } from "react";
import classnames from "classnames";
import * as dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { Tooltip, Modal, Typography } from "@bigbinary/neetoui";
import { motion } from "framer-motion";
import ProgressBar from "@ramonak/react-progress-bar";
import { LinkButton, Button } from "@/components/commons/HomeFormUI";
import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import {
  createStripeSubscription,
  updateStripeSubscription,
} from "@/apis/settings/subscriptions";
import { showToastrError } from "@/components/commons";
import { cancelBilling } from "@/apis/settings/billings";

const FEATURES = {
  soft: [
    { label: "Image-led composer", checked: true },
    { label: "Pencilbooth Homepage Placement", checked: true },
    { label: "Enhanced Booth / Link in Bio", checked: true },
    { label: "The B-Roll", checked: true },
    { label: "Schedule Send", checked: true },
    { label: "Recommendation Engine", checked: true },
    { label: "Embeddable Subscriber forms", checked: true },
    { label: "Fan Importer", checked: true },
    { label: "Visual Archive", checked: true },
    { label: "Tags", checked: true },
    { label: "Analytics", checked: true },
    { label: "Paid Subscriptions", checked: false },
    { label: "Polls**", checked: false },
    { label: "Hide PencilBooth Branding**", checked: false },
    { label: "GifMaker 3000***", checked: false },
    { label: "Custom domain support**", checked: false },
    { label: "Affiliate Program**", checked: false },
  ],
  medium: [
    { label: "Image-led composer", checked: true },
    { label: "Pencilbooth Homepage Placement", checked: true },
    { label: "Enhanced Booth / Link in Bio", checked: true },
    { label: "The B-Roll", checked: true },
    { label: "Schedule Send", checked: true },
    { label: "Recommendation Engine", checked: true },
    { label: "Embeddable Subscriber forms", checked: true },
    { label: "Fan Importer", checked: true },
    { label: "Visual Archive", checked: true },
    { label: "Tags", checked: true },
    { label: "Analytics", checked: true },
    { label: "Paid Subscriptions", checked: true },
    { label: "Polls**", checked: false },
    { label: "Hide PencilBooth Branding**", checked: false },
    { label: "GifMaker 3000***", checked: false },
    { label: "Custom domain support**", checked: false },
    { label: "Affiliate Program**", checked: false },
  ],
  hard: [
    { label: "Image-led composer", checked: true },
    { label: "Pencilbooth Homepage Placement", checked: true },
    { label: "Enhanced Booth / Link in Bio", checked: true },
    { label: "The B-Roll", checked: true },
    { label: "Schedule Send", checked: true },
    { label: "Recommendation Engine", checked: true },
    { label: "Embeddable Subscriber forms", checked: true },
    { label: "Fan Importer", checked: true },
    { label: "Visual Archive", checked: true },
    { label: "Tags", checked: true },
    { label: "Analytics", checked: true },
    { label: "Paid Subscriptions", checked: true },
    { label: "Polls**", checked: true },
    { label: "Hide PencilBooth Branding**", checked: true },
    { label: "GifMaker 3000***", checked: true },
    { label: "Custom domain support**", checked: false },
    { label: "Affiliate Program**", checked: false },
  ],
  graphite: [
    { label: "Image-led composer", checked: true },
    { label: "Pencilbooth Homepage Placement", checked: true },
    { label: "Enhanced Booth / Link in Bio", checked: true },
    { label: "The B-Roll", checked: true },
    { label: "Schedule Send", checked: true },
    { label: "Recommendation Engine", checked: true },
    { label: "Embeddable Subscriber forms", checked: true },
    { label: "Fan Importer", checked: true },
    { label: "Visual Archive", checked: true },
    { label: "Tags", checked: true },
    { label: "Analytics", checked: true },
    { label: "Paid Subscriptions", checked: true },
    { label: "Polls**", checked: true },
    { label: "Hide PencilBooth Branding**", checked: true },
    { label: "GifMaker 3000***", checked: true },
    { label: "Custom domain support**", checked: true },
    { label: "Affiliate Program**", checked: true },
  ],
  custom: [
    { label: "Image-led composer", checked: true },
    { label: "Pencilbooth Homepage Placement", checked: true },
    { label: "Enhanced Booth / Link in Bio", checked: true },
    { label: "The B-Roll", checked: true },
    { label: "Schedule Send", checked: true },
    { label: "Recommendation Engine", checked: true },
    { label: "Embeddable Subscriber forms", checked: true },
    { label: "Fan Importer", checked: true },
    { label: "Visual Archive", checked: true },
    { label: "Tags", checked: true },
    { label: "Analytics", checked: true },
    { label: "Paid Subscriptions", checked: true },
    { label: "Polls**", checked: true },
    { label: "Hide PencilBooth Branding**", checked: true },
    { label: "GifMaker 3000***", checked: true },
    { label: "Custom domain support**", checked: true },
    { label: "Affiliate Program**", checked: true },
  ],
};

const ListItems = ({ data, visible, aboutPage }) => {
  return (
    <motion.div
      animate={{ opacity: 1, height: visible ? "auto" : 0 }}
      exit={{ opacity: 0, height: 0 }}
      initial={{ opacity: 0, height: 0, overflow: "hidden" }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-col w-full space-y-3 mt-3">
        {data.map((item, index) => {
          return (
            <div
              className={classnames("flex justify-between items-center", {
                "text-[#969797]": !item.checked && !aboutPage,
                "text-[#505050]": !item.checked && aboutPage,
              })}
              key={index}
            >
              <span className="text-[12px] leading-[16.8px]">{item.label}</span>
              <span className="text-[16px] leading-[19.6px] font-semibold">
                {item.checked ? <RenderMark /> : ""}
              </span>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

const DownIcon = ({ open }) => {
  return (
    <motion.div
      animate={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
      exit={{ transform: "rotate(0deg)" }}
      initial={{ transform: "rotate(0deg)" }}
      transition={{ duration: 0.3 }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#969797"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-chevron-down"
      >
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </motion.div>
  );
};

const RenderMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-check"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
};

const PriceCard = ({
  id,
  type,
  active,
  title,
  price,
  planSubscriberCount,
  limit,
  handleClick,
  clicked,
  subscriberCount,
  completed,
  activePlan,
  aboutPage,
  billing,
  setCancelBillingAlert,
  setPriceId,
  setPlanChangeAlertOpen,
}) => {
  const stripe_subscription = billing?.stripe_subscription;

  return (
    <div
      className={classnames(
        "flex flex-col space-y-4 w-full px-4 pt-4 pb-3 rounded-md",
        { "order-first bg-white": active },
        { "mt-6 bg-[#E4E1DA]": !active && !aboutPage },
        { "mt-6 bg-[#0000000d]": aboutPage },
      )}
    >
      <div className="flex flex-col w-full space-y-3">
        {active ? (
          <div className="flex w-full justify-between">
            <div className="flex flex-col shrink-0">
              <h4 className="text-[14px] leading-[16.71px] m-0 font-sf-pro font-semibold mb-1">
                Current plan
              </h4>
              <h2 className="text-[20px] leading-[22.87px] font-normal font-soehne-kraftig m-0">
                {title}
              </h2>
            </div>

            {billing.pro_plan_needed && !stripe_subscription && (
              <LinkButton
                label="Activate"
                className="!text-xs !py-1.5 !px-3"
                to={billing.checkout_url}
              />
            )}

            {billing.pro_plan_needed && stripe_subscription?.cancelled_at && (
              <div className="flex flex-col space-y-1">
                {!billing.stripe_subscription_active && (
                  <div className="flex justify-end w-full">
                    <LinkButton
                      className="!text-xs !py-1.5 !px-3"
                      label="Reactivate"
                      to={billing.checkout_url}
                    />
                  </div>
                )}

                {stripe_subscription && (
                  <Tooltip
                    content={`Your subscription has been cancelled, you will be able to send takes until ${stripe_subscription?.current_period_end_date} date. After that date you will either need to resubscribe or reduce your subscriber count`}
                    position="bottom-end"
                  >
                    <div>
                      <p className="text-[9px] text-gray-400 font-bold text-right m-0">
                        From{" "}
                        {dayjs(
                          stripe_subscription.current_period_start_date,
                        ).format(ORDINALIZED_DATE_FORMAT)}
                      </p>

                      <p className="text-[9px] text-gray-400 font-bold text-right m-0">
                        To{" "}
                        {dayjs(
                          stripe_subscription.current_period_end_date,
                        ).format(ORDINALIZED_DATE_FORMAT)}
                      </p>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}

            {stripe_subscription && !stripe_subscription.cancelled_at && (
              <div className="flex flex-col space-y-1">
                <Button
                  label="Cancel plan"
                  className="!text-xs !py-1.5 !px-3"
                  onClick={() => setCancelBillingAlert(true)}
                />

                <div>
                  <p className="text-[9px] text-gray-400 font-bold text-right m-0">
                    From{" "}
                    {dayjs(
                      stripe_subscription.current_period_start_date,
                    ).format(ORDINALIZED_DATE_FORMAT)}
                  </p>

                  <p className="text-[9px] text-gray-400 font-bold text-right m-0">
                    To{" "}
                    {dayjs(stripe_subscription.current_period_end_date).format(
                      ORDINALIZED_DATE_FORMAT,
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <h2 className="text-[20px] leading-[22.87px] font-semibold m-0">
            {title}
          </h2>
        )}

        {active && (
          <>
            <ProgressBar
              completed={completed}
              baseBgColor="#f3eee6"
              height="16px"
              borderRadius="9999px"
              bgColor="#ffc303"
              labelSize="12px"
              labelColor="rgba(29, 31, 33, 1)"
              isLabelVisible={false}
            />
            <h4 className="text-[14px] m-0 font-soehne-kraftig font-normal">
              You have {subscriberCount} subscribers
            </h4>
          </>
        )}

        <span
          className={classnames(
            "inline-block rounded-full mt-2 mb-6 py-1.5 px-4 w-fit",
            {
              "bg-white": !active,
              "bg-primary": active,
            },
          )}
        >
          {price}
        </span>

        {!active && !aboutPage && type !== "custom" && (
          <Button
            label={activePlan.limit < limit ? "Upgrade" : "Downgrade"}
            className="!w-fit"
            onClick={() => {
              setPriceId(id);
              setPlanChangeAlertOpen(true);
            }}
          />
        )}
      </div>

      {active && (
        <p className="text-[12px] leading-[16.8px] m-0 pb-4 border-b border-[#E4E1DA]">
          You are currently on the PencilBooth {activePlan.name} plan, which
          allows full use of the service for your first {activePlan.limit} true
          fans. Go get 'em!
        </p>
      )}

      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center">
          <span className="text-[12px] leading-[16.8px]">Subscribers</span>
          <span className="text-[16px] leading-[19.6px] font-semibold">
            {planSubscriberCount}
          </span>
        </div>

        <ListItems
          data={FEATURES[type]}
          visible={clicked}
          aboutPage={aboutPage}
        />
      </div>

      <div
        className={classnames(
          "flex justify-center items-center space-x-2 pt-2 border-t cursor-pointer",
          {
            "border-white": !active && !aboutPage,
            "border-[#E4E1DA]": active,
            "border-[#838383]": aboutPage,
          },
        )}
        onClick={() => handleClick(!clicked)}
      >
        <span className="text-[12px] leading-[16.8px]">
          {clicked ? "Hide" : "Show"} details
        </span>
        <DownIcon open={clicked} />
      </div>
    </div>
  );
};

const MobilePricing = ({
  activePlan,
  subscriberCount,
  completed,
  aboutPage,
  billing,
  cancelBillingAlert,
  setCancelBillingAlert,
  getBillingResponse,
}) => {
  const location = useLocation();
  const [softVisible, setSoftVisible] = useState(false);
  const [mediumVisible, setMediumVisible] = useState(false);
  const [hardVisible, setHardVisible] = useState(false);
  const [graphiteVisible, setGraphiteVisible] = useState(false);
  const [customVisible, setCustomVisible] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [priceId, setPriceId] = useState();
  const [planChangeAlertOpen, setPlanChangeAlertOpen] = useState(false);

  const handlePlanChange = () => {
    if (billing.upgraded_to_paid) {
      updateStripeSubscriptionResponse();
    } else {
      createStripeSubscriptionResponse();
    }
  };

  const createStripeSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createStripeSubscription({
        stripe_subscription: {
          price_id: priceId,
          redirect_to: location.pathname,
        },
      });
      window.location.href = data.checkout_url;
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateStripeSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      if (priceId === billing.free_plan_id) {
        await cancelBilling(billing.stripe_subscription.id);
      } else {
        await updateStripeSubscription({
          stripe_subscription: { price_id: priceId },
        });
      }

      setPlanChangeAlertOpen(false);
      getBillingResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col w-full lg:hidden">
      <PriceCard
        id={billing?.stripe_plans?.find((plan) => plan.key === "soft").id}
        type="soft"
        active={activePlan?.key === "soft"}
        title="Soft Lead"
        price="$0/mo"
        planSubscriberCount="Up to 100"
        limit={100}
        handleClick={setSoftVisible}
        clicked={softVisible}
        subscriberCount={subscriberCount}
        completed={completed}
        activePlan={activePlan}
        aboutPage={aboutPage}
        billing={billing}
        cancelBillingAlert={cancelBillingAlert}
        setCancelBillingAlert={setCancelBillingAlert}
        setPriceId={setPriceId}
        setPlanChangeAlertOpen={setPlanChangeAlertOpen}
      />

      <PriceCard
        id={billing?.stripe_plans?.find((plan) => plan.key === "medium").id}
        type="medium"
        active={activePlan?.key === "medium"}
        title="Medium Lead"
        price="$9/mo"
        planSubscriberCount="Up to 1,000"
        limit={1000}
        handleClick={setMediumVisible}
        clicked={mediumVisible}
        subscriberCount={subscriberCount}
        completed={completed}
        activePlan={activePlan}
        aboutPage={aboutPage}
        billing={billing}
        cancelBillingAlert={cancelBillingAlert}
        setCancelBillingAlert={setCancelBillingAlert}
        setPriceId={setPriceId}
        setPlanChangeAlertOpen={setPlanChangeAlertOpen}
      />

      <PriceCard
        id={billing?.stripe_plans?.find((plan) => plan.key === "hard").id}
        type="hard"
        active={activePlan?.key === "hard"}
        title="Hard Lead"
        price="$29/mo"
        planSubscriberCount="Up to 5,000"
        limit={5000}
        handleClick={setHardVisible}
        clicked={hardVisible}
        subscriberCount={subscriberCount}
        completed={completed}
        activePlan={activePlan}
        aboutPage={aboutPage}
        billing={billing}
        cancelBillingAlert={cancelBillingAlert}
        setCancelBillingAlert={setCancelBillingAlert}
        setPriceId={setPriceId}
        setPlanChangeAlertOpen={setPlanChangeAlertOpen}
      />

      <PriceCard
        id={billing?.stripe_plans?.find((plan) => plan.key === "graphite").id}
        type="graphite"
        active={activePlan?.key === "graphite"}
        title="Graphite"
        price="$79/mo"
        planSubscriberCount="Up to 10,000"
        limit={10000}
        handleClick={setGraphiteVisible}
        clicked={graphiteVisible}
        subscriberCount={subscriberCount}
        completed={completed}
        activePlan={activePlan}
        aboutPage={aboutPage}
        billing={billing}
        cancelBillingAlert={cancelBillingAlert}
        setCancelBillingAlert={setCancelBillingAlert}
        setPriceId={setPriceId}
        setPlanChangeAlertOpen={setPlanChangeAlertOpen}
      />

      <PriceCard
        type="custom"
        active={activePlan?.key === "custom"}
        title="Pen Privileges"
        price="Contact Us"
        planSubscriberCount="Over 10,000"
        handleClick={setCustomVisible}
        clicked={customVisible}
        subscriberCount={subscriberCount}
        completed={completed}
        activePlan={activePlan}
        aboutPage={aboutPage}
        billing={billing}
        cancelBillingAlert={cancelBillingAlert}
        setCancelBillingAlert={setCancelBillingAlert}
        setPriceId={setPriceId}
        setPlanChangeAlertOpen={setPlanChangeAlertOpen}
      />

      <Modal
        isOpen={planChangeAlertOpen}
        onClose={() => setPlanChangeAlertOpen(false)}
        className="pb-modal"
      >
        <Modal.Header>
          <h4>Plan Change</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <Typography style="body2">
            Are you sure to change the plan?
          </Typography>
        </Modal.Body>

        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Yes"
            loading={btnLoader}
            type="submit"
            onClick={() => handlePlanChange()}
          />

          <Button
            label="Cancel"
            onClick={() => setPlanChangeAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MobilePricing;
