import React from "react";

import PencilBoothLogo from "@/images/pencilbooth-logo.svg";

import FormCardFront from "./FormCardFront";
import SubscribePlans from "./SubscribePlans";
import LinkCard from "./LinkCard";

const Footer = () => {
  return (
    <div className="flex justify-center items-center px-4 py-3 bg-primary">
      <img alt="PencilBooth Logo" className="h-4" src={PencilBoothLogo} />
    </div>
  );
};

const MobileForm = ({
  user,
  btnLoader,
  setShowPlans,
  showPlans,
  email,
  setEmail,
  handleSubmit,
  subscriptionId,
  subscribed,
}) => {
  const coverPictureStyles = {
    backgroundImage: `url(${user.cover_image_url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  };

  return (
    <div className="pb-settings--wrapper flex flex-col items-center min-h-screen font-sf-pro-medium relative md:hidden">
      <div className="h-[200px] w-full" style={coverPictureStyles}></div>

      <div className="w-full bg-white">
        {showPlans ? (
          <SubscribePlans
            user={user}
            setShowPlans={setShowPlans}
            subscriptionId={subscriptionId}
          />
        ) : (
          <div className="min-h-[calc(100vh-240px)] md:min-h-fit">
            <FormCardFront
              user={user}
              email={email}
              setEmail={setEmail}
              handleSubmit={handleSubmit}
              btnLoader={btnLoader}
              subscribed={subscribed}
            />

            {(user.link_trees.length > 0 || user.social_links_present) && (
              <LinkCard user={user} subscriberForm />
            )}
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
};

export default MobileForm;
