export const FEATURES_DATA = [
  {
    title: "Made for artists",
    description:
      "Let your work do the talking! Unlike other newsletters, PencilBooth is led by images instead of words. Upload in any aspect ratio, and without any gnarly compression.",
    icon: "🎨",
  },
  {
    title: "Easy to use",
    description:
      "Every PencilBooth Take has the same easy-to-edit template, so you can just focus on filling it with your four images without worrying about code or design.",
    icon: "✨",
  },
  {
    title: "Scheduled send",
    description:
      "Create your takes in advance and send them on your own schedule with no limits on image count or caption lengths.",
    icon: "📅",
  },
  {
    title: "Your audience, your data",
    description:
      "Whether you’re migrating to PencilBooth from another newsletter platform (hello!) or want to move on (miss you), we make it easy to bring or take your subscriber list with you.",
    icon: "👨‍👩‍👧‍👦",
  },
  {
    title: "GDPR compliance and spam protection",
    description:
      "Double-opt in features available for all newsletters plus enhanced Spam protection to ensure your emails make it to your recipient’s inboxes, where they rightfully belong.",
    icon: "📨",
  },
  {
    title: "Embed widget",
    description:
      "Know your way around a bit of HTML and CSS? Fully customise your own embed widget on your own website to capture new subscribers wherever you want.",
    icon: "🛠️",
  },
  {
    title: "Recommendation engine",
    description:
      "Share the love! Bring your friends over to PB and recommend their booths on your own takes or in your visual archive.",
    icon: "🤝",
  },
  {
    title: "Discovery",
    description:
      "Every take you send on PencilBooth will make its way to the homepage in our discovery feed. Get noticed as a featured booth or be searchable by location or discipline.",
    icon: "🔭",
  },
];
