import React, { useState, createRef } from "react";
import { InView, useInView } from "react-intersection-observer";

import { InputWithButton } from "@/components/commons/AppFormUI";

import JackyWinterLogo from "@/images/archive/jacky-winter-logo.png";
import Wallpaper from "@/images/archive/Wallpaper.jpg";
import Take1 from "@/images/archive/Take1.jpg";
import Take2 from "@/images/archive/Take2.jpg";
import Take3 from "@/images/archive/Take3.jpg";
import Take4 from "@/images/archive/Take5.jpg";

const TakeCard = ({ image, subject, date, count, id, inViewIdMessage }) => {
  return (
    <div className="relative flex flex-col w-full mb-6 pb-take__wrapper overflow-hidden break-inside-avoid-column">
      <InView
        as="div"
        rootMargin="0px"
        threshold={1} // trigger callback when 100% of the element is visible
        onChange={(inView) => inViewIdMessage(inView, id)}
      >
        <img src={image} />
      </InView>

      <div className="flex flex-col items-start mt-2">
        <h4 className="text-base font-medium leading-normal text-lightBlack line-clamp-1">
          {subject}
        </h4>
        <p className="text-xs font-normal leading-4 text-lightBlack opacity-60">
          {`${date} • ${count}`}
        </p>
      </div>
    </div>
  );
};

const ScrollBook = ({ subject, date, count, opacity }) => {
  return (
    <div
      className="flex flex-col items-start transition-all duration-300 ease-in-out"
      style={{ opacity: opacity }}
    >
      <h4 className="text-base font-medium leading-normal text-lightBlack line-clamp-1">
        {subject}
      </h4>
      <p className="text-xs font-normal leading-4 text-lightBlack opacity-60">
        {`${date} • ${count}`}
      </p>
    </div>
  );
};

const VisualArchive = () => {
  const { inViewRef } = useInView();
  const [visibleCard, setVisibleCard] = useState("first_card");

  const inViewIdMessage = (inView, id) => {
    if (inView && id) {
      setVisibleCard(id);
    }
  };

  const generateOpacity = (cardSerial) => {
    if (visibleCard == "first_card") {
      if (cardSerial == "first_card") {
        return 1;
      } else if (cardSerial == "second_card") {
        return 0.6;
      } else if (cardSerial == "third_card") {
        return 0.4;
      } else if (cardSerial == "fourth_card") {
        return 0.2;
      }
    } else if (visibleCard == "second_card") {
      if (cardSerial == "first_card") {
        return 0.6;
      } else if (cardSerial == "second_card") {
        return 1;
      } else if (cardSerial == "third_card") {
        return 0.6;
      } else if (cardSerial == "fourth_card") {
        return 0.4;
      }
    } else if (visibleCard == "third_card") {
      if (cardSerial == "first_card") {
        return 0.4;
      } else if (cardSerial == "second_card") {
        return 0.6;
      } else if (cardSerial == "third_card") {
        return 1;
      } else if (cardSerial == "fourth_card") {
        return 0.6;
      }
    } else if (visibleCard == "fourth_card") {
      if (cardSerial == "first_card") {
        return 0.2;
      } else if (cardSerial == "second_card") {
        return 0.4;
      } else if (cardSerial == "third_card") {
        return 0.6;
      } else if (cardSerial == "fourth_card") {
        return 1;
      }
    }
  };

  return (
    <>
      <div className="w-full flex flex-col items-center space-y-[40px] max-w-[596px] lg:space-y-[130px]">
        <div className="w-full flex flex-col items-center space-y-[40px] lg:space-y-[60px]">
          <h4 className="text-2xl font-medium w-full leading-[1.2] font-soehne-kraftig lg:leading-[1.2] lg:text-5xl">
            Browse the visual archive
          </h4>
          <p className="text-[19px] leading-[1.6]">
            All the images from your takes are stored in your visual archive.
            Use it as an ad-hoc portfolio, a scrapbook, or simply as a preview
            for your growing audience.
          </p>
        </div>

        <div className="w-full flex-col items-start justify-center h-full space-y-[20px] hidden lg:flex">
          <ScrollBook
            subject="Creative Works Conference"
            date="January 4th 2024"
            count="4 images"
            opacity={generateOpacity("first_card")}
          />

          <ScrollBook
            subject="Up on a wall"
            date="January 24th 2024"
            count="2 images"
            opacity={generateOpacity("second_card")}
          />

          <ScrollBook
            subject="Staying caffeinated"
            date="February 8th 2024"
            count="3 images"
            opacity={generateOpacity("third_card")}
          />

          <ScrollBook
            subject="New, now, wow!"
            date="February 17th 2024"
            count="4 images"
            opacity={generateOpacity("fourth_card")}
          />
        </div>
      </div>

      <div
        className={`bg-[#F3EEE6] rounded-3xl p-6 max-w-[400px] w-full pb-about-visual-archive no-scrollbar shrink-0 overflow-auto`}
      >
        <div className="relative h-20 w-full rounded-lg">
          <img
            key="cover"
            src={Wallpaper}
            className="h-full w-full rounded-lg object-cover"
          />
          <img
            key="JackyWinter Logo"
            src={JackyWinterLogo}
            className="absolute inset-x-0 -bottom-[24px] m-auto h-12 w-12 rounded-full border-2 border-solid border-secondary"
          />
        </div>

        <div className="flex flex-col items-center justify-between pl-0 mt-10 gap-3">
          <div className="flex flex-col w-full text-center gap-2">
            <h3 className="text-[24px] font-medium text-lightBlack font-soehne-kraftig">
              The Jacky Winter Group
            </h3>
            <span className="text-base font-normal leading-5 text-black opacity-60">
              146 Takes • jackywinter.com
            </span>
          </div>

          <div className="mb-8">
            <InputWithButton
              id="email"
              name="email"
              type="email"
              placeholder="Enter email address"
              value=""
              onChange={() => null}
              onSubmit={() => null}
              label="Subscribe"
              className="w-full max-w-[398px]"
              readOnly={true}
            />
          </div>

          <TakeCard
            image={Take1}
            subject="Creative Works Conference"
            date="January 4th 2024"
            count="4 images"
            ref={inViewRef}
            id="first_card"
            inViewIdMessage={inViewIdMessage}
          />

          <TakeCard
            image={Take2}
            subject="Up on a wall"
            date="January 24th 2024"
            count="2 images"
            ref={inViewRef}
            id="second_card"
            inViewIdMessage={inViewIdMessage}
          />

          <TakeCard
            image={Take3}
            subject="Staying caffeinated"
            date="February 8th 2024"
            count="3 images"
            ref={inViewRef}
            id="third_card"
            inViewIdMessage={inViewIdMessage}
          />

          <TakeCard
            image={Take4}
            subject="New, now, wow!"
            date="February 17th 2024"
            count="4 images"
            ref={inViewRef}
            id="fourth_card"
            inViewIdMessage={inViewIdMessage}
          />
        </div>
      </div>
    </>
  );
};

export default VisualArchive;
