import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import classnames from "classnames";
import { Loading } from "@bigbinary/neeto-icons";

const Button = ({
  className = "",
  label = "Button",
  loading = false,
  style = "primary",
  disabled = false,
  rounded,
  fullWidth,
  loginBtn,
  icon,
  ...otherProps
}) => {
  const btnClassNames = classnames(
    "pb-home--btn",
    {
      "pb-home--btn--primary": style === "primary",
      "pb-home--btn--secondary": style === "secondary",
      "pb-home--btn--link": style === "link",
      "pb-home--btn--danger": style === "danger",
      "pb-home--btn--onlyLink": style === "onlyLink",
    },
    {
      "pb-home--btn--rounded": rounded,
    },
    {
      "pb-home--btn--fullWidth": fullWidth,
    },
    {
      "pb-home--btn--login": loginBtn,
    },
    {
      "pointer-events-none": loading,
    },
    { "disabled-btn": disabled },
  );

  return (
    <button {...otherProps} className={classnames([btnClassNames, className])}>
      <AnimatePresence>
        {loading && (
          <motion.div
            animate={{ width: "auto", scale: 1 }}
            exit={{ width: 0, scale: 0 }}
            initial={{ width: 0, scale: 0 }}
            transition={{ bounce: false }}
          >
            <Loading className="mr-2 animate-spin-slow" />
          </motion.div>
        )}
      </AnimatePresence>
      {label} {icon}
    </button>
  );
};

export default Button;
