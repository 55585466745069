import React from "react";
import { Send, Clock, Draft, LeftArrow } from "@bigbinary/neeto-icons";
import * as R from "ramda";

import { Button } from "@/components/commons/HomeFormUI";
import { MobileButton } from "@/components/commons/AppFormUI";

const Header = ({
  formik,
  handleCancel,
  handleSchedule,
  handleDraft,
  handleUpdate,
  handleSendNow,
  draftBtnLoader,
  updateBtnLoader,
  btnDisabled,
}) => {
  return (
    <>
      <div className="flex w-full items-center justify-between p-6 md:hidden">
        <MobileButton
          label={<LeftArrow size={24} />}
          onClick={() => handleCancel()}
        />

        <div className="flex items-center justify-end gap-x-3">
          {formik.values.send_now ? (
            <MobileButton
              label={<Draft size={24} />}
              loading={updateBtnLoader}
              onClick={() => handleUpdate()}
              disabled={btnDisabled}
            />
          ) : (
            <>
              <MobileButton
                label={<Draft size={24} />}
                loading={draftBtnLoader}
                onClick={() => handleDraft()}
                disabled={btnDisabled}
              />

              <MobileButton
                label={<Clock size={24} />}
                disabled={btnDisabled}
                onClick={() => handleSchedule()}
              />

              <MobileButton
                label={<Send size={24} />}
                disabled={btnDisabled || formik.values.schedule}
                onClick={() => handleSendNow()}
              />
            </>
          )}
        </div>
      </div>

      <div className="hidden md:flex w-full items-center justify-between p-6">
        <Button
          className="!pl-0"
          style="onlyLink"
          label={
            <>
              <LeftArrow size={24} className="mr-2" /> Back
            </>
          }
          onClick={() => handleCancel()}
        />

        <div className="flex items-center justify-end gap-x-3">
          {formik.values.send_now ? (
            <Button
              label="Update"
              loading={updateBtnLoader}
              type="submit"
              onClick={() => handleUpdate()}
              disabled={btnDisabled}
            />
          ) : (
            <>
              <Button
                className="!bg-secondary border"
                label="Save draft"
                style="link"
                loading={draftBtnLoader}
                type="submit"
                onClick={() => handleDraft()}
                disabled={btnDisabled}
              />

              <Button
                label={
                  formik.values.schedule && !R.isNil(formik.values.scheduled_at)
                    ? "Cancel Schedule Send"
                    : "Schedule"
                }
                disabled={btnDisabled}
                style="secondary"
                type="submit"
                onClick={() => handleSchedule()}
              />

              <Button
                label="Send now"
                type="submit"
                disabled={btnDisabled || formik.values.schedule}
                onClick={() => handleSendNow()}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
