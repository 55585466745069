import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as pluralize from "pluralize";
import * as dayjs from "dayjs";
import { Spinner } from "@bigbinary/neetoui";
import SearchIcon from "@/images/icons/search-icon.svg";
import { Loading, Close } from "@bigbinary/neeto-icons";

import {
  getSubscriptionList,
  exportSubscriptionList,
  downloadCsv,
} from "@/apis/subscriptions";
import { showToastrError } from "@/components/commons";
import AsyncPaginateSelect from "@/components/commons/AsyncPaginateSelect";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { useAuthContext } from "@/lib/useAuthContext";
import { EXPORT_DATE_FORMAT } from "@/lib/constants";

import Table from "./Table";
import ImportModal from "./ImportModal";
import EmptyPage from "./EmptyPage";

const Audience = () => {
  const { user, setUser } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [audienceList, setAudienceList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [exportBtnLoader, setExportBtnLoader] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [downloadBtnLoader, setDownloadBtnLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const [status, setStatus] = useState();
  const [emailStatus, setEmailStatus] = useState();
  const subscriptionDropdownOptions = [
    { label: "Subscribed", value: "q[unsubscribed_and_cleaned_eq]=false" },
    { label: "Unsubscribed", value: "q[unsubscribed_eq]=true" },
    { label: "Cleaned", value: "q[cleaned_eq]=true" },
  ];
  const emailDropdownOptions = [
    { label: "Bounced", value: "last_email_status_bounced" },
    { label: "Sent", value: "last_email_status_sent" },
    { label: "Opened", value: "last_email_status_opened" },
    { label: "Spam Complaint", value: "last_email_status_spammed" },
    { label: "Clicked", value: "last_email_status_clicked" },
    { label: "Unsubscribed", value: "last_email_status_unsubscribed" },
  ];

  useEffect(() => {
    if (pageIndex) {
      getAudienceListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    if (status || status === "") {
      getAudienceListResponse();
    }
  }, [status]);

  useEffect(() => {
    if (emailStatus || emailStatus === "") {
      getAudienceListResponse();
    }
  }, [emailStatus]);

  useEffect(() => {
    if (searchTerm === "") {
      getAudienceListResponse();
    }
  }, [searchTerm]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearch(true);
      setPageIndex(1);
      getAudienceListResponse();
    }
  };

  const getAudienceListResponse = async () => {
    try {
      const { data } = await getSubscriptionList(
        pageIndex,
        searchTerm,
        status,
        emailStatus,
      );
      setAudienceList(data.subscriptions);
      setUser({ ...user, subscriberSize: data.subscriberSize });
      setTotalRecords(data.total_records);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getCsvDownloadResponse = async () => {
    try {
      setDownloadBtnLoader(true);
      const { data } = await downloadCsv();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = `example_import_subscriptions.csv`;
      link.click();
      setDownloadBtnLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const exportAudienceListResponse = async () => {
    try {
      setExportBtnLoader(true);
      const { data } = await exportSubscriptionList();
      const url = window.URL.createObjectURL(
        new Blob([data.subscription_data]),
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `audiences_${dayjs().format(EXPORT_DATE_FORMAT)}.csv`;
      link.click();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setExportBtnLoader(false);
    }
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-app--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-app--layout pb-app--layout--croppedWidth flex flex-col items-center w-full">
        <div className="flex flex-col items-center w-full">
          <div className="max-w-[834px] w-full space-y-3">
            <h1 className="font-soehne-kraftig text-center text-[24px] leading-[28.64px] font-normal m-0">
              Audience Dashboard
            </h1>

            {audienceList.length > 0 || search ? (
              <p className="text-center text-[15px] leading-[22px]">
                You currently have {user.subscriberSize} true{" "}
                {pluralize("fan", user.subscriberSize)} in your PencilBooth.
                People must really like you! If you want to manually unsubscribe
                someone for any reason, you can do that. If you want to export
                your entire audience, click{" "}
                {exportBtnLoader ? (
                  <Loading className="inline animate-spin-slow" />
                ) : (
                  <Link
                    className="underline"
                    onClick={() => exportAudienceListResponse()}
                  >
                    here
                  </Link>
                )}
                . Are you migrating from another newsletter tool? Import a CSV
                file of your existing subscribers using our{" "}
                <Link
                  className="underline"
                  onClick={() => setUploadModalOpen(true)}
                >
                  our handy importer tool
                </Link>
                . Starting from scratch? Go on and{" "}
                <Link className="underline" to="/settings/branding">
                  customize your booth
                </Link>{" "}
                or use our{" "}
                <Link
                  className="underline"
                  to="/settings/embed_subscriber_form"
                >
                  embed widget
                </Link>{" "}
                then start spreading the word!
              </p>
            ) : (
              !search &&
              audienceList.length === 0 && (
                <p className="text-center text-[15px] leading-[22px]">
                  There’s nobody here yet!
                </p>
              )
            )}
          </div>

          {(audienceList.length > 0 || search) && (
            <div className="mb-3 w-full flex">
              <div className="w-1/3">
                <div className="flex items-center w-full gap-x-3 rounded-lg border border-black bg-white px-4 py-3 mr-2 mt-8">
                  <div className="flex w-full">
                    <img src={SearchIcon} alt="search icon" className="mr-2" />
                    <input
                      name="search"
                      placeholder="Search by email"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full"
                      onKeyDown={(e) => handleKeyDown(e)}
                    />

                    <Close
                      onClick={() => {
                        setSearchTerm("");
                      }}
                    />
                  </div>
                </div>
                <p className="text-sm leading-5 opacity-60 w-max">
                  Press ↵ Enter to search
                </p>
              </div>

              <div className="w-1/3 flex items-center ml-2 mt-3">
                <AsyncPaginateSelect
                  isSearchable
                  isClearable
                  isLoading={false}
                  name="email_status"
                  id="email_status"
                  placeholder="Search last email status"
                  defaultOptions={emailDropdownOptions}
                  onChange={(opt) => {
                    setSearch(true);
                    setPageIndex(1);

                    if (opt) {
                      setEmailStatus(opt?.value);
                    } else {
                      setEmailStatus("");
                    }
                  }}
                  className="border border-black rounded-lg audience-status"
                />
              </div>

              <div className="w-1/3 flex items-center ml-2 mt-3">
                <AsyncPaginateSelect
                  isSearchable
                  isClearable
                  isLoading={false}
                  name="status"
                  id="status"
                  placeholder="Search a status"
                  defaultOptions={subscriptionDropdownOptions}
                  onChange={(opt) => {
                    setSearch(true);
                    setPageIndex(1);

                    if (opt) {
                      setStatus(opt?.value);
                    } else {
                      setStatus("");
                    }
                  }}
                  className="border border-black rounded-lg audience-status"
                />
              </div>
            </div>
          )}

          {audienceList.length > 0 ? (
            <Table
              audienceList={audienceList}
              totalRecords={totalRecords}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              getAudienceListResponse={getAudienceListResponse}
            />
          ) : (
            !search && <EmptyPage setUploadModalOpen={setUploadModalOpen} />
          )}
        </div>

        <ImportModal
          uploadModalOpen={uploadModalOpen}
          setUploadModalOpen={setUploadModalOpen}
          getCsvDownloadResponse={getCsvDownloadResponse}
          downloadBtnLoader={downloadBtnLoader}
        />
      </AnimatedPage>
    </div>
  );
};

export default Audience;
