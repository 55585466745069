import React from "react";
import { NavLink } from "react-router-dom";

const Table = ({ takeList }) => {
  return (
    <>
      <div className="pb-app--table-wrapper w-full flex flex-col rounded-lg bg-white px-8 py-6">
        <table className="pb-app--table">
          <thead>
            <tr>
              <th>Subject</th>
            </tr>
          </thead>
          <tbody>
            {takeList.map((take) => {
              return (
                <tr key={take.id}>
                  <td>
                    <NavLink
                      className="justify-self-center"
                      to={`/takes/${take.id}`}
                    >
                      {take.subject}
                    </NavLink>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
