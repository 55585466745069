import React from "react";

import AnimatedPage from "@/components/commons/AnimatedPage";
import { LinkButton } from "@/components/commons/HomeFormUI";
import Footer from "@/components/commons/Footer";
import Header from "@/components/commons/Header";
import SignupCTA from "@/components/commons/SignupCTA";

import Banner from "./Banner";
import VisualArchive from "./VisualArchive";
import FAQs from "./FAQs";
import DesktopPricing from "../Dashboard/Settings/Billings/Plans/DesktopPricing";
import MobilePricing from "../Dashboard/Settings/Billings/Plans/MobilePricing";
import LinkList from "./LinkList";
import { FEATURES_DATA } from "./constants";
import FeatureCard from "./FeatureCard";

import Linkrolls from "@/images/Linkrolls.png";
import DiscoverDesktop from "@/images/DiscoverDesktop.png";
import DiscoverMobile from "@/images/DiscoverMobile.png";

const About = () => {
  return (
    <div className="pb-home--layout-wrapper flex flex-col min-h-screen items-center w-full bg-primary">
      <Header bgYellow />

      <AnimatedPage className="pb-home--layout flex flex-col items-center w-full">
        <div className="pb-home--sub-layout flex flex-col items-center space-y-[80px] lg:space-y-[200px]">
          <div className="flex flex-col items-center space-y-[80px]">
            <h2 className="text-[48px] lg:text-[84px] text-center font-medium leading-none font-soehne-kraftig">
              The easiest way to keep people up-to-date with your art
            </h2>

            <LinkButton
              to="/signup"
              label="CLAIM YOUR BOOTH"
              className="w-[256px] !py-3"
              onClick={() => window.scrollTo(0, 0)}
            />

            <Banner />
          </div>

          <h4 className="text-2xl font-medium leading-[1.2] font-soehne-kraftig lg:leading-[1.2] lg:text-5xl">
            We’re a different kind of newsletter platform made specifically for
            visual artists. We provide you with a beautiful, image-led template
            that’s easy to use and simple to send, along with a suite of novel
            discovery tools to be noticed by potential clients and fellow
            creatives. No algorithm, no app, just a visual snapshot of what
            you’ve been up to and what you’re loving, delighting your
            subscribers each time it lands in their inbox.
          </h4>
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[40px] mt-6 lg:space-y-[0px] lg:flex-row lg:items-start lg:space-x-[200px] lg:mt-[104px]">
          <VisualArchive />
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[40px] mt-6 lg:space-y-[60px] lg:mt-[104px]">
          <div className="flex flex-col items-center space-y-[40px] w-full">
            <h4 className="text-2xl font-medium leading-[1.2] font-soehne-kraftig lg:leading-[1.2] w-full text-left lg:text-5xl lg:text-center">
              Discover and be discovered.
            </h4>
            <p className="w-full text-left lg:text-center text-[19px] max-w-full lg:max-w-[720px] leading-[1.6]">
              Your visual archive is plugged into our homepage where fellow
              artists and potential clients can also discover you. Add tags to
              your booth and get discovered.
            </p>
          </div>

          <div className="md:hidden">
            <img
              alt="discover image"
              src={DiscoverMobile}
              className="w-full h-auto"
            />
          </div>

          <div className="hidden md:block">
            <img
              alt="discover image"
              src={DiscoverDesktop}
              className="w-full h-auto"
            />
          </div>
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[40px] mt-6 lg:space-y-[60px] lg:mt-[104px]">
          <LinkList />
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[40px] mt-6 lg:space-y-[60px] lg:mt-[104px]">
          <h4 className="text-2xl font-medium leading-[1.2] font-soehne-kraftig lg:leading-[1.2] w-full text-left lg:text-5xl lg:text-center max-w-full lg:max-w-[590px]">
            Introducing the B-roll, a place for everything else.
          </h4>
          <p className="max-w-[968px] w-full text-left lg:text-center text-[19px] leading-[1.6]">
            In addition to your own work, you can easily share what else you are
            loving through our new B-roll feature. Automatically turn any boring
            URL into bite-size visual nuggets to accompany bookend your take,
            simply copy and paste any URL and voila!
          </p>

          <img
            src={Linkrolls}
            alt="linkrolls"
            className="w-full h-auto px-6 lg:px-12"
          />
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[40px] mt-6 lg:space-y-[60px] lg:mt-[104px]">
          <h2 className="text-[24px] lg:text-[32px] font-medium leading-snug font-soehne-kraftig text-center">
            More features we know you’ll love
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
            {FEATURES_DATA.map((feature, index) => (
              <FeatureCard feature={feature} key={index} />
            ))}
          </div>
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[40px] mt-6 lg:space-y-[60px] lg:mt-[104px]">
          <div className="flex flex-col items-center space-y-[40px]">
            <h4 className="text-2xl font-medium leading-[1.2] font-soehne-kraftig lg:leading-[1.2] w-full text-left lg:text-5xl lg:text-center">
              Pricing
            </h4>
          </div>

          <DesktopPricing
            activePlan={null}
            subscriberCount={0}
            completed={0}
            aboutPage
          />
          <MobilePricing
            activePlan={null}
            subscriberCount={0}
            completed={0}
            aboutPage
          />

          <div className="text-[12px] lg:text-[14px] w-full">
            <p className="m-0">
              * All plans include a maximum of one email per day to your entire
              list. If you need to send more frequently, please drop us a line.
            </p>
            <p className="m-0">** Coming Soon</p>
            <p className="m-0">*** Mysterious!!</p>
            <p className="m-0">
              ~Paid subscriptions are processed by Stripe. Paid subscriptions
              will incur the standard stripe processing fees plus a 5%
              PencilBooth application fee. There is no minimum amount for
              subscriptions. Visit our help docs to learn more.
            </p>
          </div>
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[40px] mt-6 lg:space-y-[60px] lg:mt-[104px]">
          <SignupCTA />
        </div>

        <div className="pb-home--sub-layout flex flex-col items-center space-y-[84px] mt-6 lg:space-y-[60px] lg:mt-[104px]">
          <FAQs />
        </div>

        <div className="mt-[152px]">
          <Footer />
        </div>
      </AnimatedPage>
    </div>
  );
};

export default About;
