import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Copy } from "@bigbinary/neeto-icons";
import { ColorPicker, Spinner, Toastr } from "@bigbinary/neetoui";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  getSubscriberFormSetting,
  updateSubscriberFormSetting,
} from "@/apis/settings/subscriber_form_settings";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { SUBSCRIBER_FORM_INITIAL_VALUE } from "./constants";
import {
  Input,
  Textarea,
  Switch,
  Button,
} from "@/components/commons/SettingsFormUI";

const PB_AVATAR = `${window.location.origin}/images/common/pb-avatar.png`

const EmbedSubscriberForm = () => {
  const [formSetting, setFormSetting] = useState();
  const [formSettingLoad, setFormSettingLoad] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [updatedSubscriberFormAlert, setUpdatedSubscriberFormAlert] = useState(false);
  const [changeCopyText, setChangeCopyText] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formSetting ? formSetting : SUBSCRIBER_FORM_INITIAL_VALUE,
    onSubmit: () => updateSubscriberFormSettingResponse(),
  });

  useEffect(() => {
    getSubscriberFormSettingResponse();
  }, []);

  useEffect(() => {
    if (changeCopyText) {
      setTimeout(() => setChangeCopyText(false), 1000);
    }
  }, [changeCopyText])

  const getSubscriberFormSettingResponse = async () => {
    try {
      const { data } = await getSubscriberFormSetting();
      setFormSetting(data.subscriber_form_setting);
      setFormSettingLoad(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateSubscriberFormSettingResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateSubscriberFormSetting({
        subscriber_form_setting: formik.values,
      });
      getSubscriberFormSettingResponse();
      setUpdatedSubscriberFormAlert(true);
      Toastr.success(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getAvatarLink = () => {
    if (formik.values.show_avatar) {
      return `data-avatar="${formSetting.user_logo_url ? formSetting.user_logo_url : PB_AVATAR}"`;
    }
  }

  const getDisplayName = () => {
    if (formik.values.show_display_name) {
      return `data-display-name="${formSetting.user_display_name}" data-display-name-color="${formik.values.display_name_color}"`;
    }
  }

  const getFontDetails = () => {
    if (formik.values.use_website_font) {
      return `data-use-my-website-font="true"`;
    }
  }

  const getEmbeddedFormAttributes = () => {
    return [
      'style="display: none"',
      'class="pb-subscribe-form"',
      `data-org="${formSetting.user_id}"`,
      getAvatarLink(),
      getDisplayName(),
      `data-description="${formSetting.message}"`,
      `data-description-color="${formSetting.message_color}"`,
      `data-email-input-placeholder="${formSetting.email_input_placeholder}"`,
      `data-submit-button-label="${formSetting.submit_button_label}"`,
      `data-submit-button-text-color="${formSetting.submit_button_text_color}"`,
      `data-submit-button-background-color="${formSetting.submit_button_color}"`,
      `data-confirmation-title="${formSetting.confirmation_message_title}"`,
      `data-confirmation-msg="${formSetting.confirmation_message}"`,
      `data-background-color="${formSetting.background_color}"`,
      getFontDetails()
    ].filter(item => item).join(" ")
  }

  const getEmbeddedFormAttributesWithDiv = () => {
    return `<div ${getEmbeddedFormAttributes()}></div><script async src=${window.location.origin}/scripts/embed.js></script>`
  }

  if (formSettingLoad) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-settings--layout flex flex-col w-full">
        <h2 className="font-soehne-kraftig pb-6 text-[24px] font-medium leading-snug border-b border-gray-300 lg:text-[32px]">
          Embed Subscriber Form
        </h2>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            SHOW AVATAR
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.show_avatar}
              onChange={() =>
                formik.setFieldValue("show_avatar", !formik.values.show_avatar)
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Show your Avatar in the subscribe form.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            SHOW DISPLAY NAME
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.show_display_name}
              onChange={() =>
                formik.setFieldValue(
                  "show_display_name",
                  !formik.values.show_display_name,
                )
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Show your Display Name in the subscribe form.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            DISPLAY NAME COLOR
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <ColorPicker
              color={formik.values.display_name_color}
              onChange={(color) =>
                formik.setFieldValue("display_name_color", color.hex)
              }
              showHexValue
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Change the text color of the display name. Leave it as `inherit`
              to automatically get the text color from your website.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            MESSAGE
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Textarea
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              maxLength={350}
              textCount={formik.values.message?.length}
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Add a message above the Email input in the subscribe form.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            MESSAGE COLOR
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <ColorPicker
              color={formik.values.message_color}
              onChange={(color) =>
                formik.setFieldValue("message_color", color.hex)
              }
              showHexValue
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Change the text color of the message. Leave it as `inherit` to
              automatically get the text color from your website.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            BACKGROUND COLOR
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <ColorPicker
              color={formik.values.background_color}
              onChange={(color) =>
                formik.setFieldValue("background_color", color.hex)
              }
              showHexValue
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Change the background color of the subscribe form. Leave it as
              `inherit` to automatically get the background color from your
              website.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            EMAIL INPUT PLACEHOLDER
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="email_input_placeholder"
              name="email_input_placeholder"
              value={formik.values.email_input_placeholder}
              onChange={formik.handleChange}
              error={
                Boolean(
                  formik.touched.email_input_placeholder &&
                    formik.errors.email_input_placeholder,
                ) && formik.errors.email_input_placeholder
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              The placeholder used in the Email input text box.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            SUBMIT BUTTON LABEL
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="submit_button_label"
              name="submit_button_label"
              value={formik.values.submit_button_label}
              onChange={formik.handleChange}
              error={
                Boolean(
                  formik.touched.submit_button_label &&
                    formik.errors.submit_button_label,
                ) && formik.errors.submit_button_label
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              The text shown in the submit button.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            SUBMIT BUTTON COLOR
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <ColorPicker
              color={formik.values.submit_button_color}
              onChange={(color) =>
                formik.setFieldValue("submit_button_color", color.hex)
              }
              showHexValue
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Change the button color.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            SUBMIT BUTTON TEXT COLOR
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <ColorPicker
              color={formik.values.submit_button_text_color}
              onChange={(color) =>
                formik.setFieldValue("submit_button_text_color", color.hex)
              }
              showHexValue
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Change the button text color.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            CONFIRMATION MESSAGE TITLE
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="confirmation_message_title"
              name="confirmation_message_title"
              value={formik.values.confirmation_message_title}
              onChange={formik.handleChange}
              error={
                Boolean(
                  formik.touched.confirmation_message_title &&
                    formik.errors.confirmation_message_title,
                ) && formik.errors.confirmation_message_title
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              The message title shown to the user when they successfully
              subscribe to your booth.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            CONFIRMATION MESSAGE
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Textarea
              id="confirmation_message"
              name="confirmation_message"
              value={formik.values.confirmation_message}
              onChange={formik.handleChange}
              maxLength={350}
              textCount={formik.values.confirmation_message?.length}
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              The message shown to the user when they successfully subscribe to
              your booth.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            USE MY WEBSITE FONTS
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.use_website_font}
              onChange={() =>
                formik.setFieldValue(
                  "use_website_font",
                  !formik.values.use_website_font,
                )
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              If you would like to use your website’s default fonts, enable this
              toggle.
            </span>
          </div>
        </div>
        <div className="flex items-center justify-end py-4">
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            label="Update"
            loading={btnLoader}
          />
        </div>

        <div className="mt-12 space-y-7 rounded-md divide-y divide-[#acacac] bg-white px-6 py-5">
          <div className="flex w-full flex-col gap-y-3">
            <h4 className="font-soehne-kraftig text-lg font-medium">
              Embed Instructions
            </h4>
            {updatedSubscriberFormAlert && (
              <div className="rounded-md bg-[#e4f8f0] px-6 py-4 text-sm text-[#1ea97c]">
                The code snippet below has changed. Please ensure the updated snippet is copied to
                your website to see the new changes.
              </div>
            )}
            <div className="rounded-md bg-[#F4EEE5] px-6 py-4 text-sm">
              Please copy & paste this code snippet at all locations where you
              want the subscribe form to appear.
            </div>
            <div className="relative rounded-md border border-gray-600 bg-white px-6 py-4 text-sm">
              <pre className="overflow-hidden whitespace-break-spaces opacity-50">
                &lt;div {getEmbeddedFormAttributes()}&gt;&lt;/div&gt;
                &lt;script async src="{window.location.origin}/scripts/embed.js"&gt;&lt;/script&gt;
              </pre>
              <div className="absolute right-3 top-2 flex items-center gap-2 rounded bg-black px-3 py-2 text-white cursor-pointer">
                <CopyToClipboard
                  text={getEmbeddedFormAttributesWithDiv()}
                  onCopy={() => setChangeCopyText(true)}
                >
                  <button>
                    <div className="flex items-center">
                      <Copy size="20"/>
                      <span className="ml-1">{changeCopyText ? "COPIED" : "COPY"}</span>
                    </div>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-3 pt-7">
            <h4 className="font-soehne-kraftig text-lg font-medium">
              Advanced Styling
            </h4>
            <div className="rounded-md bg-[#F4EEE5] px-6 py-4 text-sm">
              If you'd like to make your subscribe form look even more amazing, you can style the elements added to your page using the following CSS classes. If you're unsure about making changes or can't find the appropriate code, it's always a good idea to consult with a web developer or someone with technical expertise.
            </div>
            <div className="relative rounded-md border border-gray-600 bg-white px-6 py-4 text-sm">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 font-bold text-left">CSS Class Name</th>
                    <th className="py-3 font-bold text-left">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-subscribe-form</th>
                    <td className="py-3 text-left font-normal">The subscribe form container element</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-form</th>
                    <td className="py-3 text-left font-normal">The <code>&lt;form&gt;</code> element</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-avatar</th>
                    <td className="py-3 text-left font-normal">The Avatar, only if it's enabled</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-display-name</th>
                    <td className="py-3 text-left font-normal">The Display Name, if it's enabled</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-message</th>
                    <td className="py-3 text-left font-normal">The Message text</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-form-group</th>
                    <td className="py-3 text-left font-normal">The container wrapping the form input and button</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-email-input</th>
                    <td className="py-3 text-left font-normal">The email input element</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-button</th>
                    <td className="py-3 text-left font-normal">The submit button</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-success-message-wrapper</th>
                    <td className="py-3 text-left font-normal">The confirmation message wrapper</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-success-message-icon</th>
                    <td className="py-3 text-left font-normal">The confirmation message icon</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-success-message-title</th>
                    <td className="py-3 text-left font-normal">The confirmation message title</td>
                  </tr>
                  <tr className="border-b border-[#e8e8e8]">
                    <th className="py-3 text-left font-normal">.pb-success-message</th>
                    <td className="py-3 text-left font-normal">The confirmation message</td>
                  </tr>
                  <tr>
                    <th className="py-3 text-left font-normal">.pb-error-message</th>
                    <td className="py-3 text-left font-normal">The error message</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </AnimatedPage>
    </div>
  );
};

export default EmbedSubscriberForm;
