import React from "react";
import { Link } from "react-router-dom";

const FooterBanner = () => {
  return (
    <div className="flex flex-col items-center bg-[#F1F1F1] p-6 space-y-6 rounded-2xl w-full dark:bg-[#333333]">
      <p className="pb-take--text text-center !leading-[24px] dark:text-white">
        Want to get your own Booth?
      </p>

      <Link
        to="/signup"
        className="py-1 px-4 bg-black text-white rounded-full text-[15px] leading-[24px] text-center dark:text-black dark:bg-white"
        onClick={() => window.scrollTo(0, 0)}
      >
        Create an account
      </Link>
    </div>
  );
};

export default FooterBanner;
