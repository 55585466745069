import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { PlusIcon } from "@/components/commons/PBIcons";

const baseStyle = {
  "flex": 1,
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "outline": "none",
  // transition: "border .24s ease-in-out",
  "cursor": "pointer",
  "paddingTop": "3rem",
  "paddingBottom": "3rem",
  "paddingLeft": "0.625rem",
  "paddingRight": "0.625rem",
  "width": "100%",
  "border": "2px #969797 solid",
  "borderRadius": 16,
  "--tw-ring-offset-shadow":
    "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow":
    "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
  "box-shadow":
    "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "--tw-ring-color": "transparent",
};

const focusedStyle = {
  "--tw-ring-opacity": 1,
  "--tw-ring-color": "rgb(255 195 3 / var(--tw-ring-opacity))",
  "--tw-ring-offset-shadow":
    "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow":
    "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
  "box-shadow":
    "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "borderColor": "#FFC303",
};

const acceptStyle = {
  "--tw-ring-opacity": 1,
  "--tw-ring-color": "rgb(255 195 3 / var(--tw-ring-opacity))",
  "--tw-ring-offset-shadow":
    "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
  "--tw-ring-shadow":
    "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
  "box-shadow":
    "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
  "borderColor": "#FFC303",
};

const rejectStyle = {
  border: "5px #CB4446 solid",
};

const cursorNotAllowedStyle = {
  cursor: "not-allowed",
};

const formatErrorMessage = (code, msg) => {
  if (code.includes("too-many-files")) {
    return "Multiple image upload coming soon! Please choose a single image for now.";
  } else {
    return msg;
  }
};

const StyledDropzone = ({ handleChange, uploadInProgress }) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    fileRejections,
  } = useDropzone({
    maxSize: 5e6,
    minFiles: 1,
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".gif", ".webp", ".avif", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      handleChange(acceptedFiles);
    },
    noClick: uploadInProgress() ? true : false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragActive ? focusedStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(uploadInProgress() ? cursorNotAllowedStyle : {}),
      ...(fileRejections.length > 0 ? rejectStyle : {}),
    }),
    [
      isFocused,
      isDragAccept,
      isDragReject,
      isDragActive,
      uploadInProgress(),
      fileRejections,
    ],
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input multiple="false" {...getInputProps()} />
        <div className="flex flex-col justify-start items-center space-y-4">
          <div className="text-[#969797]">
            <PlusIcon />
          </div>

          <span className="text-base text-[#7B7E82]">Add Image</span>
        </div>
      </div>

      {fileRejections?.length > 0 && (
        <p className="text-[#CB4446] mt-2 mb-0">
          {fileRejections[0].errors.map((e) =>
            formatErrorMessage(e.code, e.message),
          )}
        </p>
      )}
    </div>
  );
};

export default StyledDropzone;
