import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { toast, Zoom } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@/lib/useAuthContext";
import { createTake } from "@/apis/takes";
import { showToastrError } from "@/components/commons";

import {
  MenuHorizontal,
  RadioFillIcon,
  RadioIcon,
} from "@/components/commons/PBIcons";

import LogoHeader from "./LogoHeader";
import CoverHeader from "./CoverHeader";

const SelectIcon = ({ selected }) => {
  return selected ? <RadioFillIcon /> : <RadioIcon />;
};

const TakeHeader = ({ formik, takeDetail, handleSubmitWithStatus }) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [draftBtnLoader, setDraftBtnLoader] = useState(false);

  const createTakeResponse = async () => {
    try {
      setDraftBtnLoader(true);
      const { data } = await createTake({
        take: { ...formik.values, header_image_type: "cover" },
      });
      formik.setFieldValue("id", data.id);
      navigate(`/takes/${data.id}/edit`, {
        state: {
          pageLoad: false,
        },
      });
      toast("💾", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Zoom,
        closeButton: false,
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setDraftBtnLoader(false);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-12 w-full relative">
      <div className="relative flex flex-col items-center w-full">
        {formik.values.header_image_type == "logo" ? (
          <LogoHeader />
        ) : (
          <CoverHeader
            formik={formik}
            draftBtnLoader={draftBtnLoader}
            takeDetail={takeDetail}
          />
        )}

        <div className="absolute right-2 top-2">
          <Menu>
            <MenuButton className="flex items-center rounded-full p-1 bg-white data-[hover]:bg-[#F2EFEC] data-[open]:bg-[#F2EFEC] border border-solid border-[#E5E0D9]">
              <MenuHorizontal />
            </MenuButton>
            <Transition
              enter="transition ease-out duration-75"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <MenuItems
                anchor="bottom end"
                className="origin-top-left w-[200px] rounded-lg border border-solid border-[#E5E0D9] bg-white p-2 shadow-lg focus:outline-none z-50"
              >
                <MenuItem
                  onClick={() => {
                    formik.setFieldValue("header_image_type", "logo");
                    handleSubmitWithStatus();
                  }}
                >
                  <button className="flex items-center w-full p-2 text-[16px] font-soehne-mono">
                    <SelectIcon
                      selected={formik.values.header_image_type == "logo"}
                    />
                    <span className="ml-2">Logo header</span>
                  </button>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    formik.setFieldValue("header_image_type", "cover");
                    formik.setFieldValue(
                      "cover_image_attributes",
                      user.default_take_header_attachment_attributes,
                    );
                    if (!takeDetail?.id || !formik.values.id) {
                      createTakeResponse();
                    } else {
                      handleSubmitWithStatus();
                    }
                  }}
                >
                  <button className="flex items-center w-full p-2 text-[16px] font-soehne-mono">
                    <SelectIcon
                      selected={formik.values.header_image_type == "cover"}
                    />
                    <span className="ml-2">Image header</span>
                  </button>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>

      <h3 className="pb-take--user-name font-soehne-kraftig text-center">
        {user.display_name}
      </h3>
    </div>
  );
};

export default TakeHeader;
