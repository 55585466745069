import React, { useState, useEffect } from "react";
import { Spinner } from "@bigbinary/neetoui";

import { getTakeList } from "@/apis/takes";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { showToastrError } from "@/components/commons";
import Table from "./Table";
import EmptyPage from "./EmptyPage";

const TakeList = () => {
  const [takeList, setTakeList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState();

  useEffect(() => {
    if (pageIndex) {
      getTakeListResponse();
    }
  }, [pageIndex]);

  const getTakeListResponse = async () => {
    try {
      const { data } = await getTakeList(pageIndex);
      setTakeList(data.takes);
      setTotalRecords(data.total_records);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-app--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-app--layout pb-app--layout--croppedWidth flex flex-col items-center w-full">
        <div className="flex flex-col items-center w-full space-y-8">
          <div className="max-w-[834px] w-full space-y-3">
            <h1 className="font-soehne-kraftig text-center text-[24px] leading-[28.64px] font-normal m-0">
              Takes Dashboard
            </h1>
            <p className="text-center text-[15px] leading-[22px]">
              Here’s a list of all the takes you’ve sent or scheduled. All
              previously sent takes will appear in your archive. Deleting a take
              will delete it from your visual archive.
            </p>
          </div>

          {takeList.length > 0 ? (
            <Table
              takeList={takeList}
              totalRecords={totalRecords}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              getTakeListResponse={getTakeListResponse}
            />
          ) : (
            <EmptyPage />
          )}
        </div>
      </AnimatedPage>
    </div>
  );
};

export default TakeList;
