import React, { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Toastr, Spinner, Modal, Select } from "@bigbinary/neetoui";
import { Loading, RemoveCircle } from "@bigbinary/neeto-icons";
import { PlusIcon } from "@/components/commons/PBIcons";
import countryList from "react-select-country-list";

import { dropDownListGenerator, getUnique } from "@/lib/utils";
import { EXPORT_DATE_FORMAT } from "@/lib/constants";
import { useAuthContext } from "@/lib/useAuthContext";
import { getUsers, getUser, updateUser, destroyUser } from "@/apis/users";
import { exportSubscriptionList } from "@/apis/subscriptions";
import { getDisciplines } from "@/apis/settings/disciplines";
import { showToastrError } from "@/components/commons";
import AsyncPaginateSelect from "@/components/commons/AsyncPaginateSelect";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";
import {
  Input,
  Switch,
  Button,
  Textarea,
} from "@/components/commons/SettingsFormUI";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { Button as ModalButton } from "@/components/commons/AppFormUI";

const Profile = () => {
  const locationOptions = useMemo(() => countryList().getData(), []);
  const { user, logout, setUser } = useAuthContext();
  const [listLoader, setListLoader] = useState(true);
  const [userData, setUserData] = useState({});
  const [disciplineList, setDisciplineList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionListLoad, setSubscriptionListLoad] = useState(false);
  const [initial, setInitial] = useState(true);
  const [exportBtnLoader, setExportBtnLoader] = useState(false);
  const [updateConfirmAlertOpen, setUpdateConfirmAlertOpen] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userData?.id ? userData : INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => updateUserResponse(),
  });

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    await getUserResponse();
    await getDisciplineListResponse();
    setListLoader(false);
  };

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setUserData(data.user);
      setUser({ ...user, ...data.user });
      if (initial) setSubscriptionList(data.user.follower_suggestions);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getDisciplineListResponse = async () => {
    try {
      const { data } = await getDisciplines();
      setDisciplineList(dropDownListGenerator(data.disciplines, "name"));
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getSubscriptionListResponse = async (
    search,
    loadedOptions,
    additional,
  ) => {
    try {
      setSubscriptionListLoad(true);
      const pageNumber = additional?.page || 1;

      const { data } = await getUsers(search, pageNumber);
      const responseData = dropDownListGenerator(data.users, "name");
      const dataList = getUnique(
        [...subscriptionList, ...responseData],
        "value",
      );

      setSubscriptionList(dataList);

      return {
        options: responseData,
        hasMore: data.total_records > dataList.length,
        additional: {
          page: pageNumber + 1,
        },
      };
    } catch (error) {
      showToastrError(error.data.errors);
    } finally {
      setSubscriptionListLoad(false);
    }
  };

  const updateUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          display_name: formik.values.display_name,
          url_slug: formik.values.url_slug,
          personal_url: formik.values.personal_url,
          notification_enabled: formik.values.notification_enabled,
          location: formik.values.location,
          location_short_name: formik.values.location_short_name,
          discipline_ids: formik.values.discipline_ids,
          double_opt_in_subscription: formik.values.double_opt_in_subscription,
          follower_ids: formik.values.follower_ids,
          og_description: formik.values.og_description,
          og_title: formik.values.og_title,
          default_take_header_attachment_attributes:
            formik.values.default_take_header_attachment_attributes,
        },
      });
      Toastr.success(data.message);
      getUserResponse();
      setUpdateConfirmAlertOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateUserAccountClosureResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          account_closure_reason: formik.values.account_closure_reason,
        },
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const destroyUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyUser(user.id);
      setAlertOpen(false);
      Toastr.success(data.message);
      logout();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const exportAudienceListResponse = async () => {
    try {
      setExportBtnLoader(true);
      const { data } = await exportSubscriptionList();
      const url = window.URL.createObjectURL(
        new Blob([data.subscription_data]),
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `audiences_${dayjs().format(EXPORT_DATE_FORMAT)}.csv`;
      link.click();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setExportBtnLoader(false);
    }
  };

  const handleDestroy = async () => {
    await updateUserAccountClosureResponse();
    await destroyUserResponse();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();
    var image = new Image();

    reader.onloadend = (e) => {
      image.src = reader.result;

      image.onload = function () {
        formik.setFieldValue("default_take_header_attachment_attributes", {
          ...formik.values.default_take_header_attachment_attributes,
          file_data: e.target.result,
          file_name: file.name,
          imageLoaded: true,
          height: image["height"],
          width: image["width"],
          _destroy: false,
        });
      };
    };
    reader.readAsDataURL(file);
  };

  if (listLoader) {
    return (
      <div className="flex h-screen justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-settings--layout flex flex-col w-full">
        <h2 className="font-soehne-kraftig pb-6 text-[24px] font-medium leading-snug border-b border-gray-300 lg:text-[32px]">
          Profile
        </h2>

        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            DISPLAY NAME
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="display_name"
              name="display_name"
              value={formik.values.display_name}
              onChange={formik.handleChange}
              error={
                Boolean(
                  formik.touched.display_name && formik.errors.display_name,
                ) && formik.errors.display_name
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              This is the name of your PencilBooth that your subscribers will
              see i.e ‘Thank you for reading [Display Name]’s PencilBooth!
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Location
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Select
              cacheOptions
              name="location"
              id="location"
              placeholder="Select a Location"
              value={locationOptions.find(
                (location) =>
                  formik.values.location_short_name == location.value,
              )}
              onChange={(opt) => {
                formik.setFieldValue("location", opt.label);
                formik.setFieldValue("location_short_name", opt.value);
              }}
              options={locationOptions}
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Enter your current country if you wish to be found on the homepage
              via search..
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Disciplines
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Select
              isMulti
              isSearchable
              cacheOptions
              name="discipline_ids"
              id="discipline_ids"
              placeholder="Select a Discipline"
              value={disciplineList.filter((discipline) =>
                formik.values.discipline_ids?.includes(discipline.value),
              )}
              onChange={(opt) => {
                formik.setFieldValue(
                  "discipline_ids",
                  opt.map((discipline) => discipline.value),
                );
              }}
              options={disciplineList}
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Select up to three.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            PENCILBOOTH URL
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="url_slug"
              name="url_slug"
              value={formik.values.url_slug}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.url_slug && formik.errors.url_slug) &&
                formik.errors.url_slug
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              {`This is the URL (${window.location.host}/${formik.values.url_slug}) where people can subscribe to your PencilBooth.`}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            PERSONAL URL
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="personal_url"
              name="personal_url"
              value={formik.values.personal_url}
              onChange={formik.handleChange}
              placeholder="Please enter full URL, including https://"
              error={
                Boolean(
                  formik.touched.personal_url && formik.errors.personal_url,
                ) && formik.errors.personal_url
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Enter a link to an external site where people can find out more
              about you and your work. This goes at the footer of your takes and
              is separate to your bio and social links in your Booth.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            OpenGraph Title
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Textarea
              id="og_title"
              name="og_title"
              value={formik.values.og_title}
              onChange={formik.handleChange}
              placeholder={`${userData.display_name} is on PencilBooth - a visual newsletter platform for creative types.`}
              rows={3}
              style={{ height: "auto" }}
              error={
                Boolean(formik.touched.og_title && formik.errors.og_title) &&
                formik.errors.og_title
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Override your default opengraph title (250 characters max).
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            OpenGraph Description
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Textarea
              id="og_description"
              name="og_description"
              rows={3}
              style={{ height: "auto" }}
              placeholder={`Sign up now to receive updates directly from ${userData.display_name} in your inbox.`}
              value={formik.values.og_description}
              onChange={formik.handleChange}
              error={
                Boolean(
                  formik.touched.og_description && formik.errors.og_description,
                ) && formik.errors.og_description
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Override your default opengraph description (250 characters max).
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            NEW SUBSCRIBER NOTIFICATION
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.notification_enabled}
              onChange={() =>
                formik.setFieldValue(
                  "notification_enabled",
                  !formik.values.notification_enabled,
                )
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Get an email alert when you have a new subscriber.
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Double Opt-in Subscription
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Switch
              checked={formik.values.double_opt_in_subscription}
              onChange={() =>
                formik.setFieldValue(
                  "double_opt_in_subscription",
                  !formik.values.double_opt_in_subscription,
                )
              }
            />
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Toggle this on if you want new subscribers to confirm via email
              after initially signing up from your booth.
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Follow my Friends
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <AsyncPaginateSelect
              isMulti
              cacheOptions
              isSearchable
              isClearable
              debounceTimeout={300}
              isLoading={subscriptionListLoad}
              name="follower_ids"
              id="follower_ids"
              placeholder="Select Followers"
              strategy="fixed"
              value={subscriptionList.filter((subscription) =>
                formik.values.follower_ids?.includes(subscription.value),
              )}
              onChange={(opt) => {
                setInitial(false);
                if (opt?.length > 0) {
                  formik.setFieldValue(
                    "follower_ids",
                    opt.map((subscription) => subscription.value),
                  );
                } else {
                  formik.setFieldValue("follower_ids", []);
                }
              }}
              loadOptions={getSubscriptionListResponse}
              additional={{ page: 1 }}
              defaultOptions={subscriptionList}
            />

            <span className="text-sm leading-5 text-lightBlack opacity-60">
              Choose up to four other booths to be featured at the bottom of
              your Takes.
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            Default Take Header
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <div className="flex justify-center items-center w-full px-2.5 py-12 rounded-2xl border-dashed border-2 border-[#969797] relative">
              {formik.values.default_take_header_attachment_attributes &&
              formik.values.default_take_header_attachment_attributes
                ._destroy !== 1 ? (
                <>
                  <img
                    src={
                      formik.values.default_take_header_attachment_attributes
                        .file_data ||
                      formik.values.default_take_header_attachment_attributes
                        .src
                    }
                  />
                  <div
                    className="absolute top-0 right-0 cursor-pointer z-50"
                    onClick={() =>
                      formik.setFieldValue(
                        "default_take_header_attachment_attributes",
                        {
                          ...formik.values
                            .default_take_header_attachment_attributes,
                          _destroy: 1,
                        },
                      )
                    }
                  >
                    <RemoveCircle size="30" />
                  </div>
                </>
              ) : (
                <div className="flex flex-col justify-start items-center space-y-4">
                  <div>
                    <PlusIcon />
                  </div>

                  <span className="text-base text-[#969797]">Add image</span>
                </div>
              )}

              <input
                id="file_data"
                name="file_data"
                type="file"
                multiple
                className="h-full w-full absolute cursor-pointer opacity-0 top-0 left-0"
                onChange={(event) => handleChange(event)}
                accept="image/png, image/gif, image/jpeg, image/webp, image/jpg, image/avif"
              />
            </div>
            <span className="text-sm leading-5 text-lightBlack opacity-60">
              If you wish to use an image for the header of your takes, please
              upload it here. You can override the default header image on each
              individual take if you like.
            </span>
          </div>
        </div>
        <div className="flex items-center justify-end py-4">
          <Button
            type="submit"
            onClick={() => setUpdateConfirmAlertOpen(true)}
            label="Update"
          />
        </div>
        <div className="mt-12 space-y-5 rounded-md bg-white px-6 py-5">
          <div className="flex flex-col w-full gap-y-3">
            <h4 className="font-soehne-kraftig text-lg font-medium text-lightBlack">
              Delete your account
            </h4>
            <p className="font-soehne-mono text-sm text-lightBlack">
              By deleting your PencilBooth account, you will not be able to
              access your current subscriber list, existing takes and any
              associated assets. All data is removed from our system and cannot
              be recovered. We'd suggest archiving your subscriber list here
              before closing your PencilBooth account.
            </p>
          </div>
          <Button
            onClick={() => setAlertOpen(true)}
            label="Close account"
            style="danger"
          />
        </div>
      </AnimatedPage>

      <Modal
        isOpen={alertOpen}
        onClose={() => setAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Delete your account</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>
            We’ll be sad to see you go, but we get it! Before you take off, just
            confirming that you’ve downloaded your current subscriber list? If
            not, click{" "}
            {exportBtnLoader ? (
              <Loading className="inline animate-spin-slow" />
            ) : (
              <Link
                className="underline"
                onClick={() => exportAudienceListResponse()}
              >
                here
              </Link>
            )}
            &nbsp;to do it. Also just a reminder that deleting your account is
            permanent. Once you hit that big red button we can’t get anything
            back for you.
          </p>
          <br />
          <p>
            Finally, it would be really helpful to know why you are leaving so
            we can take any feedback or experiences on board to help shape the
            future of PencilBooth. We know it’s annoying and you just want to
            get out of here, but anything you can share would be incredibly
            helpful for our team to know!
          </p>
          <Textarea
            id="account_closure_reason"
            name="account_closure_reason"
            value={formik.values.account_closure_reason}
            defaultValue={formik.values.account_closure_reason}
            onBlur={formik.handleChange}
          />
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <ModalButton
            label="I understand, delete away!"
            type="submit"
            style="danger"
            loading={btnLoader}
            onClick={() => handleDestroy()}
          />

          <ModalButton
            label="Cancel"
            onClick={() => setAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={updateConfirmAlertOpen}
        onClose={() => setUpdateConfirmAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Update Settings</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          Are you sure you want to update profile settings?
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <ModalButton
            label="Yes, Proceed"
            type="submit"
            style="danger"
            loading={btnLoader}
            onClick={() => formik.handleSubmit()}
          />

          <ModalButton
            label="Cancel"
            onClick={() => setUpdateConfirmAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Profile;
