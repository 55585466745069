import React from "react";
import { LinkButton } from "@/components/commons/HomeFormUI";

const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-3 md:flex-row">
      <LinkButton
        to="mailto:hi@pencilbooth.com"
        target="_blank"
        rel="noopener noreferrer"
        style="link"
        label="hi@pencilbooth.com"
        rounded
      />
      <LinkButton to="/terms" style="link" label="Terms & Conditions" rounded onClick={() => window.scrollTo(0, 0)} />
      <LinkButton to="/privacy" style="link" label="Privacy" rounded onClick={() => window.scrollTo(0, 0)} />
    </div>
  );
};

export default Footer;
