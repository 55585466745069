import React from "react";
import GreenTick from "@/images/green-tick.png";

const UnsubscribeAcknowledgement = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img src={GreenTick} className="mb-3" />
      <h3>You have been successfully unsubscribed from our system!</h3>
    </div>
  );
};

export default UnsubscribeAcknowledgement;
