import React from "react";

const DiscoverTag = ({ name, setSearch }) => {
  return (
    <div
      className="flex justify-center items-center h-[32px] rounded-full bg-white px-4 py-1.5 text-base cursor-pointer leading-normal"
      onClick={() => {
        document.body.classList.remove("!overflow-hidden");
        setSearch(name);
      }}
    >
      {name}
    </div>
  );
};

export default DiscoverTag;
