import React from "react";
import classnames from "classnames";
import { Tooltip, Button, Label } from "@bigbinary/neetoui";
import { Info } from "@bigbinary/neeto-icons";

const FileUploader = ({
  id,
  name,
  label,
  error,
  helpText,
  required,
  disabled,
  handleChange = () => {},
  ...otherProps
}) => {
  return (
    <div className="neeto-ui-input__wrapper">
      <div className="neeto-ui-input__label-wrapper">
        {label && <Label required={required}>{label}</Label>}
      </div>
      <div className="neeto-ui-input">
        <input
          disabled={disabled}
          id={id}
          required={required}
          onChange={handleChange}
          type="file"
          name={name}
          className="block w-full text-base pr-[14px] file:border-0 file:bg-[#E5E0D9] file:mr-[14px] file:py-[14px] file:px-[16px]"
          {...otherProps}
        />
      </div>
      {!!error && (
        <p className="text-sm leading-5 text-red-500 opacity-60">{error}</p>
      )}

      {!!helpText && <p className="text-sm leading-5 opacity-60">{helpText}</p>}
    </div>
  );
};

export default FileUploader;
