import React from "react";

const FeatureCard = ({ feature }) => {
  const { title, description, icon } = feature;

  return (
    <div className="gap-6 p-7 bg-white rounded-3xl">
      <div className="text-[36px]">{icon}</div>
      <div className="flex flex-col pt-6 gap-y-4">
        <h3 className="text-base md:text-xl font-medium text-lightBlack font-soehne-kraftig">
          {title}
        </h3>
        <p className="text-sm font-normal text-lightBlack font-soehne-mono">
          {description}
        </p>
      </div>
    </div>
  );
};

export default FeatureCard;
