import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toastr, Tooltip } from "@bigbinary/neetoui";
import { NeetoQuiz } from "@bigbinary/neeto-icons";
import { useFormik } from "formik";

import { useAuthContext } from "@/lib/useAuthContext";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { Input, Button } from "@/components/commons/HomeFormUI";
import { showToastrError } from "@/components/commons";
import Header from "@/components/commons/Header";
import { OTP_LENGTH } from "@/lib/constants";
import { verifyToken } from "@/apis/verification_tokens";
import { TOKEN_INITIAL_VALUE, TOKEN_VALIDATION_SCHEMA } from "./constants";

const VerificationToken = () => {
  const location = useLocation();
  const { login } = useAuthContext();
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false);
  const token = location?.search?.split("?token=")[1];

  const formik = useFormik({
    initialValues: TOKEN_INITIAL_VALUE,
    validationSchema: TOKEN_VALIDATION_SCHEMA,
    onSubmit: () => {
      verifyTokenResponse();
    },
  });

  const verifyTokenResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await verifyToken({
        verification_token: {
          otp: formik.values.otp,
          token: token,
        },
      });

      await login(data.token, data.user);
      Toastr.success(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);

      if (error.response.status === 403) {
        navigate("/login", { replace: true });
      }
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="pb-home--layout-wrapper flex flex-col min-h-screen items-center w-full bg-primary">
      <Header bgYellow hideSearch />

      <AnimatedPage className="pb-home--layout flex flex-col flex-grow justify-center items-center w-full">
        <h2 className="font-soehne-kraftig mb-6 text-4xl font-medium">
          Email Verification
        </h2>

        <div className="flex flex-col space-y-4 w-full max-w-[440px]">
          <div className="flex flex-col gap-2">
            <div className="flex items-center space-x-2">
              <label className="text-sm uppercase">OTP</label>
              <Tooltip
                content={<p className="font-soehne-mono">Please enter the One Time Password (OTP) which was just sent to your email to continue</p>}
                position="top"
                theme="light"
              >
                <p><NeetoQuiz size={16}/></p>
              </Tooltip>
            </div>
            <Input
              id="otp"
              name="otp"
              maxLength={OTP_LENGTH}
              value={formik.values.otp}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.otp && formik.errors.otp) &&
                formik.errors.otp
              }
            />
          </div>

          <Button
            onClick={() => formik.handleSubmit()}
            type="submit"
            label="VERIFY"
            loading={btnLoader}
            loginBtn
          />
        </div>
      </AnimatedPage>
    </div>
  );
};

export default VerificationToken;
