import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Spinner, Toastr, Modal } from "@bigbinary/neetoui";

import { OTP_LENGTH } from "@/lib/constants";
import { useAuthContext } from "@/lib/useAuthContext";
import { getUser, updateUser } from "@/apis/users";
import { createVerifyEmail, verifyEmail } from "@/apis/email_change";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { Button as ModalButton } from "@/components/commons/AppFormUI";
import { Input, Button } from "@/components/commons/SettingsFormUI";
import { USER_INITIAL_VALUE, USER_VALIDATION_SCHEMA } from "./constants";

const AccountSettings = () => {
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [userDataLoad, setUserDataLoad] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [token, setToken] = useState();
  const [otp, setOtp] = useState();
  const [emailChanged, setEmailChanged] = useState(false);
  const [emailVerificationAlert, setEmailVerificationAlert] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userData?.id ? userData : USER_INITIAL_VALUE,
    validationSchema: USER_VALIDATION_SCHEMA,
    onSubmit: () => updateUserResponse(),
  });

  useEffect(() => {
    getUserResponse();
  }, []);

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setUserData(data.user);
      setUserDataLoad(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          name: formik.values.name,
        },
      });

      if (emailChanged) {
        createVerifyEmailResponse();
      } else {
        Toastr.success(data.message);
      }
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
      setEmailChanged(false);
    }
  };

  const createVerifyEmailResponse = async () => {
    try {
      const { data } = await createVerifyEmail({
        verification_token: { email: formik.values.email },
      });
      Toastr.success(data.message);
      setToken(data.verification_token.token);
      setEmailVerificationAlert(true);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateVerifyEmailResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await verifyEmail({
        verification_token: {
          otp: otp,
          token: token,
          email: formik.values.email,
        },
      });
      setUser(data.user);
      Toastr.success(data.message);
      setOtp();
      setEmailVerificationAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (userDataLoad) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-settings--layout flex flex-col w-full">
        <h2 className="font-soehne-kraftig pb-6 text-[24px] font-medium leading-snug border-b border-gray-300 lg:text-[32px]">
          Account
        </h2>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            FULL NAME
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-1 py-4 md:grid-cols-3 border-b border-gray-300">
          <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
            CONTACT EMAIL
          </label>
          <div className="col-span-2 flex flex-col w-full gap-5 lg:w-8/12">
            <Input
              id="email"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={(e) => {
                setEmailChanged(true);
                formik.setFieldValue("email", e.target.value);
              }}
              error={
                Boolean(formik.touched.email && formik.errors.email) &&
                formik.errors.email
              }
            />
          </div>
        </div>
        <div className="flex items-center justify-end py-4">
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            label="Update"
            loading={btnLoader}
          />
        </div>
      </AnimatedPage>

      <Modal
        isOpen={emailVerificationAlert}
        onClose={() => setEmailVerificationAlert(false)}
        className="pb-modal"
      >
        <Modal.Header>
          <h4>Email verification</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <Input
            id="otp"
            name="otp"
            maxLength={OTP_LENGTH}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            helpText="You will receive an email with an otp. Please enter the same otp here to verify!"
          />
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <ModalButton
            loading={btnLoader}
            label="Verify"
            onClick={() => updateVerifyEmailResponse()}
          />
          <ModalButton
            label="Cancel"
            onClick={() => setEmailVerificationAlert(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AccountSettings;
