import React from "react";

import { LinkButton } from "@/components/commons/HomeFormUI";

const SignupCTA = () => (
  <div className="flex flex-col justify-center items-center gap-y-8 bg-[#E4D8C5] rounded-[48px] border-[5px] border-black max-w-[646px] w-full md:w-11/12 p-10">
    <h2 className="text-[39px] leading-[53px] font-medium font-soehne-kraftig text-center">
      Sign up now
    </h2>
    <p className="text-[15px] leading-[22px] font-normal text-center">
      Getting started with PencilBooth is free, easy, and bound to make you forget about the fleeting impermanence of life for at least five to seven minutes.
      <br />
      <br />
      What are you waiting for?
    </p>

    <LinkButton to="/signup" label="CLAIM YOUR BOOTH" fullWidth onClick={() => window.scrollTo(0, 0)} />
  </div>
);

export default SignupCTA;
