import React from "react";
import * as pluralize from "pluralize";
import { getProfileUrl } from "@/lib/utils";

const FriendCard = ({ subscription }) => {
  return (
    <a href={getProfileUrl(subscription.url_slug)}>
      <div className="bg-[#F1F1F1] py-6 px-4 rounded-2xl flex flex-col w-full items-center space-y-3 dark:bg-[#333333]">
        <div className="pb-take--logo w-[60px] h-[60px] border-2 border-black dark:border-white">
          <img
            className="h-full w-full object-cover"
            src={subscription.logo_url}
            alt="User Logo"
          />
        </div>

        <div className="flex flex-col items-center w-full">
          <h3 className="pb-take--friend-name text-center font-soehne-kraftig line-clamp-1 dark:text-white">
            {subscription.name}
          </h3>

          <p className="pb-take--friend-takes text-center dark:text-[#ffffff99]">
            {subscription.takes_count}{" "}
            {pluralize("take", subscription.takes_count)}
          </p>
        </div>
      </div>
    </a>
  );
};

export default FriendCard;
