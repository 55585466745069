import React, { useState, useEffect } from "react";
import { Toastr } from "@bigbinary/neetoui";

import { getUser } from "@/apis/users";
import { createAttachment } from "@/apis/settings/attachments";
import { showToastrError } from "@/components/commons";
import { useAuthContext } from "@/lib/useAuthContext";

const ImageUploader = ({
  type,
  position,
  setPageLoader,
  setLogoImage,
  setCoverImage,
}) => {
  const { user, setUser } = useAuthContext();
  const [uploaded, setUploaded] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();

  useEffect(() => {
    if (uploaded) {
      createAttachmentResponse();
    }
  }, [uploadedImage]);

  const handleChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      setUploadedImage({
        file_data: e.target.result,
        file_name: file.name,
        position: position,
        caption: file.name,
        attachable_kind: type,
      });
    };

    reader.readAsDataURL(file);
    setUploaded(true);
  };

  const createAttachmentResponse = async () => {
    try {
      setPageLoader(true);
      await createAttachment({
        attachment: {
          file_data: uploadedImage.file_data,
          file_name: uploadedImage.file_name,
          attachable_kind: uploadedImage.attachable_kind,
          caption: uploadedImage.caption,
          position: uploadedImage.position,
        },
      });
      setUploaded(false);
      Toastr.success("Image uploaded!");
      setUploadedImage({});
      await getUserResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setPageLoader(false);
    }
  };

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setUser(data.user);
      setLogoImage({ id: data.user.logo_image_id, src: data.user.logo_url });
      setCoverImage({
        id: data.user.cover_image_id,
        src: data.user.cover_image_url,
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  return (
    <input
      type="file"
      accept="image/jpg, image/jpeg, image/gif, image/png"
      className="pb-settings--chrome-window--body--uploader-input"
      onChange={(event) => handleChange(event)}
    />
  );
};

export default ImageUploader;
