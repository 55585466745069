import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Edit, Delete, MenuVertical } from "@bigbinary/neeto-icons";
import { Dropdown, Tag, Modal, Toastr, Pagination } from "@bigbinary/neetoui";

import { Button } from "@/components/commons/AppFormUI";
import { getStyle, DEFAULT_PAGE_SIZE } from "@/lib/constants";
import { destroyTake, cancelScheduledTake } from "@/apis/takes";
import { showToastrError } from "@/components/commons";

const Table = ({
  takeList,
  totalRecords,
  setPageIndex,
  pageIndex,
  getTakeListResponse,
}) => {
  const navigate = useNavigate();
  const { Menu, MenuItem } = Dropdown;
  const [takeId, setTakeId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [cancelScheduleConfirmAlert, setCancelScheduleConfirmAlert] =
    useState(false);

  const destroyTakeResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyTake(takeId);
      Toastr.success(data.message);
      getTakeListResponse();
      setDeleteAlertOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const cancelScheduledTakeResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await cancelScheduledTake(takeId);
      Toastr.success(data.message);
      getTakeListResponse();
      setCancelScheduleConfirmAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const handleEditClick = (takeId) => {
    navigate(`/takes/${takeId}/edit`);
  };

  const takeEditDisablity = (take) => {
    return (
      take.rejected_at ||
      (take.scheduled && take.approved_at) ||
      take.under_review
    );
  };

  const enableCancelTake = (take) => {
    return take.scheduled && take.approved_at;
  };

  return (
    <>
      <div className="pb-app--table-wrapper w-full flex flex-col rounded-lg bg-white px-8 py-6">
        <table className="pb-app--table">
          <thead>
            <tr>
              <th>Subject</th>
              <th>Status</th>
              <th>Sent</th>
              <th>Opened</th>
              <th>Clicked</th>
              <th>Unsubscribed</th>
              <th>Bounced</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {takeList.map((take) => {
              return (
                <tr key={take.id}>
                  <td>
                    <a
                      href={
                        take.status === "Draft"
                          ? `/takes/${take.id}/edit`
                          : `/takes/${take.id}`
                      }
                    >
                      {take.subject}
                    </a>
                  </td>
                  <td>
                    <Tag
                      style={getStyle(take, "status")}
                      type="solid"
                      label={take.status}
                    />
                  </td>
                  <td>{take.sent}</td>
                  <td>{take.opened}</td>
                  <td>{take.clicked}</td>
                  <td>{take.unsubscribed}</td>
                  <td>{take.bounced}</td>
                  <td>
                    <div className="flex justify-end">
                      <Dropdown
                        customTarget={
                          <div className="pb-app--table--menu-icon cursor-pointer bg-white p-1">
                            <MenuVertical size={16} />
                          </div>
                        }
                        onClose={function noRefCheck() {}}
                      >
                        <Menu>
                          {!takeEditDisablity(take) && (
                            <MenuItem.Button
                              className="flex items-center gap-x-3"
                              onClick={() => handleEditClick(take.id)}
                            >
                              <Link
                                to={`/takes/${take.id}/edit`}
                                className="flex"
                              >
                                <Edit size={16} />
                                <span className="ml-2">Edit take</span>
                              </Link>
                            </MenuItem.Button>
                          )}
                          {enableCancelTake(take) && (
                            <MenuItem.Button className="flex items-center gap-x-3">
                              <Link
                                onClick={() => {
                                  setTakeId(take.id);
                                  setCancelScheduleConfirmAlert(true);
                                }}
                                className="flex"
                              >
                                <Edit size={16} />
                                <span className="ml-2">Cancel Schedule</span>
                              </Link>
                            </MenuItem.Button>
                          )}
                          <MenuItem.Button
                            className="flex items-center gap-x-3"
                            onClick={() => {
                              setTakeId(take.id);
                              setDeleteAlertOpen(true);
                            }}
                          >
                            <Delete size={16} />
                            <span>Delete</span>
                          </MenuItem.Button>
                        </Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="pb-app--pagination-container">
          <Pagination
            count={totalRecords}
            navigate={(page) => {
              setPageIndex(page);
            }}
            pageNo={pageIndex}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </div>
      </div>

      <Modal
        isOpen={deleteAlertOpen}
        onClose={() => setDeleteAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Destroy take</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to delete the take?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Yes, Destroy"
            type="submit"
            style="danger"
            loading={btnLoader}
            onClick={() => destroyTakeResponse()}
          />

          <Button
            label="Cancel"
            onClick={() => setDeleteAlertOpen(false)}
            style="link"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={cancelScheduleConfirmAlert}
        onClose={() => setCancelScheduleConfirmAlert(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Cancel Take Schedule</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to cancel the schedule for this take?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Yes, Cancel"
            type="submit"
            style="danger"
            loading={btnLoader}
            onClick={() => cancelScheduledTakeResponse()}
          />

          <Button
            label="Cancel"
            onClick={() => setCancelScheduleConfirmAlert(false)}
            style="link"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Table;
