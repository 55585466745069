import React from "react";
import classnames from "classnames";

const Textarea = ({
  className = "",
  error = "",
  helpText = "",
  textCount = 0,
  rows = "3",
  ...otherProps
}) => {
  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className="relative">
        <textarea
          {...otherProps}
          rows={rows}
          className={classnames(["pb-settings--textarea", className])}
        />
      </div>

      {!!error && (
        <p className="text-sm leading-5 text-red-500 opacity-60">{error}</p>
      )}
      {!!helpText && <p className="text-sm leading-5 opacity-60">{helpText}</p>}
    </div>
  );
};

export default Textarea;
