import React from "react";

const TwitterPostCard = ({ linkRoll, withLink }) => {
  const renderContent = () => {
    return (
      <div className="flex flex-col w-full max-w-[486px] px-5 py-4 bg-[#F1F1F1] dark:bg-[#333333] dark:shadow-gray-800 shadow-md rounded-lg">
        <div className="flex justify-between items-center mb-5">
          <div className="flex items-center space-x-2 text-[#1D1F21] dark:text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              class="bi bi-twitter-x"
              viewBox="0 0 16 16"
            >
              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
            </svg>

            <span className="font-soehne-kraftig">{linkRoll.username}</span>
          </div>
        </div>

        <img
          src={linkRoll.image_src}
          className="w-full h-auto rounded-2xl"
          alt="Twitter Image"
        />

        <p className="mt-5 text-xs text-[#1D1F21] dark:text-white">
          {linkRoll.description}
        </p>
      </div>
    );
  };

  return withLink ? (
    <a href={linkRoll.formatted_link} target="_blank">
      {renderContent()}
    </a>
  ) : (
    renderContent()
  );
};

export default TwitterPostCard;
