import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classnames from "classnames";
import { useFormik } from "formik";
import { Dropdown, Toastr } from "@bigbinary/neetoui";
import { Search, Close } from "@bigbinary/neeto-icons";

import { useAuthContext } from "@/lib/useAuthContext";
import { getProfileUrl, getSubscribeUrl } from "@/lib/utils";
import { expireSession } from "@/apis/sessions";
import { createFeedbackSurvey } from "@/apis/settings/feedback_surveys";
import { showToastrError } from "@/components/commons";
import PencilBoothLogo from "@/images/pencilbooth-logo.svg";
import Hamburger from "@/images/icons/hamburger-icon.svg";
import {
  UserIcon,
  UserBoxIcon,
  CodeIcon,
  PaperIcon,
  GearIcon,
  MessageIcon,
  SignOutIcon,
  PaidIcon,
  LinkIcon,
} from "@/components/commons/PBIcons";

import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";
import FeedbackModal from "./FeedbackModal";

const getClassNames = (isActive, mobileMenu, primaryBtn) => {
  return classnames("pb-home--nav-btn", {
    "!bg-opacity-70": !isActive,
    "px-4 py-2": !mobileMenu,
    "px-6 py-3": mobileMenu,
    "pb-home--nav-btn--primary": primaryBtn,
  });
};

const NavItem = ({ label, to, mobileMenu, primaryBtn }) => {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        getClassNames(isActive, mobileMenu, primaryBtn)
      }
      to={to}
      onClick={() => {
        document.body.classList.remove("!overflow-hidden");
        setIsOpen(!isOpen);
      }}
    >
      {label}
    </NavLink>
  );
};

const Header = ({ hideSearch = false, bgYellow = false }) => {
  const location = useLocation();
  const { logout, user, setSearch } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const [emailCopied, setEmailCopied] = useState(false);
  const [feedbackModalOpen, setFeedbcakModalOpen] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const { Menu, MenuItem, Divider } = Dropdown;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => createFeedbackSurveyResponse(),
  });

  useEffect(() => {
    if (location) {
      setIsLandingPage(
        location.pathname.split("/").pop() === "discovery" ||
          location.pathname.split("/").pop() === "branding",
      );
    }
  }, [location]);

  useEffect(() => {
    if (emailCopied) {
      Toastr.success("Link copied to clipboard!");
      setEmailCopied(false);
    }
  }, [emailCopied]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await expireSession();
      await logout();
      Toastr.success(response.data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createFeedbackSurveyResponse = async () => {
    try {
      setBtnLoader(true);
      const response = await createFeedbackSurvey({
        feedback_survey: { message: formik.values.message },
      });
      setFeedbcakModalOpen(false);
      Toastr.success(response.data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const renderUserDropdown = () => {
    return (
      <Dropdown
        customTarget={
          <div
            className={`pb-home--nav-btn flex items-center cursor-pointer gap-x-2 !bg-opacity-70 py-2 ${
              user.logo_url ? "lg:py-1 pl-2" : "lg:py-2 pl-4"
            } pr-4 max-w-[200px] lg:max-w-[70px] xl:max-w-[200px]`}
          >
            {user.logo_url && (
              <img
                alt="user logo"
                src={user.logo_url}
                className="h-6 w-6 rounded-full shrink-0"
              />
            )}

            <span
              style={{
                wordWrap: "normal",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {user.display_name}
            </span>
          </div>
        }
        onClose={function noRefCheck() {}}
      >
        <Menu>
          <MenuItem.Button
            to="/settings/profile"
            className="flex items-center gap-x-3"
            onClick={() => {
              setIsOpen(false);
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <UserIcon />
            <span className="text-base">Profile</span>
          </MenuItem.Button>
          <MenuItem.Button
            to="/settings/branding"
            className="flex items-center gap-x-3"
            onClick={() => {
              setIsOpen(false);
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <UserBoxIcon />
            <span className="text-base">Booth branding</span>
          </MenuItem.Button>
          <MenuItem.Button
            to="/settings/embed_subscriber_form"
            className="flex items-center gap-x-3"
            onClick={() => {
              setIsOpen(false);
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <CodeIcon />
            <span className="text-base">Embed subscriber form</span>
          </MenuItem.Button>
          <MenuItem.Button className="flex items-center gap-x-3">
            <LinkIcon />
            <CopyToClipboard
              text={getSubscribeUrl(user.url_slug)}
              onCopy={() => setEmailCopied(true)}
            >
              <span className="text-base">Copy subscriber link</span>
            </CopyToClipboard>
          </MenuItem.Button>

          <Divider />

          <MenuItem.Button
            to="/settings/paid_subscription"
            className="flex items-center gap-x-3"
            onClick={() => {
              setIsOpen(false);
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <PaidIcon />
            <span className="text-base">Paid subscriptions</span>
          </MenuItem.Button>

          <Divider />

          <MenuItem.Button
            to="/settings/billing"
            className="flex items-center gap-x-3"
            onClick={() => {
              setIsOpen(false);
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <PaperIcon />
            <span className="text-base">Billing</span>
          </MenuItem.Button>
          <MenuItem.Button
            to="/settings/account"
            className="flex items-center gap-x-3"
            onClick={() => {
              setIsOpen(false);
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <GearIcon />
            <span className="text-base">Account settings</span>
          </MenuItem.Button>

          {user.superadmin && (
            <>
              <Divider />
              <MenuItem.Button
                className="flex items-center gap-x-3"
                to="/super_admin/users"
              >
                <UserBoxIcon />
                <span className="text-base">Super admin</span>
              </MenuItem.Button>
              <MenuItem.Button
                className="flex items-center gap-x-3"
                to="/super_admin/disciplines"
              >
                <PaperIcon />
                <span className="text-base">Disciplines</span>
              </MenuItem.Button>
              <MenuItem.Button
                className="flex items-center gap-x-3"
                to="/super_admin/takes"
              >
                <PaperIcon />
                <span className="text-base">Under review takes</span>
              </MenuItem.Button>
            </>
          )}

          <Divider />

          <MenuItem.Button
            className="flex items-center gap-x-3"
            onClick={() => {
              setIsOpen(false);
              formik.resetForm();
              setFeedbcakModalOpen(true);
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <MessageIcon />
            <span className="text-base">Send us some feedback</span>
          </MenuItem.Button>
          <MenuItem.Button
            className="flex items-center gap-x-3"
            onClick={() => handleLogout()}
          >
            <SignOutIcon />
            <span className="text-base">Logout</span>
          </MenuItem.Button>
        </Menu>
      </Dropdown>
    );
  };

  return (
    <>
      <div
        className={classnames("fixed z-20 w-full", {
          "bg-primary": bgYellow,
          "bg-secondary": !bgYellow,
        })}
      >
        <div className="px-6 py-5 lg:py-8">
          <div className="relative grid w-full grid-cols-1 lg:grid-cols-3">
            <div
              className="absolute left-0 top-0 flex items-center justify-center lg:hidden"
              onClick={() => {
                toggleMenu();
                document.body.classList.add("!overflow-hidden");
              }}
            >
              <img src={Hamburger} />
            </div>

            <div className="hidden items-center gap-x-2 lg:flex">
              {user && (
                <NavItem
                  label="Visual Archive"
                  to={getProfileUrl(user.url_slug)}
                />
              )}

              {!user && (
                <>
                  <NavItem label="About" to="/about" />
                </>
              )}

              {!hideSearch && (
                <div
                  onClick={() => {
                    setSearch(true);
                    document.body.classList.add("!overflow-hidden");
                  }}
                  className="flex cursor-pointer gap-x-2 pb-home--nav-btn !bg-opacity-70 px-4 py-2"
                >
                  <Search size={18} />
                  <span>Search</span>
                </div>
              )}
            </div>
            <NavLink className="justify-self-center" to="/discovery">
              <img
                alt="PencilBooth Logo"
                className="h-6 w-auto lg:h-8"
                src={PencilBoothLogo}
              />
            </NavLink>
            {user && (
              <div className="hidden items-center gap-x-2 lg:flex justify-end">
                <NavItem label="Audience" to="/audience" />
                <NavItem label="Takes" to="/takes" />
                <NavItem label="Create" to="/takes/new" primaryBtn />
                {renderUserDropdown()}
              </div>
            )}

            {!hideSearch && (
              <div
                className="absolute right-0 top-0 flex items-center justify-center lg:hidden"
                onClick={() => {
                  setSearch(true);
                  document.body.classList.add("!overflow-hidden");
                }}
              >
                <Search size={24} />
              </div>
            )}

            {!user && (
              <div className="hidden items-center gap-x-2 lg:flex justify-end">
                <NavItem label="Login" to="/login" />
                <NavItem label="Sign Up" to="/signup" primaryBtn />
              </div>
            )}
          </div>

          {isOpen && (
            <div className="pb-home--mobile-nav-container absolute left-0 top-0 h-screen w-full bg-primary z-1000">
              <div
                className="absolute left-6 top-6 flex items-center justify-center"
                onClick={() => {
                  toggleMenu();
                  document.body.classList.remove("!overflow-hidden");
                }}
              >
                <Close size={24} />
              </div>

              <div className="flex justify-center w-full px-6 py-5">
                <NavLink to="/discovery">
                  <img
                    alt="PencilBooth Logo"
                    className="h-6 w-auto lg:h-8"
                    src={PencilBoothLogo}
                  />
                </NavLink>
              </div>

              {!user && (
                <div className="flex flex-col items-center justify-center gap-y-4 h-full">
                  <NavItem label="About" to="/about" mobileMenu />
                  <NavItem label="Login" to="/login" mobileMenu />
                  <NavItem label="Sign Up" to="/signup" mobileMenu primaryBtn />
                </div>
              )}

              {user && (
                <div
                  className="flex flex-col items-center justify-center gap-y-4"
                  style={{ height: "calc(100vh - 64px)" }}
                >
                  <NavItem
                    label="Visual Archive"
                    to={getProfileUrl(user.url_slug)}
                    mobileMenu
                  />
                  <NavItem label="Audience" to="/audience" mobileMenu />
                  <NavItem label="Takes" to="/takes" mobileMenu />
                  <NavItem
                    label="Create"
                    to="/takes/new"
                    primaryBtn
                    mobileMenu
                  />
                  {renderUserDropdown()}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <FeedbackModal
        feedbackModalOpen={feedbackModalOpen}
        setFeedbcakModalOpen={setFeedbcakModalOpen}
        formik={formik}
        btnLoader={btnLoader}
      />
    </>
  );
};

export default Header;
