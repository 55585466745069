import axios from "axios";

const getUsersUrl = (id) => {
  if (id) {
    return `/api/v1/users/${id}`;
  }

  return `/api/v1/users`;
};

export const getUsers = (searchParams, pageIndex) => {
  return axios.get(`${getUsersUrl()}?page=${pageIndex}&q[display_name_i_cont_any]=${searchParams}`);
};

export const getUser = (userId) => {
  return axios.get(getUsersUrl(userId));
};

export const createUser = (payload) => {
  return axios.post(getUsersUrl(), payload);
};

export const updateUser = (userId, payload) => {
  return axios.put(getUsersUrl(userId), payload);
};

export const destroyUser = (userId) => {
  return axios.delete(getUsersUrl(userId));
};
