import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Toastr } from "@bigbinary/neetoui";

import AnimatedPage from "@/components/commons/AnimatedPage";
import { Input, Button } from "@/components/commons/HomeFormUI";
import Header from "@/components/commons/Header";
import { createSession } from "@/apis/sessions";
import { MAX_STRING_LENGTH } from "@/lib/constants";
import { showToastrError } from "@/components/commons";
import { LOGIN_INITIAL_VALUE, LOGIN_VALIDATION_SCHEMA } from "./constants";

const Login = () => {
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false);

  const formik = useFormik({
    initialValues: LOGIN_INITIAL_VALUE,
    validationSchema: LOGIN_VALIDATION_SCHEMA,
    onSubmit: () => {
      createUserSessionResponse();
    },
  });

  const createUserSessionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSession({
        session: formik.values,
      });
      Toastr.success(data.message);
      navigate(`/verification_token?token=${data.token}`, {
        replace: true,
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="pb-home--layout-wrapper flex flex-col min-h-screen items-center w-full bg-primary">
      <Header bgYellow hideSearch />

      <AnimatedPage className="pb-home--layout flex flex-col flex-grow justify-center items-center w-full">
        <h2 className="font-soehne-kraftig mb-6 text-4xl font-medium">Login</h2>

        <div className="flex flex-col space-y-4 w-full max-w-[440px]">
          <div className="flex flex-col gap-2">
            <label className="text-sm uppercase">Email Address</label>
            <Input
              id="email"
              name="email"
              type="email"
              maxLength={MAX_STRING_LENGTH}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.email && formik.errors.email) &&
                formik.errors.email
              }
            />
          </div>

          <Button
            onClick={() => formik.handleSubmit()}
            type="submit"
            label="LOGIN"
            loading={btnLoader}
            loginBtn
          />

          <p className="text-center text-sm leading-normal">
            Don't have an account? PencilBooth is free forever for your first
            100 true fans. Go on and <br />
            <Link className="underline" to="/signup" onClick={() => window.scrollTo(0, 0)}>
              claim your Booth now
            </Link>
            .
          </p>
        </div>
      </AnimatedPage>
    </div>
  );
};

export default Login;
