import React, { useEffect, useRef } from "react";
import { Modal } from "@bigbinary/neetoui";

import { Input } from "@/components/commons/SettingsFormUI";
import { Button } from "@/components/commons/AppFormUI";

const FormModal = ({
  disciplineModalOpen,
  setDisciplineModalOpen,
  formik,
  btnLoader,
}) => {
  return (
    <Modal
      isOpen={disciplineModalOpen}
      onClose={() => setDisciplineModalOpen(false)}
      className="pb-modal"
    >
      <Modal.Header>
        <h4>{formik.values.id ? "Edit Discipline" : "Add Discipline"}</h4>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <Input
          name="name"
          id="name"
          placeholder="Enter name"
          defaultValue={formik.values.name}
          onBlur={(e) => formik.setFieldValue("name", e.target.value)}
          error={
            Boolean(formik.touched.name && formik.errors.name) &&
            formik.errors.name
          }
        />
      </Modal.Body>
      <Modal.Footer className="flex gap-x-2">
        <Button
          label={formik.values.id ? "Update" : "Create"}
          loading={btnLoader}
          type="submit"
          onClick={() => formik.handleSubmit()}
        />

        <Button
          label="Cancel"
          onClick={() => setDisciplineModalOpen(false)}
          style="modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default FormModal;
