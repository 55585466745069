import React from "react";
import classnames from "classnames";

const Input = ({
  className = "",
  type = "text",
  error = "",
  prefix,
  suffix,
  ...otherProps
}) => {
  return (
    <div className="flex flex-col w-full gap-y-2">
    <div className={classnames("pb-home--input-wrapper", { "pl-4": prefix, "pr-4": suffix })}>
      {prefix && <span className="pb-home--input-prefix">{prefix}</span>}
      <input
        type={type}
        className={classnames(["pb-home--input", className], { "pl-4": !prefix, "pr-4": !suffix })}
        {...otherProps}
      />
      {suffix && <span className="pb-home--input-suffix">{suffix}</span>}
    </div>

      {!!error && (
        <p className="text-sm leading-5 text-red-500 opacity-60">{error}</p>
      )}
    </div>
  );
};

export default Input;
