import React, { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { setAuthHeaders, resetAuthTokens } from "@/apis/axios";
import { AUTH_TOKEN } from "@/lib/constants";

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(userData);
  const [hideHeader, setHideHeader] = useState(false);
  const [search, setSearch] = useState(false);

  const login = async (token, data) => {
    await localStorage.setItem(AUTH_TOKEN, token);
    setUser(data);
    await setAuthHeaders();

    if (data.first_time_enter) {
      navigate("/settings/branding", { replace: true });
    } else {
      navigate("/discovery", { replace: true });
    }
  };

  const logout = async () => {
    await localStorage.removeItem(AUTH_TOKEN);
    setUser(null);
    await resetAuthTokens();
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      login,
      logout,
      user,
      setUser,
      hideHeader,
      setHideHeader,
      search,
      setSearch,
    }),
    [user, hideHeader, search],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
