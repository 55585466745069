import React from "react";
import { Modal } from "@bigbinary/neetoui";

import { useAuthContext } from "@/lib/useAuthContext";
import { Button } from "@/components/commons/AppFormUI";

const ConfirmationModal = ({
  sendConfirmAlertOpen,
  setSendConfirmAlertOpen,
  sendNowBtnLoader,
  subscribersType,
  handleSubmit,
}) => {
  const { user } = useAuthContext();

  const subscriberSize = () => {
    if (subscribersType.includes("free") && subscribersType.includes("paid")) {
      return Number(user.freeSubscriberSize + user.paidSubscriberSize);
    } else if (subscribersType.includes("free")) {
      return user.freeSubscriberSize;
    } else if (subscribersType.includes("paid")) {
      return user.paidSubscriberSize;
    } else {
      return user.subscriberSize;
    }
  };

  return (
    <Modal
      isOpen={sendConfirmAlertOpen}
      onClose={() => setSendConfirmAlertOpen(false)}
      className="pb-alert"
    >
      <Modal.Header>
        <h4>Publish your take</h4>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <p>
          You all good to go? Remember, there is no going back. Metaphorically.
          It’s highly likely that we’ve been stuck in a never-ending cycle of
          death and rebirth since beginningless time. You should still send this
          out though. Only if you’re ready though (You’re ready, we can feel
          it!)
        </p>

        <ul>
          <li className="list-disc list-inside">
            Sending to {subscriberSize()} subscribers via email
          </li>
          <li className="list-disc list-inside">Sending Now</li>
        </ul>
      </Modal.Body>
      <Modal.Footer className="flex gap-x-2">
        <Button
          loading={sendNowBtnLoader}
          label="Very ready to send!"
          type="submit"
          onClick={() => handleSubmit()}
        />

        <Button
          label="Cancel"
          onClick={() => setSendConfirmAlertOpen(false)}
          style="modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
