import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Toastr, Tooltip } from "@bigbinary/neetoui";
import { NeetoQuiz } from "@bigbinary/neeto-icons";

import AnimatedPage from "@/components/commons/AnimatedPage";
import { Input, Button } from "@/components/commons/HomeFormUI";
import Header from "@/components/commons/Header";
import { createUser } from "@/apis/users";
import { showToastrError } from "@/components/commons";
import { MAX_STRING_LENGTH, MAX_SLUG_LENGTH } from "@/lib/constants";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

const Signup = () => {
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      createUserResponse();
    },
  });

  const createUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createUser({
        user: { ...formik.values, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      });
      Toastr.success(data.message);
      navigate(`/verification_token?token=${data.token}`, {
        replace: true,
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="pb-home--layout-wrapper flex flex-col min-h-screen items-center w-full bg-primary">
      <Header bgYellow hideSearch />

      <AnimatedPage className="pb-home--layout flex flex-col flex-grow justify-center items-center w-full">
        <h2 className="font-soehne-kraftig mb-6 text-4xl font-medium">
          Sign Up
        </h2>

        <div className="flex flex-col space-y-4 w-full max-w-[440px]">
          <div className="flex flex-col gap-2">
            <div className="flex items-center space-x-2">
              <label className="text-sm uppercase">Email Address</label>
              <Tooltip
                content={<p className="font-soehne-mono">Please note! Users replying to your takes via email will respond directly to this address</p>}
                position="top"
                theme="light"
              >
                <p><NeetoQuiz size={16}/></p>
              </Tooltip>
            </div>
            <Input
              id="email"
              name="email"
              type="email"
              maxLength={MAX_STRING_LENGTH}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.email && formik.errors.email) &&
                formik.errors.email
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm uppercase">Your Full Name</label>
            <Input
              id="name"
              name="name"
              maxLength={MAX_STRING_LENGTH}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.name && formik.errors.name) &&
                formik.errors.name
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center space-x-2">
              <label className="text-sm uppercase">Display Name</label>
              <Tooltip
                content={<p className="font-soehne-mono">This is what your subscribers will see i.e: 'Thank you for subscribing to {formik.values.display_name ? formik.values.display_name : '[Display Name]'}’s PencilBooth!'</p>}
                position="top"
                theme="light"
              >
                <p><NeetoQuiz size={16}/></p>
              </Tooltip>
            </div>
            <Input
              id="display_name"
              name="display_name"
              maxLength={MAX_STRING_LENGTH}
              value={formik.values.display_name}
              onChange={formik.handleChange}
              error={
                Boolean(
                  formik.touched.display_name && formik.errors.display_name,
                ) && formik.errors.display_name
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm uppercase">URL</label>
            <Input
              id="url_slug"
              name="url_slug"
              maxLength={MAX_SLUG_LENGTH}
              prefix={"pencilbooth.com/"}
              value={formik.values.url_slug}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.url_slug && formik.errors.url_slug) &&
                formik.errors.url_slug
              }
            />
          </div>

          <Button
            onClick={() => formik.handleSubmit()}
            type="submit"
            label="SIGN UP"
            loading={btnLoader}
            loginBtn
          />

          <p className="text-center text-xs leading-normal">
            By creating an account, you agree to our{" "}
            <Link className="underline" to="/terms" onClick={() => window.scrollTo(0, 0)}>
              Terms of Service
            </Link>{" "}
            and have read and understood the{" "}
            <Link className="underline" to="/privacy" onClick={() => window.scrollTo(0, 0)}>
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </AnimatedPage>
    </div>
  );
};

export default Signup;
