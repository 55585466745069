import React, { useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

import {
  FreeIcon,
  PaidIcon,
  CheckboxIcon,
  CheckboxFillIcon,
} from "@/components/commons/PBIcons";

const SubscriberDropdown = ({
  formik,
  user,
  handleSubmitWithStatus,
  showPreview,
}) => {
  const subscribersType = formik.values.subscribers_type;
  const [subscribersList, setSubscribersList] = useState([
    {
      label: "Free subscribers",
      type: "free",
      count: user.freeSubscriberSize,
    },
    {
      label: "Paid subscribers",
      type: "paid",
      count: user.paidSubscriberSize,
    },
  ]);

  const buttonText = (type) => {
    if (type.length === 0) return "Select subscribers";
    if (type.includes("paid") && type.includes("free")) {
      return `All subscribers (${subscribersList.map((el) => el.count).reduce((sum, x) => sum + x)})`;
    } else {
      const data = subscribersList.find((el) => type.includes(el.type));
      return `${data.label} (${data.count})`;
    }
  };

  if (showPreview) {
    return (
      <span className="inline-flex items-center leading-[18.4px] py-1 px-2">
        {buttonText(subscribersType)}
      </span>
    );
  }

  return (
    <Menu>
      <MenuButton className="inline-flex items-center rounded py-1 px-2 focus:outline-none data-[hover]:bg-[#F2EFEC] data-[open]:bg-[#F2EFEC]">
        {buttonText(subscribersType)}
      </MenuButton>
      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom start"
          className="origin-top-left w-[300px] rounded-lg border border-solid border-[#E5E0D9] bg-white p-2 shadow-lg focus:outline-none z-50"
        >
          {subscribersList.map((data, index) => {
            return (
              <MenuItem key={index}>
                <button
                  className="flex w-full items-center justify-between p-2 font-soehne-mono"
                  onClick={() => {
                    let array = formik.values.subscribers_type;

                    if (subscribersType.includes(data.type)) {
                      array = subscribersType.filter(
                        (type) => type != data.type,
                      );
                    } else {
                      array = [...array, data.type];
                    }
                    formik.setFieldValue("subscribers_type", array);
                    handleSubmitWithStatus();
                  }}
                >
                  <div className="flex items-center gap-2 shrink-0">
                    {data.type === "paid" ? <PaidIcon /> : <FreeIcon />}
                    <span>{buttonText(data.type)}</span>
                  </div>

                  {subscribersType.includes(data.type) ? (
                    <CheckboxFillIcon />
                  ) : (
                    <CheckboxIcon />
                  )}
                </button>
              </MenuItem>
            );
          })}
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default SubscriberDropdown;
