import React from "react";
import { Link } from "react-router-dom";

import { MAX_STRING_LENGTH } from "@/lib/constants";
import { Input, Button } from "@/components/commons/SettingsFormUI";

import ImageUploader from "./ImageUploader";

const CardHeader = ({
  user,
  logoImage,
  hideControls,
  setDeletingImageId,
  setDeleteAlertOpen,
  setSubscriberModalOpen,
  mobileForm,
  ...otherProps
}) => {
  return (
    <div
      className={`flex flex-col items-center w-full gap-y-6 ${
        mobileForm
          ? "p-6 bg-white"
          : "p-6 sm:px-12 sm:pt-12 bg-white sm:bg-transparent"
      }`}
    >
      <div className="flex flex-col items-center w-full">
        <div className="overflow-hidden rounded-full">
          <img
            src={logoImage?.file_data || logoImage?.src}
            alt="artist icon"
            className="object-cover h-12 w-12"
          />
        </div>

        {!hideControls &&
          (logoImage?.id ? (
            <span
              className="text-sm leading-[24px] underline cursor-pointer"
              onClick={() => {
                setDeletingImageId(logoImage.id);
                setDeleteAlertOpen(true);
              }}
            >
              Delete
            </span>
          ) : (
            <div className="relative">
              <span className="text-sm leading-[24px] underline cursor-pointer">
                Add avatar
              </span>
              <ImageUploader type={"logo"} position={1} {...otherProps} />
            </div>
          ))}
      </div>

      <div className="text-[28px] leading-[33.41px] text-center font-soehne-kraftig sm:text-[32px] sm:leading-[38.19px]">
        {user.display_name}
      </div>

      {user.welcome_message?.length > 0 ? (
        <p className="text-[16px] leading-[24px] text-center m-0">
          {user.welcome_message}
        </p>
      ) : (
        <div className="flex flex-col gap-y-2 w-full">
          <div className="h-[16px] bg-[#F5F5F5] rounded"></div>
          <div className="h-[16px] bg-[#F5F5F5] rounded"></div>
          <div className="h-[16px] bg-[#F5F5F5] rounded"></div>
        </div>
      )}

      {!hideControls && (
        <span
          className="text-sm leading-[24px] underline cursor-pointer"
          onClick={() => setSubscriberModalOpen(true)}
        >
          {user.welcome_message?.length > 0
            ? "Edit subscriber welcome"
            : "Add subscriber welcome"}
        </span>
      )}

      <div className="flex gap-x-3 w-full">
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="Enter email address"
          className="!text-[16px] !leading-[19.09px] !h-auto !py-[14px]"
          maxLength={MAX_STRING_LENGTH}
        />

        <Button type="submit" label="Subscribe" loginBtn />
      </div>

      <div className="px-[48px] py-[12px]">
        <Link
          to={`/archive/${user.url_slug}`}
          className="text-[16px] leading-[24px] font-medium"
          onClick={() => window.scrollTo(0, 0)}
        >
          View archives
        </Link>
      </div>
    </div>
  );
};

export default CardHeader;
