import React, { useState, useEffect } from "react";
import { useOutlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthProvider } from "@/lib/useAuthContext";
import { authenticate } from "@/apis/sessions";
import { setAuthHeaders } from "@/apis/axios";
import { AUTH_TOKEN } from "@/lib/constants";
import { showToastrError } from "@/components/commons";

const AuthLayout = () => {
  const outlet = useOutlet();
  const [loader, setLoader] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    authenticateRequest();
  }, []);

  const authenticateRequest = async () => {
    await setAuthHeaders();

    try {
      const authToken = localStorage.getItem(AUTH_TOKEN);

      if (authToken) {
        const { data } = await authenticate();
        setUser(data.user);
      } else {
        setUser(null);
      }
    } catch (error) {
      localStorage.removeItem(AUTH_TOKEN);
      showToastrError(error.response.data.errors);
      setUser(null);
    } finally {
      setLoader(false);
    }
  };

  if (loader) {
    return null;
  }

  return (
    <>
      <ToastContainer autoClose={3000} limit={2} theme="light" position="top-center" />
      <AuthProvider userData={user}>
        {outlet}
      </AuthProvider>
    </>
  );
};

export default AuthLayout;
