import React from "react";
import { Link } from "react-router-dom";

import ThreadsIcon from "@/images/icons/threads-icon.svg";
import InstagramIcon from "@/images/icons/instagram-icon.svg";
import EmailIcon from "@/images/icons/email-icon.svg";
import ImmortalProjectsLogo from "@/images/immortal-projects-logo.png";
import { LinkButton } from "@/components/commons/HomeFormUI";

const Banner = () => (
  <div className="flex flex-col p-8 bg-primary gap-y-12">
    <p className="text-2xl font-medium leading-normal">
      PencilBooth is a visual email newsletter app made for creative types.
    </p>
    <div className="flex flex-col w-full gap-y-4">
      <LinkButton
        to="/signup"
        label="CLAIM YOUR BOOTH"
        onClick={() => window.scrollTo(0, 0)}
      />
      <LinkButton
        to="/login"
        label="SIGN IN"
        style="secondary"
        onClick={() => window.scrollTo(0, 0)}
      />
    </div>
    <div className="flex flex-col w-full gap-y-6">
      <p className="text-base leading-normal">
        PencilBooth is the easiest way to keep people up-to-date with your creative practice. We provide you with a beautiful, image-led template that's easy to use and simple to send, along with a suite of novel discovery tools to be noticed by potential clients and fellow creatives.
      </p>
      <p className="text-base leading-normal">
        No algorithm, no app, just a visual snapshot of what you’ve been up to
        and what you’re loving, delighting your subscribers each time it lands
        in their inbox.
      </p>
      <p className="text-[12px] italic leading-normal">
        You’re currently viewing our new discover homepage featuring some of the
        latest newsletters from our growing community. Don’t be shy now — Go on
        and find your people, or{" "}
        <Link
          className="underline"
          to="/signup"
          onClick={() => window.scrollTo(0, 0)}
        >
          set up a booth of your own!
        </Link>
      </p>
    </div>
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center gap-x-3">
        <a
          href="https://www.threads.net/@pencilbooth"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center justify-center w-[32px] h-[32px] rounded-full bg-white bg-opacity-60">
            <img src={ThreadsIcon} />
          </div>
        </a>
        <a
          href="https://www.instagram.com/pencilbooth"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center justify-center w-[32px] h-[32px] rounded-full bg-white bg-opacity-60">
            <img src={InstagramIcon} />
          </div>
        </a>
        <a
          href="mailto:team@pencilbooth.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center justify-center w-[32px] h-[32px] rounded-full bg-white bg-opacity-60">
            <img src={EmailIcon} />
          </div>
        </a>
      </div>
      <div className="flex items-center gap-x-3">
        <a
          href="https://immortalityprojects.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="w-8 h-auto" src={ImmortalProjectsLogo} />
        </a>
        <p className="text-xs leading-4 text-black">
          PencilBooth is an <br />
          <a
            href="https://immortalityprojects.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Immortality Project
          </a>
        </p>
      </div>
      <div className="flex items-center gap-x-2">
        <Link
          className="text-xs font-normal leading-4 text-black hover:underline"
          target="_blank"
          to="/terms"
          onClick={() => window.scrollTo(0, 0)}
        >
          Terms & Conditions
        </Link>
        <span>{" • "}</span>
        <Link
          className="text-xs font-normal leading-4 text-black hover:underline"
          target="_blank"
          to="/privacy"
          onClick={() => window.scrollTo(0, 0)}
        >
          Privacy
        </Link>
      </div>
    </div>
  </div>
);

export default Banner;
