import React from "react";
import classnames from "classnames";

import PencilBoothLogo from "@/images/pencilbooth-logo.svg";

import FormCardFront from "./FormCardFront";
import SubscribePlans from "./SubscribePlans";
import LinkCard from "./LinkCard";

const Footer = () => {
  return (
    <div className="flex justify-center items-center px-4 py-3 bg-primary w-[140px] rounded-bl-lg rounded-br-lg">
      <img alt="PencilBooth Logo" className="h-4" src={PencilBoothLogo} />
    </div>
  );
};

const DesktopForm = ({
  user,
  btnLoader,
  setShowPlans,
  showPlans,
  email,
  setEmail,
  handleSubmit,
  subscriptionId,
  subscribed,
}) => {
  const coverPictureStyles = {
    backgroundImage: `url(${user.cover_image_url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
  };

  return (
    <div
      className="pb-settings--wrapper flex-col items-center h-screen w-screen overflow-y-auto font-sf-pro-medium relative hidden md:flex"
      style={coverPictureStyles}
    >
      <div className="flex flex-col items-center justify-center min-h-screen shrink-0 py-12">
        <div
          className={classnames(
            "rounded-2xl bg-white transition-all duration-300 ease-in-out w-[480px]",
            {
              "!max-w-[1024px] w-[90vw]": showPlans,
            },
          )}
        >
          {showPlans ? (
            <SubscribePlans
              user={user}
              subscriptionId={subscriptionId}
              setShowPlans={setShowPlans}
            />
          ) : (
            <div className="min-h-[calc(100vh-240px)] md:min-h-fit">
              <FormCardFront
                user={user}
                email={email}
                setEmail={setEmail}
                handleSubmit={handleSubmit}
                btnLoader={btnLoader}
                subscribed={subscribed}
              />

              {(user.link_trees.length > 0 || user.social_links_present) && (
                <LinkCard user={user} subscriberForm />
              )}
            </div>
          )}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default DesktopForm;
