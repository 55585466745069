import React from "react";
import ErrorImage from "@/images/error-image.png";

const GenericError = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img src={ErrorImage} className="mb-3" />
      <h3>Something went wrong!</h3>
    </div>
  );
};

export default GenericError;
