import React from "react";

import Button from "@/images/button.png";

const DefaultUrlCard = ({ linkRoll, withLink }) => {
  const renderContent = () => {
    return (
      <div className="flex flex-col w-full max-w-[486px] p-3 md:p-5 bg-[#F1F1F1] dark:bg-[#333333] dark:shadow-none shadow-lg rounded-lg">
        <img
          src={linkRoll.image_src}
          className="h-auto w-full rounded-xl"
          alt={"Link Image"}
          onError={(e) => (e.target.style = "display: none; margin: 0;")}
        />

        <div className="flex justify-between items-start w-full space-x-4 mt-3">
          <p className="pb-take--description-text dark:text-white">
            {linkRoll.link}
          </p>

          <a
            href={linkRoll.link}
            target="_blank"
            className="shrink-0 w-[12px] h-[11.25px] md:w-[16px] md:h-[15px] dark:text-white"
          >
            <img src={Button} className="h-full w-full" alt="link button" />
          </a>
        </div>
      </div>
    );
  };

  return withLink ? (
    <a href={linkRoll.formatted_link} target="_blank">
      {renderContent()}
    </a>
  ) : (
    renderContent()
  );
};

export default DefaultUrlCard;
