import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Toastr, Modal } from "@bigbinary/neetoui";

import { useAuthContext } from "@/lib/useAuthContext";
import { getUser, updateUser } from "@/apis/users";
import { showToastrError } from "@/components/commons";
import { Input, Button } from "@/components/commons/SettingsFormUI";

import {
  LINK_TREE_INITIAL_VALUE,
  LINK_TREE_VALIDATION_SCHEMA,
} from "./constants";

const LinkTreeModal = ({ linkTreeModalOpen, setLinkTreeModalOpen }) => {
  const { user, setUser } = useAuthContext();
  const [btnLoader, setBtnLoader] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user?.id
      ? { links: user.link_trees_attributes }
      : LINK_TREE_INITIAL_VALUE,
    validationSchema: LINK_TREE_VALIDATION_SCHEMA,
    onSubmit: () => updateUserResponse(),
  });

  useEffect(() => {
    if (
      user.link_trees_attributes.length === 0 &&
      formik.values.links.length === 0
    ) {
      handleAdd();
    }
  }, [user]);

  const handleChange = (e, index, attr) => {
    let array = formik.values.links;
    array[index] = { ...array[index], [attr]: e.target.value };
    formik.setFieldValue("links", array);
  };

  const handleAdd = () => {
    formik.setFieldValue("links", [
      ...formik.values.links,
      { name: "", link: "" },
    ]);
  };

  const handleDelete = (index) => {
    let array = formik.values.links;
    array[index] = { ...array[index], _destroy: true };
    formik.setFieldValue("links", array);
  };

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setUser(data.user);
      formik.setFieldValue(
        "links",
        data.user.link_trees_attributes.length == 0
          ? [{ name: "", url: "" }]
          : data.user.link_trees_attributes,
      );
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: {
          id: user.id,
          link_trees_attributes: formik.values.links,
        },
      });
      Toastr.success(data.message);
      await getUserResponse();
      setLinkTreeModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Modal
      isOpen={linkTreeModalOpen}
      onClose={() => {
        setLinkTreeModalOpen(false);
        formik.resetForm();
      }}
      className="pb-large-modal"
    >
      <Modal.Header>
        <h4>Bio Links</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-6 pb-6 border-t border-gray-300 divide-y divide-gray-300">
          {formik.values.links.map((link, index) => {
            if (link._destroy === true) {
              return null;
            }

            return (
              <div key={index} className="flex flex-col w-full space-y-4 pt-6">
                <div className="grid grid-cols-1 lg:grid-cols-5">
                  <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
                    Link Name
                  </label>
                  <div className="col-span-3 flex flex-col w-full gap-5">
                    <Input
                      key="name"
                      id="name"
                      name="name"
                      placeholder="Enter your link name"
                      defaultValue={formik.values.links[index].name}
                      onBlur={(e) => handleChange(e, index, "name")}
                      error={
                        Boolean(
                          formik.touched.links &&
                            formik.touched.links[index]?.name &&
                            formik.errors.links &&
                            formik.errors.links[index]?.name,
                        ) && formik.errors.links[index]?.name
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-5">
                  <label className="col-span-1 mb-2 text-sm uppercase md:mb-0">
                    URL
                  </label>
                  <div className="col-span-3 flex flex-col w-full gap-5">
                    <Input
                      id="url"
                      name="url"
                      placeholder="Enter your link URL"
                      value={formik.values.links[index].url}
                      onChange={(e) => handleChange(e, index, "url")}
                      error={
                        Boolean(
                          formik.touched.links &&
                            formik.touched.links[index]?.url &&
                            formik.errors.links &&
                            formik.errors.links[index]?.url,
                        ) && formik.errors.links[index]?.url
                      }
                    />
                  </div>
                  <div className="col-span-1 flex justify-end w-full gap-5 pt-4 md:p-0">
                    <Button
                      onClick={() => handleDelete(index)}
                      label="Delete"
                      style="secondary"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-end lg:justify-between gap-x-2 pt-6 border-t border-gray-300">
          <Button
            onClick={() => handleAdd()}
            label="Add link"
            style="secondary"
          />

          <Button
            type="submit"
            onClick={formik.handleSubmit}
            label="Save"
            loading={btnLoader}
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="bg-trnasparent"></Modal.Footer>
    </Modal>
  );
};

export default LinkTreeModal;
