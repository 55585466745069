import React from "react";

const Banner = () => (
  <div className="pb-home--banner-video">
    <video autoPlay loop muted playsInline>
      <source
        src="https://res.cloudinary.com/jackywinter/video/upload/v1709554820/0182_JackyWinter_PencilBooth_LandingPage_16x9_DarkMode_UpdatesMar2023_v02_ipz64n.mp4"
        type="video/mp4"
      />
    </video>
  </div>
);

export default Banner;
