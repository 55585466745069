import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import classnames from "classnames";
import { Loading } from "@bigbinary/neeto-icons";

const InputWithButton = ({
  className = "",
  wrapperClassName = "",
  type = "text",
  loading = false,
  label = "Button",
  style = "primary",
  disabled = false,
  error = "",
  onSubmit = () => {},
  onlyLink = false,
  ...otherProps
}) => {
  const btnClassNames = classnames(
    "pb-app--inputbtn-btn shrink-0",
    {
      "pb-app--inputbtn-btn--primary": style === "primary",
      "pb-app--inputbtn-btn--danger": style === "danger",
    },
    { "pointer-events-none": loading },
    { "disabled-btn": disabled },
  );

  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className={classnames(["pb-app--inputbtn-wrapper", wrapperClassName])}>
        <input
          type={type}
          className={classnames(["pb-app--inputbtn-input", className])}
          {...otherProps}
        />
        <button
          className={onlyLink ? "" : btnClassNames}
          onClick={() => onSubmit()}
        >
          <AnimatePresence>
            {loading && (
              <motion.div
                animate={{ width: "auto", scale: 1 }}
                exit={{ width: 0, scale: 0 }}
                initial={{ width: 0, scale: 0 }}
                transition={{ bounce: false }}
              >
                <Loading className="mr-2 animate-spin-slow" size="18" />
              </motion.div>
            )}
          </AnimatePresence>
          {label}
        </button>
      </div>

      {!!error && (
        <p className="text-sm leading-5 text-red-500 opacity-60">{error}</p>
      )}
    </div>
  );
};

export default InputWithButton;
