import React from "react";
import { Modal, Typography } from "@bigbinary/neetoui";

import { Textarea } from "@/components/commons/SettingsFormUI";
import { Button } from "@/components/commons/AppFormUI";

const FeedbackModal = ({
  feedbackModalOpen,
  setFeedbcakModalOpen,
  formik,
  btnLoader,
}) => {
  return (
    <Modal
      isOpen={feedbackModalOpen}
      onClose={() => setFeedbcakModalOpen(false)}
      className="pb-modal"
    >
      <Modal.Header>
        <h4>Drop us a line</h4>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <Typography style="body2">
          Have a question? Want to make a feature request? Hit us up!
        </Typography>
        <Textarea
          id="message"
          name="message"
          defaultValue={formik.values.message}
          onBlur={formik.handleChange}
          maxLength={350}
          textCount={formik.values.message?.length}
          error={
            Boolean(formik.touched.message && formik.errors.message) &&
            formik.errors.message
          }
        />
      </Modal.Body>
      <Modal.Footer className="flex gap-x-2">
        <Button
          label="Send"
          loading={btnLoader}
          type="submit"
          onClick={() => formik.handleSubmit()}
        />

        <Button
          label="Cancel"
          onClick={() => setFeedbcakModalOpen(false)}
          style="modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
