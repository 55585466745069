import React from "react";
import { Navigate, useOutlet } from "react-router-dom";

import SearchBanner from "@/components/commons/SearchBanner";
import { useAuthContext } from "@/lib/useAuthContext";

const HomeLayout = () => {
  const outlet = useOutlet();
  const { user, setSearch } = useAuthContext();

  if (user) {
    return <Navigate to="/discovery" replace={true} />;
  }

  return (
    <div className="min-h-screen font-soehne-mono">
      {outlet}
      <SearchBanner onClose={() => setSearch(false)} />
    </div>
  );
};

export default HomeLayout;
