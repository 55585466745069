import React, { useState } from "react";
import { Modal, Typography } from "@bigbinary/neetoui";
import { motion } from "framer-motion";
import { Close } from "@bigbinary/neeto-icons";
import { updateUser } from "@/apis/users";
import { showToastrError } from "@/components/commons";
import { useAuthContext } from "@/lib/useAuthContext";

const HeaderAlert = () => {
  const { user, setUser } = useAuthContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [notification, setNotification] = useState(true);

  const updateUserResponse = async () => {
    try {
      const { data } = await updateUser(user.id, {
        user: {
          new_user_alert: false,
          old_user_alert: false,
        },
      });

      setUser({ ...user, new_user_alert: false, old_user_alert: false });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const videoUrl = user?.old_user_alert
    ? "https://www.loom.com/embed/467ec661d37044139cd8f3eb40b4f0f7?sid=082a2213-01fa-4a2e-bc13-08c16c80bf09"
    : "https://www.loom.com/embed/724701d3b9694e99b0ef3d29cba6fa68?sid=415549e6-f537-42ed-995f-6e5993e9460d";

  return (
    <motion.div
      animate={{
        opacity: 1,
        height: notification ? "auto" : 0,
        width: "100%",
      }}
      exit={{ opacity: 0, height: 0, width: "100%" }}
      initial={{
        opacity: 0,
        height: 0,
        overflow: "hidden",
        width: "100%",
      }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-col sm:flex-row justify-center items-center text-xs font-medium w-full bg-[#f5a19d] py-2 relative px-8 space-y-1 sm:space-y-0">
        <div className="flex items-center flex-col md:flex-row md:space-x-1">
          <p>Welcome to PencilBooth 2.0!</p>

          <p>Learn whats new:
            <span
              className="inline-block px-2 py-0.5 shadow-lg bg-[#000] text-xs text-white rounded-full cursor-pointer ml-1"
              onClick={() => setModalOpen(true)}
            >
              click here
            </span>
          </p>
        </div>


        <span
          className="absolute right-3 sm:right-5 cursor-pointer"
          onClick={() => {
            setNotification(false);
            user && updateUserResponse();
          }}
        >
          <Close size={16} />
        </span>
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        className="pb-modal pb-modal--medium"
      >
        <Modal.Header>
          <h4>Welcome to PencilBooth!</h4>
        </Modal.Header>
        <Modal.Body className="h-[60vh]">
          <iframe
            src={videoUrl}
            style={{
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </motion.div>
  );
};

export default HeaderAlert;
