import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import { Close } from "@bigbinary/neeto-icons";

import { useAuthContext } from "@/lib/useAuthContext";
import { getUserPreview, getUserDisciplines } from "@/apis/searches";
import { getLocations } from "@/apis/settings/locations";
import { showToastrError } from "@/components/commons";
import SearchIcon from "@/images/icons/search-icon.svg";
import PencilBoothLogo from "@/images/pencilbooth-logo.svg";
import BoothCard from "./BoothCard";
import DiscoverTag from "./DiscoverTag";

const SearchBanner = ({ onClose }) => {
  const navigate = useNavigate();
  const { setSearch, search } = useAuthContext();
  const [searchTerm, setSearchTerm] = useState();
  const [searchLocation, setSearchLocation] = useState();
  const [searchDiscipline, setSearchDiscipline] = useState();
  const [previewUsers, setPreviewUsers] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [disciplineList, setDisciplineList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [searchOption, setSearchOption] = useState("location");

  useEffect(() => {
    if (search) {
      loadDataWhenSearched();

      window.addEventListener("keydown", onPressEsc);
      return () => window.removeEventListener("keydown", onPressEsc);
    }
  }, [search]);

  useEffect(() => {
    if (searchLocation || searchDiscipline) {
      setSearch(false);
      document.body.classList.remove("!overflow-hidden");
      navigate(`/search?${prepareSearchQuery()}`, { replace: true });
    }
  }, [searchLocation, searchDiscipline]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearch(false);
      document.body.classList.remove("!overflow-hidden");
      navigate(`/search?${prepareSearchQuery()}`, { replace: true });
    }
  };

  const onPressEsc = (event) => {
    if (event.key === "Escape") {
      setSearch(false);
      document.body.classList.remove("!overflow-hidden");
    }
  };

  const prepareSearchQuery = () => {
    let searchQuery = "";
    if (searchTerm) searchQuery += `query=${searchTerm}`;
    if (searchLocation) searchQuery += `&location=${searchLocation}`;
    if (searchDiscipline) searchQuery += `&discipline=${searchDiscipline}`;

    return searchQuery;
  };

  const loadDataWhenSearched = async () => {
    await getUserPreviewResponse();
    await getLocationListResponse();
    await getDisciplineListResponse();
    setListLoader(false);
  };

  const getLocationListResponse = async () => {
    try {
      const { data } = await getLocations();
      setLocationList(data.locations);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getDisciplineListResponse = async () => {
    try {
      const { data } = await getUserDisciplines();
      setDisciplineList(data.disciplines);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getUserPreviewResponse = async () => {
    try {
      const { data } = await getUserPreview();
      setPreviewUsers(data.users);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollableWidth, setScrollableWidth] = useState(100);
  const [isMobileScrollable, setIsMobileScrollable] = useState(false);
  const [scrollableMobileWidth, setScrollableMobileWidth] = useState(100);

  const checkOverflow = () => {
    // Get the element you want to check for overflow
    const contentElement = document.getElementById("scrollable-content");

    // Set isScrollable based on scroll and client heights
    setIsScrollable(contentElement.scrollHeight > contentElement.clientHeight);
  };

  useEffect(() => {
    if (!listLoader) {
      // Check for scrollable content on initial render and on window resize
      const handleResize = () => {
        const contentElement = document.getElementById("scrollable-content");
        setScrollableWidth(contentElement.scrollWidth);

        if (contentElement.scrollHeight > contentElement.clientHeight) {
          setIsScrollable(true);
        } else {
          setIsScrollable(false);
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, [listLoader]);

  const checkMobileOverflow = () => {
    // Get the element you want to check for overflow
    const contentElement = document.getElementById("mobile-scrollable-content");

    // Set isScrollable based on scroll and client heights
    setIsMobileScrollable(
      contentElement.scrollHeight > contentElement.clientHeight,
    );
  };

  useEffect(() => {
    if (!listLoader) {
      // Check for scrollable content on initial render and on window resize
      const handleResize = () => {
        const contentElement = document.getElementById(
          "mobile-scrollable-content",
        );
        setScrollableMobileWidth(contentElement.scrollWidth);

        if (contentElement.scrollHeight > contentElement.clientHeight) {
          setIsMobileScrollable(true);
        } else {
          setIsMobileScrollable(false);
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }
  }, [listLoader]);

  return (
    <div
      className={`pb-home--search-wrapper bg-secondary bg-opacity-80 backdrop-blur-sm fixed left-0 z-50 h-screen lg:h-[740px] max-h-[100vh] w-screen transition-all duration-500 ease-in-out ${
        search ? "top-0" : "-top-[100vh] lg:-top-[740px] overflow-hidden"
      }`}
    >
      <div className="relative flex flex-col items-center w-full h-full">
        <div className="relative flex items-center justify-center w-full px-6 py-5 lg:hidden">
          <div
            onClick={() => {
              document.body.classList.remove("!overflow-hidden");
              onClose();
            }}
            className="absolute left-6 top-6 flex items-center justify-center cursor-pointer"
          >
            <Close size={24} />
          </div>

          <img
            alt="PencilBooth Logo"
            className="h-6 w-auto lg:h-8"
            src={PencilBoothLogo}
          />
        </div>

        <div className="w-full max-w-6xl px-6 pt-6 pb-8 lg:pt-12 lg:pb-6">
          <div className="flex items-center w-full gap-x-3 rounded-full border-2 border-black bg-white px-4 py-3">
            <img src={SearchIcon} alt="search icon" />
            <input
              name="search"
              placeholder="Search for Booths, locations, disciplines"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div
            onClick={() => {
              document.body.classList.remove("!overflow-hidden");
              onClose();
            }}
            className="absolute left-3 top-3 hidden cursor-pointer items-center justify-center lg:flex"
          >
            <Close size={24} />
          </div>

          {listLoader ? (
            <div className="flex justify-center items-center h-[574px]">
              <Spinner />
            </div>
          ) : (
            <div className="flex flex-col justify-between w-full gap-x-12 xl:gap-x-24 gap-y-12 pt-6 lg:flex-row">
              <div
                className="flex w-full flex-col gap-y-6 lg:h-[574px] overflow-auto"
                style={{ maxHeight: "calc(100vh - 144px)" }}
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-medium lg:text-2xl font-soehne-kraftig">
                    Featured Booths
                  </h3>

                  <button
                    className="text-base font-medium hover:underline block lg:hidden"
                    onClick={() => {
                      setSearch(false);
                      document.body.classList.remove("!overflow-hidden");
                      navigate(`/featured_booth`, { replace: true });
                    }}
                  >
                    View all
                  </button>

                  <button
                    className="text-base font-medium hover:underline hidden lg:block"
                    onClick={() => {
                      setSearch(false);
                      document.body.classList.remove("!overflow-hidden");
                      navigate(`/featured_booth`, { replace: true });
                    }}
                  >
                    View all Booths
                  </button>
                </div>

                <div className="flex w-full gap-4 overflow-x-auto lg:grid lg:grid-cols-2">
                  {previewUsers.map((user, index) => (
                    <Link
                      to={`/archive/${user.url_slug}`}
                      onClick={() => {
                        setSearch(false);
                        document.body.classList.remove("!overflow-hidden");
                        window.scrollTo(0, 0);
                      }}
                    >
                      <BoothCard boothUser={user} key={index} />
                    </Link>
                  ))}
                </div>
              </div>

              <div
                className={`hidden max-w-xs w-full flex-col gap-y-12 lg:flex lg:h-[574px] relative overflow-y-auto overflow-x-hidden pb-6`}
                style={{ maxHeight: "calc(100vh - 144px)" }}
                id="scrollable-content"
                onScroll={checkOverflow}
              >
                <h3 className="text-xl font-medium lg:text-2xl font-soehne-kraftig">
                  Discover Booths
                </h3>

                <div className="flex flex-col gap-y-6">
                  <h4 className="item text-base font-medium font-soehne-kraftig">
                    By location
                  </h4>
                  <div className="flex flex-wrap gap-x-2 gap-y-3">
                    {locationList.map((location, index) => (
                      <DiscoverTag
                        key={index}
                        name={location}
                        setSearch={setSearchLocation}
                      />
                    ))}
                  </div>
                </div>

                <div className="flex flex-col gap-y-6">
                  <h4 className="item text-base font-medium font-soehne-kraftig">
                    By discipline
                  </h4>
                  <div className="flex flex-wrap gap-x-2 gap-y-3">
                    {disciplineList.map((discipline, index) => (
                      <DiscoverTag
                        key={index}
                        name={discipline.name}
                        setSearch={setSearchDiscipline}
                      />
                    ))}
                  </div>
                </div>

                {isScrollable && (
                  <div
                    className="inner-shadow"
                    style={{ width: `${scrollableWidth}px` }}
                  >
                    &nbsp;&nbsp;
                  </div>
                )}
              </div>

              <div
                className="flex flex-col w-full gap-y-6 lg:hidden"
                style={{ height: "calc(100vh - 532px)" }}
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-medium font-soehne-kraftig md:text-2xl">
                    Discover
                  </h3>

                  <select
                    className="bg-transparent text-base font-medium"
                    onChange={(e) => setSearchOption(e.target.value)}
                  >
                    <option value="location">By location</option>
                    <option value="discipline">By discipline</option>
                  </select>
                </div>

                {searchOption === "location" && (
                  <div
                    className={`flex flex-wrap gap-x-2 gap-y-3 overflow-y-auto pb-3`}
                    style={{ maxHeight: "calc(100vh - 582px)" }}
                    id="mobile-scrollable-content"
                    onScroll={checkMobileOverflow}
                  >
                    {locationList.map((location, index) => {
                      return (
                        <DiscoverTag
                          key={index}
                          name={location}
                          setSearch={setSearchLocation}
                        />
                      );
                    })}

                    {isMobileScrollable && (
                      <div
                        className="mobile-inner-shadow"
                        style={{ width: `${scrollableMobileWidth}px` }}
                      >
                        &nbsp;&nbsp;
                      </div>
                    )}
                  </div>
                )}

                {searchOption === "discipline" && (
                  <div
                    className={`flex flex-wrap gap-x-2 gap-y-3 overflow-y-auto pb-3`}
                    style={{ maxHeight: "calc(100vh - 582px)" }}
                    id="mobile-scrollable-content"
                    onScroll={checkMobileOverflow}
                  >
                    {disciplineList.map((discipline, index) => {
                      return (
                        <DiscoverTag
                          key={index}
                          name={discipline.name}
                          setSearch={setSearchDiscipline}
                        />
                      );
                    })}

                    {isMobileScrollable && (
                      <div
                        className="mobile-inner-shadow"
                        style={{ width: `${scrollableMobileWidth}px` }}
                      >
                        &nbsp;&nbsp;
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBanner;
