import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import classnames from "classnames";

import { getUsers } from "@/apis/searches";
import { showToastrError } from "@/components/commons";
import Header from "@/components/commons/Header";
import AnimatedPage from "@/components/commons/AnimatedPage";
import AppHeader from "@/components/commons/AppHeader";
import BoothCard from "@/components/commons/BoothCard";
import { useAuthContext } from "@/lib/useAuthContext";

const Search = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const searchTerm = location?.search?.split("?query=")[1] || "";
  const searchLocationTerm = location?.search
    ?.split("&location")[1]
    ?.replace("=", "");
  const searchDisciplineTerm = location?.search
    ?.split("&discipline")[1]
    ?.replace("=", "");
  const [boothUsers, setBoothUsers] = useState([]);
  const [listLoader, setListLoader] = useState(true);

  useEffect(() => {
    getBoothUsersResponse();
  }, [searchTerm, searchLocationTerm, searchDisciplineTerm]);

  const getBoothUsersResponse = async () => {
    try {
      const { data } = await getUsers(
        searchTerm,
        searchLocationTerm,
        searchDisciplineTerm,
      );
      setBoothUsers(data.users);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const handleSubscription = (id) => {
    setBoothUsers(
      boothUsers.map((el) => {
        if (el.id == id) {
          el.subscribed = true;
        }

        return el;
      }),
    );
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {user && <AppHeader />}
      <div
        className={classnames("flex flex-col items-center w-full", {
          "pb-app--layout-wrapper pt-[64px] lg:pt-[98px]": user,
          "pb-home--layout-wrapper": !user,
        })}
      >
        {!user && <Header />}

        <AnimatedPage
          className={classnames("flex flex-col w-full", {
            "pb-app--layout": user,
            "pb-home--layout pb-home--layout--fullWidth": !user,
          })}
        >
          <h2 className="font-soehne-kraftig mb-8 text-center text-2xl md:font-semibold md:text-2xl md:mb-12 lg:text-5xl">
            Search Result
          </h2>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {boothUsers.map((boothUser) => {
              return (
                <BoothCard
                  boothUser={boothUser}
                  key={boothUser.id}
                  handleSubscription={handleSubscription}
                />
              );
            })}
          </div>
        </AnimatedPage>
      </div>
    </>
  );
};

export default Search;
