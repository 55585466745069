import * as R from "ramda";
import { Toastr } from "@bigbinary/neetoui";

export const showToastrError = (error) => {
  let message;
  if (typeof error === "string") {
    message = error;
  } else if (typeof error === "object") {
    message = R.join("\n", error);
  } else if (error?.data && error?.data?.errors) {
    message = R.join("\n", error?.data?.errors);
  } else {
    message = "Something went wrong";
  }
  Toastr.error(message);
};

export const showToastrSuccess = (message) => {
  Toastr.success(message);
};
