import React, { useState } from "react";
import { Edit, Delete, MenuVertical } from "@bigbinary/neeto-icons";
import { Dropdown, Pagination, Modal } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";

import {
  updateStripeDetails,
  cancelStripeSubscription,
  destroySubscription,
} from "@/apis/settings/paid_booths";
import { showToastrError } from "@/components/commons";
import { Button } from "@/components/commons/AppFormUI";

import { DEFAULT_PAGE_SIZE, ORDINALIZED_DATE_FORMAT } from "@/lib/constants";

const Table = ({
  subscriptionsList,
  totalRecords,
  setPageIndex,
  pageIndex,
  getPaidBoothsResponse,
}) => {
  const { Menu, MenuItem } = Dropdown;
  const [cancelStripeSubscriptionAlert, setCancelStripeSubscriptionAlert] =
    useState(false);
  const [unsubscribeSubscriptionAlert, setUnsubscribeSubscriptionAlert] =
    useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);

  const updateStripeDetailsResponse = async (subscriptionId) => {
    try {
      const { data } = await updateStripeDetails(subscriptionId);
      window.location.href = data.checkout_url;
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const cancelStripeSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      await cancelStripeSubscription(selectedSubscriptionId);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
      setSelectedSubscriptionId();
      setCancelStripeSubscriptionAlert(false);
    }
  };

  const destroySubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      await destroySubscription(selectedSubscriptionId);
      await getPaidBoothsResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
      setSelectedSubscriptionId();
      setUnsubscribeSubscriptionAlert(false);
    }
  };

  const renderSubscription = (subscription) => {
    if (subscription.plan_type === "Free") {
      return <span>Free</span>;
    } else {
      return (
        <div className="flex flex-col gap-2">
          <span>{`${subscription.plan_amount} / ${subscription.plan_type}`}</span>
          <span className="text-[10px] text-gray-500 uppercase">
            {`${subscription.plan_ending ? "Ends On" : "Renews On"} ${dayjs(
              subscription.plan_end_date,
            ).format(ORDINALIZED_DATE_FORMAT)}`}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <div className="pb-app--table-wrapper w-full flex flex-col rounded-lg bg-white px-8 py-6">
        <table className="pb-app--table">
          <thead>
            <tr>
              <th>Booth Name</th>
              <th>Subscription</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {subscriptionsList.map((subscription) => {
              return (
                <tr key={subscription.id}>
                  <td>
                    <Link to={`/archive/${subscription.user_url_slug}`}>
                      {subscription.user_name}
                    </Link>
                  </td>
                  <td>{renderSubscription(subscription)}</td>

                  <td>
                    <div className="flex justify-end">
                      <Dropdown
                        customTarget={
                          <div className="pb-app--table--menu-icon cursor-pointer bg-white p-1">
                            <MenuVertical size={16} />
                          </div>
                        }
                        onClose={function noRefCheck() {}}
                      >
                        <Menu>
                          {subscription.plan_type !== "Free" &&
                            !subscription.plan_ending && (
                              <>
                                <MenuItem.Button
                                  className="flex items-center gap-2"
                                  onClick={() =>
                                    updateStripeDetailsResponse(subscription.id)
                                  }
                                >
                                  <Edit size={16} />
                                  <span>Update payment details</span>
                                </MenuItem.Button>

                                <MenuItem.Button
                                  className="flex items-center gap-2"
                                  onClick={() => {
                                    setSelectedSubscriptionId(subscription.id);
                                    setCancelStripeSubscriptionAlert(true);
                                  }}
                                >
                                  <Delete size={16} />
                                  <span>Cancel subscription</span>
                                </MenuItem.Button>
                              </>
                            )}

                          {(subscription.plan_type === "Free" ||
                            (subscription.plan_type !== "Free" &&
                              subscription.plan_ending)) && (
                            <MenuItem.Button
                              className="flex items-center gap-2"
                              onClick={() => {
                                setSelectedSubscriptionId(subscription.id);
                                setUnsubscribeSubscriptionAlert(true);
                              }}
                            >
                              <Delete size={16} />
                              <span>Unsubscribe</span>
                            </MenuItem.Button>
                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="pb-app--pagination-container">
          <Pagination
            count={totalRecords}
            navigate={(page) => {
              setPageIndex(page);
            }}
            pageNo={pageIndex}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </div>

        <Modal
          isOpen={cancelStripeSubscriptionAlert}
          onClose={() => setCancelStripeSubscriptionAlert(false)}
          className="pb-modal"
        >
          <Modal.Header>
            <div className="flex flex-col w-full gap-2 max-w-[683px]">
              <h4 className="modal-header">Cancel Stripe Subscription</h4>
            </div>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to cancel the stripe subscription?
          </Modal.Body>

          <Modal.Footer className="bg-trnasparent flex gap-x-2">
            {" "}
            <Button
              label="Yes"
              loading={btnLoader}
              type="submit"
              onClick={() => cancelStripeSubscriptionResponse()}
            />
            <Button
              label="Cancel"
              onClick={() => setCancelStripeSubscriptionAlert(false)}
              style="modal"
            />
          </Modal.Footer>
        </Modal>

        <Modal
          isOpen={unsubscribeSubscriptionAlert}
          onClose={() => setUnsubscribeSubscriptionAlert(false)}
          className="pb-modal"
        >
          <Modal.Header>
            <div className="flex flex-col w-full gap-2 max-w-[683px]">
              <h4 className="modal-header">Unsubscribe Booth</h4>
            </div>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to unsubscribe the booth?
          </Modal.Body>

          <Modal.Footer className="bg-trnasparent flex gap-x-2">
            {" "}
            <Button
              label="Yes"
              loading={btnLoader}
              type="submit"
              onClick={() => destroySubscriptionResponse()}
            />
            <Button
              label="Cancel"
              onClick={() => setUnsubscribeSubscriptionAlert(false)}
              style="modal"
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Table;
