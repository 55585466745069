import axios from "axios";

export const getPaidSubscription = () => {
  return axios.get(`/api/v1/settings/paid_subscriptions/1`);
};

export const createStripeAccount = () => {
  return axios.post(`/api/v1/settings/stripe_accounts`);
};

export const createConnectedSubscription = (payload) => {
  return axios.post(`/api/v1/connected_subscriptions`, payload);
};

export const cancelConnectedSubscription = (payload) => {
  return axios.put(`/api/v1/cancel_connected_subscriptions/1`, payload);
};
