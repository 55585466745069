import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import { motion } from "framer-motion";
import { Popover } from "antd";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { v4 as uuid } from "uuid";

import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

import {
  MenuHorizontal,
  TrashIcon,
  PlusIcon,
} from "@/components/commons/PBIcons";
import { Button } from "@/components/commons/AppFormUI";
import { getLinkRollsPreview, createLinkRoll } from "@/apis/link_rolls";
import { showToastrError } from "@/components/commons";
import { isValidUrl, withoutProtocol } from "@/lib/utils";

import LinkRollCard from "./LinkRollCard";
import { RICH_TEXT_EDITOR_INLINE_MODE } from "./../../constants";

const LinkRollForm = ({ formik, handleSubmitWithStatus }) => {
  const [initial, setInitial] = useState(true);
  const [linkRollLink, setLinkRollLink] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [addPopover, setAddPopover] = useState(false);
  const [linkError, setLinkError] = useState();

  useEffect(() => {
    if (!initial) {
      handleSubmitWithStatus();
    }
  }, [formik.values.link_rolls_enabled]);

  const handleLinkPreviewFetch = () => {
    let website = linkRollLink;

    if (isValidUrl(website)) {
      if (withoutProtocol(website)) {
        website = `http://${website}`;
      }

      const newUuid = uuid();
      const payload = {
        link: linkRollLink,
        title: "",
        caption: "",
        id: newUuid,
        loading: true,
      };
      const newLinkRollsArray = [
        ...formik.values.link_rolls_attributes,
        payload,
      ];

      formik.setFieldValue("link_rolls_attributes", newLinkRollsArray);
      getLinkRollsPreviewResponse(website, newUuid, newLinkRollsArray);
    }
  };

  const getLinkRollsPreviewResponse = async (
    website,
    newUuid,
    newLinkRollsArray,
  ) => {
    try {
      setBtnLoader(true);

      const {
        data: { link_rolls_preview },
      } = await getLinkRollsPreview(formik.values.id, website);

      const payload = {
        link: link_rolls_preview.link,
        title: link_rolls_preview.title,
        description: link_rolls_preview.description,
        image_src: link_rolls_preview.image_src,
        username: link_rolls_preview.username,
        instagram_post: link_rolls_preview.instagram_post,
        twitter_post: link_rolls_preview.twitter_post,
      };

      const { data } = await createLinkRoll(formik.values.id, {
        link_roll: payload,
      });

      const newArray = newLinkRollsArray.map((el) => {
        if (el.id == newUuid) {
          return data.link_roll;
        } else {
          return { ...el, in_bottom_position: false };
        }
      });

      formik.setFieldValue("link_rolls_attributes", newArray);
      setAddPopover(false);
      setLinkRollLink("");
    } catch (error) {
      setLinkRollLink("");
      const updatedLinkRolls = newLinkRollsArray.filter(
        (linkRoll) => linkRoll.id != newUuid,
      );

      formik.setFieldValue("link_rolls_attributes", updatedLinkRolls);
      showToastrError(error.response.data.message);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div
      className={classnames("flex flex-col w-full", {
        "py-6": formik.values.link_rolls_enabled,
      })}
    >
      <motion.div
        animate={{
          opacity: 1,
          height: formik.values.link_rolls_enabled ? "auto" : 0,
          overflow: formik.values.link_rolls_enabled ? "visible" : "hidden",
        }}
        exit={{ opacity: 0, height: 0, overflow: "hidden" }}
        initial={{ opacity: 0, height: 0, overflow: "hidden" }}
        transition={{ duration: 0.3 }}
      >
        <div className="relative z-10 flex flex-col space-y-8 w-full py-14 border-t border-b border-solid border-[#E5E5E5]">
          <div className="relative text-center">
            <input
              value={formik.values.link_roll_title}
              onChange={(e) =>
                formik.setFieldValue("link_roll_title", e.target.value)
              }
              className="pb-take-header-input text-center"
              placeholder="Give your B-Roll a header"
              onBlur={() => handleSubmitWithStatus()}
            />

            <div className="absolute right-0 top-0">
              <Menu>
                <MenuButton className="flex items-center rounded-full p-1 data-[hover]:bg-[#F2EFEC] data-[open]:bg-[#F2EFEC] border border-solid border-[#E5E0D9]">
                  <MenuHorizontal />
                </MenuButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <MenuItems
                    anchor="bottom end"
                    className="origin-top-left w-[200px] rounded-lg border border-solid border-[#E5E0D9] bg-white p-2 shadow-lg focus:outline-none z-50"
                  >
                    <MenuItem
                      onClick={() => {
                        setInitial(false);
                        formik.setFieldValue("link_rolls_enabled", false);
                      }}
                    >
                      <button className="flex items-center w-full p-2 text-[16px] font-soehne-mono">
                        <TrashIcon />
                        <span className="ml-2">Delete</span>
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </div>

          <RichTextEditorComponent
            id="link-roll-intro-editor"
            inlineMode={RICH_TEXT_EDITOR_INLINE_MODE}
            toolbarSettings={{
              items: ["Bold", "Italic", "Underline", "CreateLink"],
            }}
            onBlur={() => handleSubmitWithStatus()}
            change={(e) => formik.setFieldValue("link_roll_intro", e.value)}
            value={formik.values.link_roll_intro}
            placeholder="Add your B-Roll intro here (optional)"
          >
            <Inject services={[Link, QuickToolbar, HtmlEditor, Toolbar]} />
          </RichTextEditorComponent>

          {formik.values.link_rolls_attributes.length > 0 && (
            <div className="flex flex-col justify-center items-center w-full">
              {formik.values.link_rolls_attributes.map((linkRoll, index) => {
                return (
                  <LinkRollCard
                    key={index}
                    formik={formik}
                    linkRoll={linkRoll}
                  />
                );
              })}
            </div>
          )}

          <Popover
            content={
              <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                <div className="flex flex-col space-y-2 w-full">
                  <span className="text-[14px]">Link</span>
                  <input
                    value={linkRollLink}
                    onChange={(e) => {
                      setLinkError("");
                      setLinkRollLink(e.target.value);
                    }}
                    className="pb-take-popover-input"
                    placeholder="Enter a link"
                  />
                  {linkError?.length > 0 && (
                    <p className="text-sm text-[#CB4446]">{linkError}</p>
                  )}
                </div>

                <div className="flex justify-end space-x-4">
                  <Button
                    label="Cancel"
                    className="!w-fit"
                    onClick={() => setAddPopover(false)}
                    style="link"
                  />

                  <Button
                    label="Insert"
                    loading={btnLoader}
                    className="!w-fit"
                    type="submit"
                    onClick={() => {
                      if (!isValidUrl(linkRollLink)) {
                        setLinkError("Link is not valid.");
                      } else {
                        setLinkError("");
                        handleLinkPreviewFetch();
                      }
                    }}
                  />
                </div>
              </div>
            }
            trigger="click"
            open={addPopover}
            placement="bottom"
            overlayClassName="w-[350px]"
          >
            <button
              className="flex items-center w-fit space-x-3 cursor-pointer group"
              onClick={() => {
                setAddPopover(true);
              }}
            >
              <div className="text-[#969797] group-hover:bg-[#E5E5E5]">
                <PlusIcon />
              </div>
              <span className="text-[15px] text-[#7B7E82]">Add a link</span>
            </button>
          </Popover>
        </div>
      </motion.div>

      {!formik.values.link_rolls_enabled && (
        <div
          className="flex items-center w-fit space-x-3 cursor-pointer group"
          onClick={() => {
            setInitial(false);
            formik.setFieldValue(
              "link_rolls_enabled",
              !formik.values.link_rolls_enabled,
            );
          }}
        >
          <div className="text-[#969797] group-hover:bg-[#E5E5E5]">
            <PlusIcon />
          </div>
          <span className="text-[15px] text-[#7B7E82]">Add the B-Roll</span>
        </div>
      )}
    </div>
  );
};

export default LinkRollForm;
