import React from "react";

import { Down } from "@bigbinary/neeto-icons";
import classnames from "classnames";
import { motion } from "framer-motion";

import Collapse from "./Collapse";

const Item = ({
  id,
  title = "",
  isOpen = false,
  onClick = () => {},
  children,
  className = "",
  titleProps = {},
  iconProps = {},
}) => (
  <div
    className={classnames("pb-accordion__wrapper transition-all", {
      "bg-black bg-opacity-5": !isOpen,
      "bg-black bg-opacity-10": isOpen,
      [className]: className,
    })}
  >
    <div
      aria-controls={`pb-accordion-section-${id}`}
      aria-disabled={isOpen}
      aria-expanded={isOpen}
      id={`pb-accordion-item-${id}`}
      role="button"
      tabIndex={0}
      className={classnames(
        "pb-accordion__item flex items-center justify-between gap-x-2",
        {
          "pb-accordion__item--open": isOpen,
        },
      )}
      onClick={onClick}
      onKeyDown={(e) => {
        switch (e.key) {
          case " ":
          case "Enter":
            onClick();
            break;
          default:
        }
      }}
    >
      <div
        {...titleProps}
        className={classnames(
          "pb-accordion__item-handle flex flex-grow items-center break-words text-lightBlack",
        )}
      >
        {title}
      </div>
      <motion.div
        animate={isOpen ? "open" : "collapsed"}
        className="pb-accordion__item-toggle-icon flex-grow-0"
        transition={{ duration: 0.3 }}
        variants={{ open: { rotate: 180 }, collapsed: { rotate: 0 } }}
      >
        <Down className="text-lightBlack" size={24} {...iconProps} />
      </motion.div>
    </div>
    <Collapse
      aria-labelledby={`pb-accordion-item-${id}`}
      className="pb-accordion__drop"
      id={`pb-accordion-section-${id}`}
      open={isOpen}
      role="region"
    >
      {children}
    </Collapse>
  </div>
);

Item.displayName = "Accordion.Item";

export default Item;
