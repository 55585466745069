import React from "react";
import { RouterProvider } from "react-router-dom";

import { registerLicense } from "@syncfusion/ej2-base";

import { router } from "@/components/Main";

function App() {
  // Registering Syncfusion license key
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);
  return <RouterProvider router={router} />;
}

export default App;
