import React from "react";
import { useOutlet } from "react-router-dom";

import SearchBanner from "@/components/commons/SearchBanner";
import { useAuthContext } from "@/lib/useAuthContext";

const DefaultLayout = () => {
  const outlet = useOutlet();
  const { setSearch } = useAuthContext();

  return (
    <div className="min-h-screen font-soehne-mono bg-secondary">
      {outlet}
      <SearchBanner onClose={() => setSearch(false)} />
    </div>
  );
};

export default DefaultLayout;
