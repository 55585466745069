import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Eye,
  Hide,
  MenuVertical,
  UserCircle,
  MailSend,
  Copy,
  Info,
} from "@bigbinary/neeto-icons";
import { Toastr, Pagination, Tooltip, Spinner } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import {
  getStyle,
  DEFAULT_PAGE_SIZE,
  ORDINALIZED_DATE_FORMAT,
} from "@/lib/constants";
import { useAuthContext } from "@/lib/useAuthContext";
import { showToastrError } from "@/components/commons";

const Table = ({
  userList,
  totalRecords,
  setPageIndex,
  pageIndex,
  getUserListResponse,
  tableLoader,
}) => {
  dayjs.extend(advancedFormat);
  const { user, setUser } = useAuthContext();
  const [emailCopied, setEmailCopied] = useState(false);

  useEffect(() => {
    if (emailCopied) {
      Toastr.success("Link copied to clipboard!");
      setEmailCopied(false);
    }
  }, [emailCopied]);

  return (
    <>
      <div className="pb-app--table-wrapper w-full flex flex-col rounded-lg bg-white px-8 py-6">
        <table className="pb-app--table">
          <thead>
            <tr>
              <th>Name</th>
              <th>URL</th>
              <th>Email</th>
              <th>Last Take</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableLoader ? (
              <tr><td className="!text-center" colSpan="4"><Spinner /></td></tr>
            ) : (
              <>
                {userList.map((user) => {
              return (
                <tr key={user.id}>
                  <td>
                    <NavLink
                      className="justify-self-center"
                      to={`/super_admin/users/${user.id}/edit`}
                    >
                      <div className="flex items-center">
                        <div className="h-10 w-10 overflow-hidden rounded-full border-2 border-black shrink-0 mr-3">
                          <LazyLoad threshold={0.1}>
                            <img src={user.logo_url} className="aspect-square object-cover" alt="user logo" />
                          </LazyLoad>
                        </div>

                        <div className="flex flex-col space-y-1">
                          <span>{user.name}</span>

                          <div className="flex items-center space-x-2 text-xs text-gray-600">
                            <Tooltip content="Subscribers" position="bottom">
                              <span className="flex items-center">
                                <UserCircle size="18" className="mr-1" />{" "}
                                {user.subscriber_size}
                              </span>
                            </Tooltip>
                            <Tooltip content="Takes" position="bottom">
                              <span className="flex items-center">
                                <MailSend size="18" className="mr-1" />{" "}
                                {user.takes_count}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </td>
                  <td>
                    <div className="flex items-center">
                      <span className="mr-2">/{user.url_slug}</span>
                      <CopyToClipboard
                        text={`${window.location.host}/${user.url_slug}`}
                        onCopy={() => setEmailCopied(true)}
                      >
                        <Copy size={16} className="cursor-pointer" />
                      </CopyToClipboard>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      <span className="mr-2">{user.email}</span>
                      <CopyToClipboard
                        text={user.email}
                        onCopy={() => setEmailCopied(true)}
                      >
                        <Copy size={16} className="cursor-pointer" />
                      </CopyToClipboard>
                    </div>
                  </td>
                  <td>
                    {user.last_take_sent_at
                      ? dayjs(user.last_take_sent_at).format(
                          ORDINALIZED_DATE_FORMAT,
                        )
                      : ""}
                  </td>
                  <td>
                    <div className="flex justify-end">
                      <NavLink
                        className="justify-self-center"
                        to={`/super_admin/users/${user.id}/edit`}
                      >
                        <Info />
                      </NavLink>
                    </div>
                  </td>
                </tr>
              );
            })}
              </>
            )}
          </tbody>
        </table>

        <div className="pb-app--pagination-container">
          <Pagination
            count={totalRecords}
            navigate={(page) => {
              setPageIndex(page);
            }}
            pageNo={pageIndex}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </div>
      </div>
    </>
  );
};

export default Table;
