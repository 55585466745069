import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

const LinkButton = ({
  className = "",
  label = "Button",
  style = "primary",
  to,
  ...otherProps
}) => {
  const btnClassNames = classnames("pb-app--button", {
    "pb-app--button--primary": style === "primary",
    "pb-app--button--danger": style === "danger",
    "pb-app--button--link": style === "link",
    "pb-app--button--secondary": style === "secondary",
  });

  return (
    <Link
      to={to}
      className={classnames([btnClassNames, className])}
      {...otherProps}
    >
      {label}
    </Link>
  );
};

export default LinkButton;
