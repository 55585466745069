import React from "react";

import EmptyTakeHeader from "@/images/empty-take-header.jpg";

const LogoHeader = ({ user }) => {
  return (
    <div className="pb-take--logo h-[100px] w-[100px]">
      <img
        className="h-full w-full object-cover"
        src={user?.logo_url}
        alt="User Logo"
      />
    </div>
  );
};

const CoverHeader = ({ take }) => {
  return (
    <div className="relative w-full rounded-lg">
      <div className="relative h-full w-full rounded-lg object-cover lg:rounded-2xl">
        <img
          src={take.cover_image_attributes?.src || EmptyTakeHeader}
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

const TakeHeader = ({ take, user }) => {
  return take.header_image_type == "logo" ? (
    <LogoHeader user={user} />
  ) : (
    <CoverHeader take={take} />
  );
};

export default TakeHeader;
