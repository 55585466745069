import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { Loading } from "@bigbinary/neeto-icons";
import queryString from "query-string";

import { createConnectSubscription } from "@/apis/connected_subscriptions";
import { getUserPlans } from "@/apis/settings/user_plans";

import { showToastrError } from "@/components/commons";
import { Button } from "@/components/commons/SettingsFormUI";
import { BackIcon } from "@/components/commons/PBIcons";

import PlanCard from "./PlanCard";

const SubscribePlans = ({
  user,
  setShowPlans,
  subscriptionId,
  modalContent,
}) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [planLoader, setPlanLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState();
  const [subscribed, setSubscribed] = useState(false);
  const [userPlans, setUserPlans] = useState([]);

  useEffect(() => {
    getUserPlansResponse();

    if (queryParams.success) {
      setSubscribed(true);
    }

    if (queryParams.failure) {
      showToastrError("Payment has been failed. Please try again.");
    }
  }, []);

  const isStripeRequired = () => {
    return userPlans.find((el) => el.id == selectedPlanId)?.paid_plan;
  };

  const createConnectSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const freePlan = userPlans.find((el) => !el.paid_plan);

      if (freePlan?.id == selectedPlanId) {
        setSubscribed(true);
      } else {
        const { data } = await createConnectSubscription({
          connected_subscription: {
            user_plan_id: selectedPlanId,
            subscription_id: subscriptionId || queryParams.subscription_id,
            redirect_to: location.pathname,
          },
        });

        window.location.href = data.url;
      }
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getUserPlansResponse = async () => {
    try {
      const { data } = await getUserPlans(user.id);
      const firstPaidPlan = data.user_plans.find((el) => el.paid_plan);
      setSelectedPlanId(firstPaidPlan?.id);
      setUserPlans(data.user_plans);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setPlanLoader(false);
    }
  };

  const DEFAULT_MESSAGE =
    "Subscribe for free or choose a subscription plan for extra content.  You will get lots of cool photos and some more other things too.";

  return (
    <div className="flex flex-col items-center w-full p-8 gap-8 relative min-h-[calc(100vh-240px)] md:min-h-fit">
      <div className="flex flex-col items-center w-full gap-6">
        <div className="flex flex-col items-center w-full gap-4">
          <div className="overflow-hidden rounded-full">
            <img
              src={user.logo_url}
              alt="artist icon"
              className="object-cover h-12 w-12"
            />
          </div>

          <div className="text-[28px] text-center font-soehne-kraftig md:text-[32px]">
            {user.display_name}
          </div>
        </div>

        <div className="flex flex-col items-center max-w-[527px] text-[#23282E] font-sf-pro">
          <p className="text-base leading-[24px] text-center">
            {user.custom_subscription_message || DEFAULT_MESSAGE}
          </p>
        </div>
      </div>

      <motion.div
        animate={{
          opacity: 1,
          width: "100%",
          height: subscribed ? 0 : "auto",
          overflow: subscribed ? "hidden" : "visible",
          display: subscribed ? "none" : "block",
        }}
        exit={{ opacity: 0, height: 0 }}
        initial={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex flex-col md:flex-row gap-4 w-full">
          {planLoader ? (
            <Loading className="mr-2 animate-spin-slow" />
          ) : (
            userPlans.map((plan) => {
              return (
                <PlanCard
                  key={plan.id}
                  plan={plan}
                  selectedPlanId={selectedPlanId}
                  setSelectedPlanId={setSelectedPlanId}
                />
              );
            })
          )}
        </div>
      </motion.div>

      <div className="flex flex-col items-center w-full gap-4 font-soehne-mono">
        {isStripeRequired() && !subscribed && (
          <p className="text-xs text-[#23282E] text-center">
            You’ll be taken to Stripe to complete your payment
          </p>
        )}

        <Button
          onClick={() =>
            subscribed ? null : createConnectSubscriptionResponse()
          }
          type="submit"
          label={subscribed ? "Subscribed!" : "Subscribe"}
          loading={btnLoader}
          loginBtn
        />
      </div>

      {subscribed && (
        <Link
          to={`/archive/${user.url_slug}`}
          className="text-[16px] leading-[24px]"
          onClick={() => window.scrollTo(0, 0)}
        >
          View archives
        </Link>
      )}

      {!subscribed && !modalContent && (
        <div
          className="absolute left-3 top-3 cursor-pointer"
          onClick={() => setShowPlans(false)}
        >
          <BackIcon />
        </div>
      )}
    </div>
  );
};

export default SubscribePlans;
