import React from "react";
import { Modal } from "@bigbinary/neetoui";

import { LinkButton, Button } from "@/components/commons/AppFormUI";

const SubscriptionModal = ({
  subscriptionModalOpen,
  setSubscriptionModalOpen,
}) => {
  return (
    <Modal
      isOpen={subscriptionModalOpen}
      onClose={() => setSubscriptionModalOpen(false)}
      className="pb-modal"
    >
      <Modal.Header>
        <h4>Subscription Needed</h4>
      </Modal.Header>
      <Modal.Body>
        <p>
          You have reached maximum number of fans in free plan. You need to
          activate pro plan to send takes.
        </p>
      </Modal.Body>
      <Modal.Footer className="flex gap-x-2">
        <LinkButton
          to="/settings/billing"
          rel="noopener noreferrer"
          label="Yes, Please"
          rounded={true}
          onClick={() => window.scrollTo(0, 0)}
        />

        <Button
          label="Cancel"
          onClick={() => setSubscriptionModalOpen(false)}
          style="modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionModal;
