import React, { useState, useEffect } from "react";
import { Spinner, Toastr } from "@bigbinary/neetoui";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

import { getSubscriberFormSetting } from "@/apis/subscriber_form_settings";
import { createSubscription } from "@/apis/subscriptions";
import { showToastrError } from "@/components/commons";

import MobileForm from "./MobileForm";
import DesktopForm from "./DesktopForm";
import SignUpAlert from "./SignUpAlert";

const SubscribeForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { urlSlug } = useParams();
  const [user, setUser] = useState({});
  const [btnLoader, setBtnLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [showPlans, setShowPlans] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState();
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [signUpAlert, setSignUpAlert] = useState(false);

  useEffect(() => {
    getSubscriberFormSettingResponse();

    if (queryParams.show_plans) {
      setShowPlans(true);
    }

    if (queryParams.new_user === "true" && queryParams.success) {
      setSignUpAlert(true);
    }
  }, []);

  useEffect(() => {
    if (
      subscriptionId &&
      user.turn_on_paid &&
      !user.double_opt_in_subscription
    ) {
      setShowPlans(true);
    } else if (subscriptionId && !user.turn_on_paid) {
      setSubscribed(true);
    }
  }, [user, subscriptionId]);

  const handleSubmit = () => {
    createSubscriptionResponse();
  };

  const getSubscriberFormSettingResponse = async () => {
    try {
      const response = await getSubscriberFormSetting(urlSlug);
      setUser(response.data.user);
      setPageLoader(false);
    } catch (error) {
      navigate("/not_found");
      showToastrError(error.response.data.errors);
    }
  };

  const createSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createSubscription({
        subscription: { email: email, user_id: user.id },
      });
      setSubscriptionId(data.id);
      Toastr.success(data.message);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="subscription-form">
      <MobileForm
        user={user}
        setShowPlans={setShowPlans}
        showPlans={showPlans}
        btnLoader={btnLoader}
        subscriptionId={subscriptionId}
        handleSubmit={handleSubmit}
        email={email}
        setEmail={setEmail}
        subscribed={subscribed}
      />
      <DesktopForm
        user={user}
        setShowPlans={setShowPlans}
        showPlans={showPlans}
        btnLoader={btnLoader}
        subscriptionId={subscriptionId}
        handleSubmit={handleSubmit}
        email={email}
        setEmail={setEmail}
        subscribed={subscribed}
      />

      <SignUpAlert modalOpen={signUpAlert} setModalOpen={setSignUpAlert} />
    </div>
  );
};

export default SubscribeForm;
