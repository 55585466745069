import React from "react";

import { LinkButton } from "@/components/commons/HomeFormUI";

import UploadIcon from "@/images/icons/upload-icon.svg";
import PlusCircleIcon from "@/images/icons/plus-circle-icon.svg";

const EmptyPage = ({ setUploadModalOpen }) => {
  return (
    <div className="flex flex-col lg:flex-row space-y-8 mt-8 lg:space-y-0 lg:space-x-8">
      <div className="flex flex-col flex-1 w-full rounded-2xl bg-white p-6 space-y-6">
        <img src={UploadIcon} height={24} width={24} alt="upload icon" />

        <p className="font-soehne-kraftig text-[16px] leading-[19.09px] m-0 pb-3 border-b-2 border-[#E5E0D9]">
          Migrating from another newsletter tool?
        </p>

        <p className="text-[15px] leading-[22px]">
          Import a CSV file of your existing subscribers using our handy
          importer tool.
        </p>

        <LinkButton
          onClick={() => setUploadModalOpen(true)}
          label="Import a CSV file"
          className="w-fit"
        />
      </div>

      <div className="flex flex-col flex-1 w-full rounded-2xl bg-white p-6 space-y-6">
        <img
          src={PlusCircleIcon}
          height={24}
          width={24}
          alt="plus circle icon"
        />

        <p className="font-soehne-kraftig text-[16px] leading-[19.09px] m-0 pb-3 border-b-2 border-[#E5E0D9]">
          Starting from scratch?
        </p>

        <p className="text-[15px] leading-[22px]">
          Go on and customize your booth or use our embed widget then start
          spreading the word!
        </p>

        <LinkButton
          to="/settings/branding"
          label="Customise your booth"
          style="secondary"
          className="!border !border-[#969797] w-fit"
        />
      </div>
    </div>
  );
};

export default EmptyPage;
