import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Modal, Select, Toastr, Spinner, Typography } from "@bigbinary/neetoui";
import { Download, Loading } from "@bigbinary/neeto-icons";
import { Button } from "@/components/commons/AppFormUI";

import { importSubscriptionList } from "@/apis/subscriptions";
import FileUploader from "@/components/commons/FileUploader";

import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

const ImportModal = ({
  uploadModalOpen,
  setUploadModalOpen,
  downloadBtnLoader,
  getCsvDownloadResponse,
}) => {
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      importAudienceListResponse();
    },
  });

  const handleChange = (event) => {
    const file = event.target.files[0];

    let reader = new FileReader();

    reader.onloadend = (e) => {
      formik.setFieldValue("file_data", e.target.result);
      formik.setFieldValue("file_name", file.name);
      formik.setFieldValue("caption", file.name);
    };

    reader.readAsDataURL(file);
  };

  const importAudienceListResponse = async () => {
    try {
      setSubmitBtnLoader(true);
      const { data } = await importSubscriptionList({
        subscription: formik.values,
      });
      Toastr.success(data.message);
      setUploadModalOpen(false);
    } catch (error) {
      Toastr.error(error.response.data.errors);
    } finally {
      setSubmitBtnLoader(false);
    }
  };

  return (
    <Modal
      isOpen={uploadModalOpen}
      onClose={() => setUploadModalOpen(false)}
      className="pb-modal"
    >
      <Modal.Header>
        <h4>Audience Importer</h4>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <Typography style="nano">
          <p>If you are migrating from another newsletter platform, you can import a CSV file of your subscribers here. We highly recommend downloading the sample import CSV and pasting your contacts there.</p><br />
          <p>To avoid your PencilBooth messages being flagged as Spam, we suggest you send a message on your current platform to let your audience know before you send your first take to let them know they have been subscribed to your PencilBooth, and give them a chance to opt out. You can manually remove subscribers on your Audience dashboard if needed.</p><br />
          <p>As a best practice when it comes to contacts you are bringing over from another service, we would suggest not sending an existing newsletter from another platform concurrently with your PencilBooth takes, as this will probably cause mass unsubscribes and potential abuse reports.</p><br/>
          <p>If you need a hand, just drop us a line at <a className="underline" href="mailto:import@pencilbooth.com">import@pencilbooth.com</a>.</p>
        </Typography>
        <FileUploader
          id="file"
          name="file"
          handleChange={handleChange}
          accept="text/csv"
          error={
            Boolean(formik.touched.file_data && formik.errors.file_data) &&
            formik.errors.file_data
          }
        />
        {downloadBtnLoader ? (
          <div className="text-base font-medium hover:underline flex">
            <Loading size={20} className="mr-2 animate-spin-slow" />
            Download Sample Import CSV
          </div>
        ) : (
          <button
            className="text-base font-medium hover:underline flex"
            onClick={() => getCsvDownloadResponse()}
          >
            <Download size={20} className="mr-2" />
            Download Sample Import CSV
          </button>
        )}
      </Modal.Body>
      <Modal.Footer className="flex gap-x-2">
        <Button
          loading={submitBtnLoader}
          label="Upload"
          disabled={downloadBtnLoader}
          onClick={() => formik.handleSubmit()}
        />
        <Button
          label="Cancel"
          disabled={downloadBtnLoader}
          onClick={() => setUploadModalOpen(false)}
          style="modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
