import axios from "axios";

const getAttachmentsUrl = (id) => {
  if (id) {
    return `/api/v1/settings/attachments/${id}`;
  }

  return `/api/v1/settings/attachments`;
};

export const createAttachment = (payload) => {
  return axios.post(getAttachmentsUrl(), payload);
};

export const destroyAttachment = (attachmentId) => {
  return axios.delete(getAttachmentsUrl(attachmentId));
};
