import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Loading } from "@bigbinary/neeto-icons";

import { useAuthContext } from "@/lib/useAuthContext";
import { getTakeList, getTagList, getUser } from "@/apis/visual_archives";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import Header from "@/components/commons/Header";
import AppHeader from "@/components/commons/AppHeader";
import TakeCard from "@/components/commons/TakeCard";

import Banner from "./Banner";
import Tags from "./Tags";
import EmptyPage from "./EmptyPage";

const VisualArchive = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { urlSlug } = useParams();
  const [takeUser, setTakeUser] = useState();
  const [pageLoader, setPageLoader] = useState(true);

  const [takeList, setTakeList] = useState([]);
  const [takesCount, setTakesCount] = useState(0);
  const [takePageIndex, setTakePageIndex] = useState(1);

  const [tagList, setTagList] = useState([]);
  const [tagListLoader, setTagListLoader] = useState(true);
  const [tagsCount, setTagsCount] = useState(0);
  const [tagPageIndex, setTagPageIndex] = useState(1);

  const [tagIds, setTagIds] = useState([]);

  useEffect(() => {
    if (urlSlug?.length > 0) {
      loadWhenUrlSlugFetched();
    }
  }, [urlSlug]);

  const loadWhenUrlSlugFetched = async () => {
    setPageLoader(true);
    setTakeList([]);
    setTakesCount(0);
    setTakePageIndex(1);
    setTagList([]);
    setTagListLoader(true);
    setTagsCount(0);
    setTagPageIndex(1);
    setTagIds([]);
    await getUserResponse();
    await getTakeListResponse([], 1, true);
    await getTagListResponse(true);
    setPageLoader(false);
  };

  useEffect(() => {
    if (tagPageIndex) {
      getTagListResponse(false);
    }
  }, [tagPageIndex]);

  const getTakeListResponse = async (selectedTagIds, pageIndex, freshLoad) => {
    try {
      const { data } = await getTakeList(urlSlug, selectedTagIds, pageIndex);

      if (freshLoad) {
        setTakeList(data.takes);
      } else {
        setTakeList([...takeList, ...data.takes]);
      }

      setTakesCount(data.total_records);
    } catch (error) {
      if (error.response.status === 404) {
        navigate("/not_found");
      }
      showToastrError(error.response.data.errors);
    }
  };

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(urlSlug);
      setTakeUser(data.user);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getTagListResponse = async (freshLoad) => {
    try {
      const { data } = await getTagList(urlSlug, tagPageIndex);

      if (freshLoad) {
        setTagList(data.tags);
      } else {
        setTagList([...tagList, ...data.tags]);
      }

      setTagsCount(data.total_records);
      setTagListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const loadNext = () => {
    if (takeList.length > 0 && takesCount > takeList.length) {
      setTakePageIndex(takePageIndex + 1);
      getTakeListResponse(tagIds, takePageIndex + 1, false);
    }
  };

  const handleAllTagClick = () => {
    setTagIds([]);
    setTakeList([]);
    setTakePageIndex(1);
    getTakeListResponse([], 1, true);
  };

  const handleTagClick = (tag) => {
    let selectedTagIds = [];

    if (tagIds.includes(tag.id)) {
      selectedTagIds = tagIds.filter((id) => id !== tag.id);
    } else {
      selectedTagIds = [...tagIds, tag.id];
    }

    setTagIds(selectedTagIds);
    setTakeList([]);
    setTakePageIndex(1);
    getTakeListResponse(selectedTagIds, 1, true);
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {user && <AppHeader />}
      <div
        className={classnames("flex flex-col items-center w-full", {
          "pb-app--layout-wrapper pt-[64px] lg:pt-[98px]": user,
          "pb-home--layout-wrapper": !user,
        })}
      >
        {!user && <Header />}

        <AnimatedPage
          className={classnames("flex flex-col w-full", {
            "pb-app--layout": user,
            "pb-home--layout pb-home--layout--fullWidth": !user,
          })}
        >
          <Banner
            takeUser={takeUser}
            takesCount={takesCount}
            urlSlug={urlSlug}
            user={user}
            setPageLoader={setPageLoader}
            getTakeListResponse={getTakeListResponse}
            getUserResponse={getUserResponse}
          />

          {tagsCount > 0 && (
            <Tags
              tagIds={tagIds}
              loading={tagListLoader}
              tagsCount={tagsCount}
              setTagPageIndex={setTagPageIndex}
              tagList={tagList}
              handleAllTagClick={handleAllTagClick}
              handleTagClick={handleTagClick}
            />
          )}

          {takeList.length > 0 ? (
            <InfiniteScroll
              dataLength={takeList.length}
              next={loadNext}
              hasMore={takesCount > takeList.length}
              loader={
                <div className="w-full flex justify-center p-6">
                  Loading... <Loading className="animate-spin-slow ml-2" />
                </div>
              }
              scrollThreshold={0.8}
            >
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 1: 1, 767: 2, 1279: 3, 1535: 4 }}
              >
                <Masonry gutter="1.5rem">
                  {takeList.map((take) => {
                    return (
                      <TakeCard
                        key={take.id}
                        take={take}
                        logo_url={takeUser.logo_url}
                      />
                    );
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </InfiniteScroll>
          ) : user?.id == takeUser?.id && !pageLoader && tagList.length == 0 ? (
            <EmptyPage />
          ) : null}
        </AnimatedPage>
      </div>
    </>
  );
};

export default VisualArchive;
