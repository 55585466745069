import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";
import Flatpickr from "react-flatpickr";
import { SEARCH_DATE_FORMAT } from "@/lib/constants";
import SearchIcon from "@/images/icons/search-icon.svg";
import { getUsers } from "@/apis/super_admin/users";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import NoDataPage from "@/components/commons/NoDataPage";
import { Input } from "@/components/commons/SettingsFormUI";
import Table from "./Table";

const Users = () => {
  const [searchTermString, setSearchTermString] = useState();
  const [searchTermNumber, setSearchTermNumber] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [userList, setUserList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [minSearchDate, setMinSearchDate] = useState();
  const [maxSearchDate, setMaxSearchDate] = useState();

  const flatPickrOption = {
    mode: "range",
    dateFormat: "Y-m-d",
    wrap: true,
    allowInput: true,
    disableMobile: true,
  };

  useEffect(() => {
    if (pageIndex) {
      getUserListResponse();
    }
  }, [pageIndex]);

  useEffect(() => {
    getUserListResponse(1);
    setPageIndex(1);
  }, [maxSearchDate]);

  const getUserListResponse = async (page = pageIndex) => {
    try {
      setTableLoader(true);
      let minDate =
        minSearchDate && dayjs(minSearchDate).format(SEARCH_DATE_FORMAT);
      let maxDate =
        maxSearchDate && dayjs(maxSearchDate).format(SEARCH_DATE_FORMAT);
      const { data } = await getUsers(
        page,
        searchTermString,
        searchTermNumber,
        minDate,
        maxDate,
      );
      setUserList(data.users);
      setTotalRecords(data.total_records);
      setListLoader(false);
      setTableLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getUserListResponse(1);
    }
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-app--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-app--layout pb-app--layout--croppedWidth flex flex-col items-center w-full">
        <div className="flex flex-col items-center mb-10 max-w-3xl">
          <h1 className="font-soehne-kraftig text-center text-[24px] leading-[28.64px] font-normal m-0">
            Users
          </h1>
        </div>

        <div className="flex w-full">
          <div className="flex items-center w-1/3 gap-x-3 rounded-lg border border-black bg-white px-4 py-3 mb-3 mr-2">
            <div className="w-full flex">
              <img src={SearchIcon} alt="search icon" className="mr-2" />
              <input
                name="search"
                placeholder="Search for name, email, url"
                value={searchTermString}
                onChange={(e) => setSearchTermString(e.target.value)}
                className="w-full"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>

          <div className="flex items-center w-1/3 gap-x-3 rounded-lg border border-black bg-white px-4 py-3 mb-3 mr-2">
            <div className="w-full flex">
              <img src={SearchIcon} alt="search icon" className="mr-2" />
              <input
                name="search"
                placeholder="Search for subscibers or takes number"
                value={searchTermNumber}
                onChange={(e) => setSearchTermNumber(e.target.value)}
                className="w-full"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>

          <div className="w-1/3">
            <Flatpickr
              data-input
              options={flatPickrOption}
              onChange={([date1, date2]) => {
                setMinSearchDate(date1);
                setMaxSearchDate(date2);
              }}
            >
              <Input
                data-input
                name="dateRange"
                id="dateRange"
                placeholder="Enter last take sent date range"
                className="!border-black !h-auto !py-3"
              />
            </Flatpickr>
          </div>
        </div>

        {userList.length > 0 ? (
          <Table
            userList={userList}
            totalRecords={totalRecords}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            tableLoader={tableLoader}
          />
        ) : (
          <NoDataPage />
        )}
      </AnimatedPage>
    </div>
  );
};

export default Users;
