import React from "react";
import classnames from "classnames";
import { Loading } from "@bigbinary/neeto-icons";

const MobileButton = ({
  className = "",
  label = "Button",
  loading = false,
  disabled = false,
  ...otherProps
}) => {
  return (
    <button
      {...otherProps}
      className={classnames(
        ["flex items-center justify-center cursor-pointer", className],
        { "pointer-events-none": loading },
        { "pointer-events-none opacity-40": disabled },
      )}
    >
      {loading ? <Loading className="animate-spin-slow" /> : label}
    </button>
  );
};

export default MobileButton;
