import axios from "axios";

export const getLinkRollsPreview = (takeId, url) => {
  return axios.get(`/api/v1/takes/${takeId}/link_rolls/new?url=${url}`);
};

export const destroyLinkRoll = (takeId, id) => {
  return axios.delete(`/api/v1/takes/${takeId}/link_rolls/${id}`);
};

export const reorderLinkRoll = (takeId, linkRollId, reorderType) => {
  return axios.get(
    `/api/v1/takes/${takeId}/link_rolls/${linkRollId}/reorder?reorder_type=${reorderType}`,
  );
};

export const createLinkRoll = (takeId, payload) => {
  return axios.post(`/api/v1/takes/${takeId}/link_rolls`, payload);
};

export const updateLinkRoll = (takeId, linkRollId, payload) => {
  return axios.put(`/api/v1/takes/${takeId}/link_rolls/${linkRollId}`, payload);
};
