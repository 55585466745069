import axios from "axios";

const getDisciplinesUrl = () => {
  return `/api/v1/settings/disciplines`;
};

export const getDisciplines = () => {
  return axios.get(getDisciplinesUrl());
};

export const createDiscipline = (payload) => {
  return axios.post(getDisciplinesUrl(), payload);
};
