import React, { useState } from "react";
import { useFormik } from "formik";
import { Toastr, Modal } from "@bigbinary/neetoui";

import { useAuthContext } from "@/lib/useAuthContext";
import { getUser, updateUser } from "@/apis/users";
import { showToastrError } from "@/components/commons";
import { MESSAGE_INITIAL_VALUE, MESSAGE_VALIDATION_SCHEMA } from "./constants";
import { Textarea, Button } from "@/components/commons/SettingsFormUI";

const SubscriberWelcomeModal = ({
  subscriberModalOpen,
  setSubscriberModalOpen,
}) => {
  const { user, setUser } = useAuthContext();
  const [btnLoader, setBtnLoader] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user?.id ? user : MESSAGE_INITIAL_VALUE,
    validationSchema: MESSAGE_VALIDATION_SCHEMA,
    onSubmit: () => updateUserResponse(),
  });

  const getUserResponse = async () => {
    try {
      const { data } = await getUser(user.id);
      setUser({ ...user, ...data.user });
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateUserResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateUser(user.id, {
        user: { welcome_message: formik.values.welcome_message },
      });
      Toastr.success(data.message);
      await getUserResponse();
      setSubscriberModalOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <Modal
      isOpen={subscriberModalOpen}
      onClose={() => setSubscriberModalOpen(false)}
      className="pb-large-modal"
    >
      <Modal.Header>
        <h4>Subscriber Welcome</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-6 py-6 border-t border-gray-300">
          <div className="grid grid-cols-1 lg:grid-cols-4 max-w-[1200px]">
            <label className="col-span-1 mb-2 mr-2 text-sm uppercase lg:mb-0">
              Subscriber Welcome
            </label>
            <div className="col-span-3 flex flex-col w-full gap-5">
              <Textarea
                id="welcome_message"
                name="welcome_message"
                defaultValue={formik.values.welcome_message}
                onBlur={formik.handleChange}
                textCount={formik.values.welcome_message?.length}
                placeholder="Write subscriber welcome message here..."
                error={
                  Boolean(
                    formik.touched.welcome_message &&
                      formik.errors.welcome_message,
                  ) && formik.errors.welcome_message
                }
              />
              <span className="text-sm leading-5 text-lightBlack opacity-60">
                This is the text people will see when they go to your subscriber
                page. This is your chance to tell people a bit more about what
                you'll be sending them!
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-x-2 pt-6 border-t border-gray-300">
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            label="Update"
            loading={btnLoader}
          />
        </div>
      </Modal.Body>

      <Modal.Footer className="bg-trnasparent"></Modal.Footer>
    </Modal>
  );
};

export default SubscriberWelcomeModal;
