import axios from "axios";

export const getUsers = (
  searchTerm,
  searchLocationTerm,
  searchDisciplineTerm,
) => {
  let searchQuery = "";
  if (searchTerm?.length > 0)
    searchQuery += `q[name_or_display_name_or_location_or_disciplines_name_i_cont_any]=${searchTerm}`;
  if (searchLocationTerm?.length > 0)
    searchQuery += `&q[location_i_cont_any]=${searchLocationTerm}`;
  if (searchDisciplineTerm?.length > 0)
    searchQuery += `&q[disciplines_name_i_cont_any]=${searchDisciplineTerm}`;
  return axios.get(`/api/v1/searches?${searchQuery}`);
};

export const getUserPreview = () => {
  return axios.get("/api/v1/preview_users");
};

export const getUserDisciplines = () => {
  return axios.get("/api/v1/searches/disciplines");
};
