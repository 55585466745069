import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Eye, Hide, MenuVertical } from "@bigbinary/neeto-icons";
import { Dropdown, Tag, Toastr, Modal, Pagination } from "@bigbinary/neetoui";

import { Button } from "@/components/commons/AppFormUI";
import {
  getStyle,
  DEFAULT_PAGE_SIZE,
  ORDINALIZED_DATE_FORMAT,
} from "@/lib/constants";
import { showToastrError } from "@/components/commons";
import { updateSubscription } from "@/apis/subscriptions";

const Table = ({
  audienceList,
  totalRecords,
  setPageIndex,
  pageIndex,
  getAudienceListResponse,
}) => {
  dayjs.extend(advancedFormat);
  const { Menu, MenuItem } = Dropdown;
  const [audience, setAudience] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const updateSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await updateSubscription(audience.id, {
        subscription: { unsubscribed: !audience.unsubscribed },
      });
      Toastr.success(data.message);
      getAudienceListResponse();
      setAlertOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const getAlertMessage = () => {
    if (!audience) return null;

    if (audience.upgraded_to_paid) {
      return `You can not unsubscribe this audience, because this audience is a paid customer.`;
    } else if (audience.unsubscribed) {
      return `Are you sure you want to resubscribe the audience?`;
    } else if (!audience.unsubscribed) {
      return `Are you sure you want to unsubscribe the audience? ${audience.upgraded_to_paid && !audience.plan_ending ? "It's a gentle reminder that this audience is a paid subscriber." : ""}`;
    }
  };

  const getAlertTitle = () => {
    if (!audience) return null;

    if (audience.unsubscribed) {
      return "Resubscribe";
    } else {
      return "Unsubscribe";
    }
  };

  const renderSubscription = (subscription) => {
    if (subscription.unsubscribed) {
      return <span></span>;
    } else if (subscription.plan_type === "Free") {
      return <span>Free</span>;
    } else {
      return (
        <div className="flex flex-col gap-2">
          <span>{`${subscription.plan_amount} / ${subscription.plan_type}`}</span>
          <span className="text-[10px] text-gray-500 uppercase">
            {`${subscription.plan_ending ? "Ends On" : "Renews On"} ${dayjs(
              subscription.plan_end_date,
            ).format(ORDINALIZED_DATE_FORMAT)}`}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <div className="pb-app--table-wrapper w-full flex flex-col rounded-lg bg-white px-8 py-6">
        <table className="pb-app--table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Last Email Status</th>
              <th>Status</th>
              <th>Date subscribed</th>
              <th>Plan Details</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {audienceList.map((audience) => {
              return (
                <tr key={audience.id}>
                  <td>{audience.email}</td>
                  <td>{audience.last_email_status}</td>
                  <td>
                    <Tag
                      style={getStyle(audience, "status")}
                      type="solid"
                      label={audience.status}
                    />
                  </td>
                  <td>
                    {dayjs(audience.created_at).format(ORDINALIZED_DATE_FORMAT)}
                  </td>
                  <td>{renderSubscription(audience)}</td>
                  <td>
                    <div className="flex justify-end">
                      <Dropdown
                        customTarget={
                          <div className="pb-app--table--menu-icon cursor-pointer bg-white p-1">
                            <MenuVertical size={16} />
                          </div>
                        }
                      >
                        <Menu>
                          {audience.unsubscribed ? (
                            <MenuItem.Button
                              className="flex items-center gap-x-3"
                              prefix={<Eye size={16} />}
                              onClick={() => {
                                setAudience(audience);
                                setAlertOpen(true);
                              }}
                            >
                              Resubscribe
                            </MenuItem.Button>
                          ) : (
                            <MenuItem.Button
                              className="flex items-center gap-x-3"
                              prefix={<Hide size={16} />}
                              onClick={() => {
                                setAudience(audience);
                                setAlertOpen(true);
                              }}
                            >
                              Unsubscribe
                            </MenuItem.Button>
                          )}
                        </Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="pb-app--pagination-container">
          <Pagination
            count={totalRecords}
            navigate={(page) => {
              setPageIndex(page);
            }}
            pageNo={pageIndex}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </div>
      </div>

      <Modal
        isOpen={alertOpen}
        onClose={() => setAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>{getAlertTitle()}</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>{getAlertMessage()}</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          {!audience?.upgraded_to_paid && (
            <Button
              label="Yes, Proceed"
              type="submit"
              style="danger"
              onClick={() => updateSubscriptionResponse()}
              loading={btnLoader}
            />
          )}

          <Button
            label="Cancel"
            onClick={() => setAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Table;
