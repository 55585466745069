import React from "react";
import { useAuthContext } from "@/lib/useAuthContext";

const LogoHeader = () => {
  const { user } = useAuthContext();

  return (
    <div className="pb-take--logo h-[100px] w-[100px]">
      <img
        className="h-full w-full object-cover"
        src={user.logo_url}
        alt="User Logo"
      />
    </div>
  );
};

export default LogoHeader;
