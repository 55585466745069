import React, { useState } from "react";
import { Link } from "react-router-dom";

import { MAX_STRING_LENGTH } from "@/lib/constants";
import { Input, Button } from "@/components/commons/SettingsFormUI";

const FormCardFront = ({
  user,
  email,
  setEmail,
  handleSubmit,
  btnLoader,
  subscribed,
}) => {
  const [error, setError] = useState();

  return (
    <div className="flex flex-col items-center w-full p-6 gap-y-6 md:px-12 md:pt-12">
      <div className="flex flex-col items-center w-full">
        <div className="overflow-hidden rounded-full">
          <img
            src={user.logo_url}
            alt="artist icon"
            className="object-cover h-12 w-12"
          />
        </div>
      </div>

      <div className="text-[28px] leading-[33.41px] text-center font-soehne-kraftig md:text-[32px] md:leading-[38.19px]">
        {user.display_name}
      </div>

      {user.welcome_message?.length > 0 && (
        <p className="text-[16px] leading-[24px] text-center m-0">
          {user.welcome_message}
        </p>
      )}

      <div className="flex flex-col w-full">
        <div className="flex justify-center gap-x-3 w-full">
          {!subscribed && (
            <Input
              id="email"
              name="email"
              type="email"
              className="!text-[16px]"
              value={email}
              onChange={(e) => {
                setError("");
                setEmail(e.target.value);
              }}
              placeholder="Enter email address"
              maxLength={MAX_STRING_LENGTH}
            />
          )}

          <Button
            onClick={() => {
              if (subscribed) {
                return null;
              } else {
                if (email?.length === 0) {
                  setError("Email can't be blank.");
                } else {
                  handleSubmit();
                }
              }
            }}
            type="submit"
            label={subscribed ? "Subscribed!" : "Subscribe"}
            loading={btnLoader}
            loginBtn
          />
        </div>

        {error?.length > 0 && (
          <div className="text-sm leading-5 text-red-500 opacity-60 mt-2">
            {error}
          </div>
        )}
      </div>

      <div className="px-[48px] py-[12px]">
        <Link
          to={`/archive/${user.url_slug}`}
          className="text-[16px] leading-[24px]"
          onClick={() => window.scrollTo(0, 0)}
        >
          View archives
        </Link>
      </div>
    </div>
  );
};

export default FormCardFront;
