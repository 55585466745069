import React from "react";

import AnimatedPage from "@/components/commons/AnimatedPage";
import Footer from "@/components/commons/Footer";
import Header from "@/components/commons/Header";

const TermsAndConditions = () => (
  <div className="pb-home--layout-wrapper flex flex-col items-center w-full bg-secondary">
    <Header hideSearch />

    <AnimatedPage className="pb-home--layout flex flex-col items-center w-full space-y-12 md:space-y-20">
      <h2 className="text-[28px] lg:text-[42px] font-medium leading-snug font-soehne-kraftig text-center">
        Terms & Conditions
      </h2>
      <div className="flex flex-col gap-y-6">
        <p className="text-sm font-normal leading-normal md:text-base">
          Thank you for using Pencilbooth, owned and operated by Pencilbooth
          (ABN 27 628 752 705) (referred to in this policy as “
          <strong>Pencilbooth</strong>”, “<strong>we</strong>”, “
          <strong>us</strong>” or “<strong>our</strong>”). These terms and
          conditions (“<strong>Terms of Use</strong>”) govern your use of the
          Pencilbooth application and associated products and services offered
          by Pencilbooth, including our website located at&nbsp;
          <a
            href="/"
            target="undefined"
            rel="noopener noreferrer"
            data-href=""
            className="underline"
          >
            https://pencilbooth.com/
          </a>
          &nbsp;(referred to in this policy collectively, the “
          <strong>Services</strong>”).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          Please read these Terms of Use carefully prior to creating an account
          with Pencilbooth. By using our Services, you accept and intend to be
          bound by these Terms of Use, as well as any other policies or
          procedures that we may publish from time to time (including our
          privacy policy located at{" "}
          <a
            href="/privacy"
            target="_blank"
            rel="noopener noreferrer"
            data-href=""
            className="underline"
          >
            “<strong>Privacy Policy</strong>”
          </a>
          ).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We may update these Terms of Use at any time by displaying the amended
          terms and conditions on our website. Unless required by law, we will
          not provide you with individual notice of any changes, so please be
          sure to check this page regularly for updates.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Registration</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          In order to use our Services you must create an account (“
          <strong>Member Account</strong>”).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          When you register your Member Account, you will provide us with
          personal information including your full name, email address, and a
          profile picture. You must ensure that this information is accurate and
          current. We will handle all personal information we collect in
          accordance with our Privacy Policy.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We will also ask you to create a password. You are responsible for all
          use and activity carried out under your Member Account, so we
          encourage you to keep your password secure at all times. If you think
          someone has gained access to your Member Account without your
          permission, please contact us immediately at team@pencilbooth.com.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          &nbsp;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          By registering for a Member Account, you agree that:
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (a)&nbsp;&nbsp;&nbsp;you are at least 18 years of age, or if you are
          under 18 years of age that your parent or guardian is familiar with
          and consents to these Terms of Use;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (b)&nbsp;&nbsp;&nbsp;if you create a Member Account on behalf of a
          third party, you are authorised to do so;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (c)&nbsp;&nbsp;&nbsp;you will not create a Member Account with false
          information;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (d)&nbsp;&nbsp;&nbsp;if we disable your Member Account for any reason,
          you will not attempt to make a new Member Account;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (e)&nbsp;&nbsp;&nbsp;you will not transfer your Member Account to
          anyone without first gaining our written permission.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Services</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          We own the Pencilbooth application and associated Services. We grant
          you a non-exclusive, worldwide, revocable, non-transferable licence to
          use the Services in accordance with these Terms of Use.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          You may not:
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (a)&nbsp;&nbsp;&nbsp;modify, make derivative works of, disassemble,
          reverse compile, or reverse engineer any part of the Services, or
          access or use the Services in order to build or support, and/or assist
          a third party in building or supporting, products or services
          competitive with Services;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (b)&nbsp;&nbsp;&nbsp;license, sell, rent, lease, transfer, assign,
          distribute, display, host, outsource, or otherwise commercially
          exploit or make the Services available to any third party other than
          as expressly permitted under these Terms of Use.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          You acknowledge that the Services may not operate on a continuous
          basis and could be unavailable from time to time (including for
          maintenance purposes). We reserve the right to amend or cease the
          operation of the Services, or any part of the Services, at any time in
          our sole discretion without prior notice or liability to you.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mailing List</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          The Services will allow you to create and compile a list of names and
          email addresses for the purpose of sending electronic communications
          to the persons on such list (“
          <strong>Mailing List</strong>”). Your Mailing List is your property,
          and will remain your property even if you decide to stop using our
          Services. You must not use purchased, rented, or otherwise illegally
          obtained third party lists of email addresses to create your Mailing
          List, or otherwise add persons to your Mailing List without their
          express consent.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          If you use your Mailing List to run campaigns, promotions or contests,
          you are solely responsible for ensuring compliance with any applicable
          laws and regulations that may apply to such campaign, promotion, or
          contest, including obtaining any licence from the necessary
          authorities in respect of any trade promotion.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your Content</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          You own all content and information that you collect, upload, or
          otherwise make available via the Service, including through your
          Mailing Lists (your “<strong>Content</strong>”).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          By creating a Member Account, you grant us the right to host,
          distribute, and otherwise use your Content for the sole limited
          purpose of operating, developing, providing, and improving the
          Service. We will not use your Content for any other purpose other than
          facilitating your use of the Service. Any rights granted to us in
          respect of the Content will end when you delete your Member Account,
          however you acknowledge that deleted Content may persist in backup
          copies for a reasonable period of time (but will not be available to
          others).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          You warrant that your use of the Content in connection with the
          Services will not cause you or us to breach any law, regulation, rule,
          code or other legal obligation.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          Further, you agree that you will not use the Services to reproduce or
          communicate Content that:
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (a)&nbsp;&nbsp;&nbsp;infringes the intellectual property rights of any
          third party, including by using another person’s copyrights, trade
          marks, designs, or patents without their permission;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (b)&nbsp;&nbsp;&nbsp;is or could reasonably be considered to be
          deceptive, misleading, false, fraudulent, obscene, defamatory,
          disparaging, indecent, seditious, offensive, scandalous, sexually
          explicit, threatening, abusive, harassing, hateful, degrading,
          intimidating, graphically violent, liable to incite hatred,
          discriminatory, blasphemous or otherwise inappropriate;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (c)&nbsp;&nbsp;&nbsp;depicts, encourages, promotes or incites conduct
          that could violate any law or give rise to criminal or civil
          liability, including without limitation content supporting terrorism
          or violence;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (d)&nbsp;&nbsp;&nbsp;unlawfully impersonates any person (including by
          way of celebrity look-a-likes or sound-a-likes), or states or
          misrepresents any affiliation with any person where such affiliation
          does not exist;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (e)&nbsp;&nbsp;&nbsp;contains any confidential information, material,
          or non-public information about any third party without express
          approval of that third party;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (f)&nbsp;&nbsp;&nbsp;contains “viruses”, “Trojan horses”, “copybots”,
          “worms”, spyware, “time bombs”, “cancelbots” or any other computer
          programs or codes that are intended to damage, interrupt, destroy,
          detrimentally interfere with or limit the functionality of any
          computer system or similar device;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (g)&nbsp;&nbsp;&nbsp;encourages persons to do or omit from doing
          things that might be injurious to any person’s wellbeing;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (h)&nbsp;&nbsp;&nbsp;otherwise brings Pencilbooth, or has the
          potential to bring Pencilbooth, into disrepute; or
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (i)&nbsp;&nbsp;&nbsp;is considered to be unsolicited bulk mail such as
          “spam”, “junk mail”, “chain letters”, “pyramid schemes” or any other
          form of solicitation, unauthorised advertisements or unauthorised
          promotion.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          You understand and agree that we may monitor or review any Content on
          the Services. We may delete your Member Account if any of your Content
          violates these Terms of Use.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          Although we reserve the right to review and delete Member Accounts of
          users whose Content violates these Terms of Use, such Content is the
          sole responsibility of the user who uploads or otherwise makes it
          available on the Service, and we cannot guarantee that all Content
          will comply with these Terms of Use. If you are aware of Content on
          the Services that violates these Terms of Use, please report it to at
          team@pencilbooth.com.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy and Spam</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          As the owner and controller of your Mailing Lists, you are responsible
          for ensuring that you comply with all laws that may apply to your
          collection, storage, use, and disclosure of personal data, including
          the Privacy Act 1988 (Cth) (“
          <strong>Privacy Act</strong>”), the Australian Privacy Principles (“
          <strong>APPs</strong>”), the Spam Act 2003 (Cth) (“
          <strong>Spam Act</strong>”) and, to the extent that it applies, the EU
          General Data Protection Regulation 2016/679 (“
          <strong>GDPR</strong>”).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          As a condition of using the Services, you must adopt our pro forma
          privacy policy which sets out how you can and can’t use personal data
          of subscribers to your Mailing List (a copy can be viewed&nbsp;
          <a
            className="underline"
            href="/privacy_pro_forma"
            data-href="/privacy_pro_forma"
            target="_blank"
          >
            here
          </a>
          ). You must make yourself familiar with the pro forma privacy policy,
          and implement any processes required in order to comply with its
          terms.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>
            6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile and Other Devices
          </strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          Please be aware that when you access or use the Services on your
          mobile or other personal device that your carrier's normal rates and
          fees will still apply.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Linked Sites</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          Our Services may contain links to websites operated by third parties.
          Those links are provided for convenience and may not remain current or
          be maintained. Unless expressly stated otherwise, we do not endorse
          and are not responsible for the content on those linked websites and
          have no control over or rights in those linked websites.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>
            8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Intellectual Property Notices
          </strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          If you believe that any materials on the Services infringe your
          intellectual property, you may request that such materials be removed.
          This request must bear a signature (or electronic equivalent) of the
          rightsholder or an authorised representative, and must:
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (a)&nbsp;&nbsp;&nbsp;identify the allegedly infringing materials;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (b)&nbsp;&nbsp;&nbsp;indicate where the infringing materials are
          located on the Service;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (c)&nbsp;&nbsp;&nbsp;include your full legal name and current contact
          information;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (d)&nbsp;&nbsp;&nbsp;state that you have a good faith belief that the
          materials are infringing;
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (e)&nbsp;&nbsp;&nbsp;state that the information in your claim is
          accurate; and
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          (f)&nbsp;&nbsp;&nbsp;indicate that you are the lawful owner or
          controller of the intellectual property, or are authorised to act on
          the owner's behalf.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          If you believe that someone has wrongly filed a notice of infringement
          against you, you may send us a counter-notice in accordance with our
          requirements. Our contact for intellectual property notices relating
          to the Services is at team@pencilbooth.com.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          For the purpose of these Terms of Use, intellectual property includes,
          but is not limited to, patents, designs, patterns, artistic works,
          photography, literary works, musical works, sound recordings,
          cinematograph films, software, trademarks, service marks, trade names
          and logos.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Termination</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          You may terminate your Member Account at any time, for any reason, by
          emailing team@pencilbooth.com. We may terminate your account at any
          time without notice if we believe that you have violated these Terms
          of Use, or if we otherwise cease to provide any part or whole of the
          Services at our sole discretion.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>
            10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disclaimer of Warranty
          </strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          The Services are provided “as is", without any warranty of any kind.
          We expressly disclaim all implied warranties to the maximum extent
          allowed by law, and we make no warranty regarding the quality or
          suitability of the Service, or the accuracy of any information in the
          Services.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We are not liable to you or anyone else if interference with or damage
          to your computer systems occurs in connection with the use of the
          Services or a third-party linked website. You must take your own
          precautions to ensure that whatever you select for your use of our
          Services is free of viruses or anything else that may interfere with
          or damage the operations of your computer systems.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          To the maximum extent permitted by law, we and our affiliates, parent
          companies, subsidiaries, officers, directors, employees, agents,
          network service providers, business partners, licensees and licensors
          (collectively, the “<strong>Related Parties</strong>
          ”) disclaim all liability, whether based in contract, tort (including
          negligence), strict liability or otherwise, and further excludes
          liability for all losses, including without limitation direct,
          indirect, incidental, consequential, or special damages arising out of
          or in any way connected with access to or use of the Services, even if
          we or our Related Parties have been advised of the possibility of such
          damages.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indemnity by you</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          You agree to indemnify, hold harmless and defend us and our Related
          Parties at your expense, against any and all claims, actions,
          proceedings and suits and all related liabilities, damages,
          settlements, penalties, fines, costs and expenses (including, without
          limitation, reasonable solicitors' fees and other dispute resolution
          expenses) incurred by us and our Related Parties arising out of or
          relating to: (a) your access or use of the Services; (b) your Mailing
          Lists and Content; (c) your breach of these Terms of Use; and (d) your
          non-compliance with any privacy laws or the pro forma privacy policy.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Miscellaneous</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          Your use of the Services and these Terms of Use are governed by the
          law of Victoria and you submit to the non-exclusive jurisdiction of
          the courts of Victoria
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We may transfer, assign, novate or otherwise deal with any part or
          whole of the Services without notification to you.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          If any provision of these Terms of Use are found to be unenforceable,
          then that provision or provisions will be severed from these Terms of
          Use and will not affect the validity and enforceability of any
          remaining provisions.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          These Terms of Use, together with the Privacy Policy, make up the
          entire agreement between you and us, and supersede any prior
          agreements in respect of your use of the Services.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          You may contact us at&nbsp;
          <a
            className="underline"
            href="mailto:team@pencilbooth.com"
            target="undefined"
            rel="noopener noreferrer"
          >
            team@pencilbooth.com
          </a>
          &nbsp;to provide feedback on the Services. It is entirely your choice
          to provide feedback. You acknowledge that we may use your feedback to
          develop and improve the Services, including to provide new features
          without any further reference or compensation to you.
        </p>
      </div>
      <Footer />
    </AnimatedPage>
  </div>
);

export default TermsAndConditions;
