import React, { useState, useEffect } from "react";
import { Spinner } from "@bigbinary/neetoui";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "@bigbinary/neeto-icons";

import { getUsers } from "@/apis/featured_booths";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import BoothCard from "@/components/commons/BoothCard";
import Header from "@/components/commons/Header";
import AppHeader from "@/components/commons/AppHeader";
import { useAuthContext } from "@/lib/useAuthContext";

const FeaturedBooth = () => {
  const { user } = useAuthContext();
  const [boothUsers, setBoothUsers] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    getBoothUsersResponse();
  }, []);

  const getBoothUsersResponse = async () => {
    try {
      const { data } = await getUsers(pageIndex + 1);
      setBoothUsers([...boothUsers, ...data.users]);
      setTotalRecords(data.total_records);
      setPageIndex(pageIndex + 1);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const handleSubscription = (id) => {
    setBoothUsers(
      boothUsers.map((el) => {
        if (el.id == id) {
          el.subscribed = true;
        }

        return el;
      }),
    );
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {user && <AppHeader />}
      <div
        className={classnames("flex flex-col items-center w-full", {
          "pb-app--layout-wrapper pt-[64px] lg:pt-[98px]": user,
          "pb-home--layout-wrapper": !user,
        })}
      >
        {!user && <Header />}

        <AnimatedPage
          className={classnames("flex flex-col w-full", {
            "pb-app--layout": user,
            "pb-home--layout pb-home--layout--fullWidth": !user,
          })}
        >
          <InfiniteScroll
            dataLength={boothUsers.length}
            next={getBoothUsersResponse}
            hasMore={totalRecords > boothUsers.length}
            loader={
              <div className="w-full flex justify-center p-6">
                Loading... <Loading className="animate-spin-slow ml-2" />
              </div>
            }
            scrollThreshold={0.5}
          >
            <h2 className="font-soehne-kraftig mb-8 text-center text-2xl font-medium md:mb-12 lg:text-5xl">
              Featured Booths
            </h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {boothUsers.map((boothUser) => {
                return (
                  <BoothCard
                    boothUser={boothUser}
                    key={boothUser.id}
                    handleSubscription={handleSubscription}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
        </AnimatedPage>
      </div>
    </>
  );
};

export default FeaturedBooth;
