import axios from "axios";

const getLocationsUrl = () => {
  return `/api/v1/settings/locations`;
};

export const getLocations = () => {
  return axios.get(getLocationsUrl());
};

export const createLocation = (payload) => {
  return axios.post(getLocationsUrl(), payload);
};
