import React, { useState } from "react";
import { Modal, Toastr } from "@bigbinary/neetoui";
import * as dayjs from "dayjs";

import { Button } from "@/components/commons/AppFormUI";
import { updateTake } from "@/apis/super_admin/takes";
import { showToastrError } from "@/components/commons";

const ApprovalBanner = ({ takeDetail, setTakeDetail }) => {
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  const [btnLoader, setBtnLoader] = useState(false);
  const [approvalConfirmAlert, setApprovalConfirmAlert] = useState(false);
  const [rejectConfirmAlert, setRejectConfirmAlert] = useState(false);

  const updateTakeResponse = async (approvalState) => {
    try {
      setBtnLoader(true);
      const { data } = await updateTake(takeDetail.id, {
        take: {
          [approvalState]: dayjs().utc().format(),
          under_review: false,
        },
      });
      setTakeDetail({ ...takeDetail, under_review: false });
      Toastr.success(data.message);
      setApprovalConfirmAlert(false);
      setRejectConfirmAlert(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex justify-between py-4 space-x-2">
      <Button
        label="Approve"
        type="submit"
        onClick={() => setApprovalConfirmAlert(true)}
      />

      <Button
        label="Reject"
        type="submit"
        style="danger"
        onClick={() => setRejectConfirmAlert(true)}
      />

      <Modal
        isOpen={approvalConfirmAlert}
        onClose={() => setApprovalConfirmAlert(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Approve Take</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to approve the take?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Yes, Approve"
            type="submit"
            loading={btnLoader}
            onClick={() => updateTakeResponse("approved_at")}
          />

          <Button
            label="Cancel"
            onClick={() => setApprovalConfirmAlert(false)}
            style="link"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={rejectConfirmAlert}
        onClose={() => setRejectConfirmAlert(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Reject Take</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to reject the take?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Yes, Reject"
            type="submit"
            style="danger"
            loading={btnLoader}
            onClick={() => updateTakeResponse("rejected_at")}
          />

          <Button
            label="Cancel"
            onClick={() => setRejectConfirmAlert(false)}
            style="link"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ApprovalBanner;
