import React from "react";
import { Modal, Typography } from "@bigbinary/neetoui";
import { Button, LinkButton } from "@/components/commons/HomeFormUI";

const SignUpAlert = ({ modalOpen, setModalOpen }) => {
  return (
    <Modal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      className="pb-modal"
    >
      <Modal.Header>
        <h4>Psst!</h4>
      </Modal.Header>
      <Modal.Body className="space-y-4">
        <Typography style="body2">
          It looks like you don’t have your own PencilBooth account, in which
          case you won’t be able to view paid content on the web - You’ll still
          receive it via email though. Please sign up for a free account here to
          ensure you have full access. You can also do this later..
        </Typography>
      </Modal.Body>
      <Modal.Footer className="flex gap-x-2">
        <LinkButton label="Sign Up" type="submit" to="/signup" />

        <Button
          label="Cancel"
          onClick={() => setModalOpen(false)}
          style="modal"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default SignUpAlert;
