import axios from "axios";

export const createSession = (payload) => {
  return axios.post(`/api/v1/login`, payload);
};

export const expireSession = () => {
  return axios.delete(`/api/v1/logout`);
};

export const authenticate = () => {
  return axios.get(`/api/v1/me`);
};
