import React from "react";
import { Navigate, useOutlet } from "react-router-dom";

import AppHeader from "@/components/commons/AppHeader";
import SearchBanner from "@/components/commons/SearchBanner";
import { useAuthContext } from "@/lib/useAuthContext";
import { registerIntercepts } from "@/apis/axios";

const AppLayout = () => {
  const outlet = useOutlet();
  const { user, logout, hideHeader, setSearch } = useAuthContext();

  if (user) {
    registerIntercepts(logout);

    return (
      <div className="min-h-screen font-soehne-mono bg-secondary">
        {!hideHeader && <AppHeader />}
        {outlet}
        <SearchBanner onClose={() => setSearch(false)} />
      </div>
    );
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export default AppLayout;
