import React, { useState, useEffect, useRef } from "react";
import * as pluralize from "pluralize";
import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Modal } from "@bigbinary/neetoui";
import LazyLoad from "react-lazy-load";

import { LinkButton } from "@/components/commons/HomeFormUI";
import { Button } from "@/components/commons/SettingsFormUI";
import { Button as ModalButton } from "@/components/commons/AppFormUI";
import { updateTake } from "@/apis/takes";
import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import { useAuthContext } from "@/lib/useAuthContext";

const TakeCard = ({ take, logo_url, discoveryPage, setTakeList }) => {
  dayjs.extend(advancedFormat);
  const { user } = useAuthContext();
  const [removeAlertOpen, setRemoveAlertOpen] = useState(false);
  const [removingTakeId, setRemovingTakeId] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const takeAttachments = take.attachments_attributes;
  const takeAttachmentCount = takeAttachments.length;
  const firstTakeAttachment = takeAttachments[0];

  const [width, setWidth] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(contentRef.current.offsetWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateTakeResponse = async () => {
    try {
      setBtnLoader(true);
      await updateTake(removingTakeId, { take: { featured: false } });
      setRemoveAlertOpen(false);
      setTakeList((data) => data.filter((d) => d.id !== take.id));
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div
      className="relative flex flex-col w-full pb-take__wrapper overflow-hidden"
      ref={contentRef}
    >
      <LazyLoad className="h-full w-full aspect-auto" threshold={0.1}>
        <img
          src={firstTakeAttachment.attachment_url}
          height={firstTakeAttachment?.height}
          width={
            firstTakeAttachment?.width > width
              ? firstTakeAttachment?.width
              : width
          }
        />
      </LazyLoad>

      <div className="flex items-center gap-x-3 py-3">
        <div className="overflow-hidden bg-primary border-2 border-black rounded-full shrink-0">
          <img src={logo_url} className="w-10 h-10 object-cover" />
        </div>

        <div className="flex flex-col items-start">
          <h4 className="text-base font-medium leading-normal text-lightBlack line-clamp-1">
            {take.subject}
          </h4>
          <p className="text-xs font-normal leading-4 text-lightBlack opacity-60">
            {dayjs(take.sent_at).format(ORDINALIZED_DATE_FORMAT)}
            {" • "}
            {takeAttachmentCount} {pluralize("image", takeAttachmentCount)}
          </p>
        </div>
      </div>

      <div className="pb-take-action--wrapper"></div>

      <div className="w-full pb-take--action">
        <div className={`mb-4 flex space-x-0.5`}>
          {takeAttachments.map((img) => {
            return (
              <div className="pb-take--action_img--wrapper" key={img.id}>
                <img
                  src={img.attachment_url}
                  className="h-full w-full object-cover"
                />
              </div>
            );
          })}
        </div>

        <div className="flex">
          <LinkButton
            to={`/takes/${take.id}`}
            label="View Take"
            fullWidth
            onClick={() => window.scrollTo(0, 0)}
          />

          {discoveryPage && user?.superadmin && (
            <Button
              onClick={() => {
                setRemovingTakeId(take.id);
                setRemoveAlertOpen(true);
              }}
              label="Remove"
              style="danger"
              className="ml-2"
            />
          )}
        </div>
      </div>

      <Modal
        isOpen={removeAlertOpen}
        onClose={() => setRemoveAlertOpen(false)}
        className="pb-alert"
      >
        <Modal.Header>
          <h4>Remove Take</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <p>Are you sure you want to remove this take?</p>
        </Modal.Body>
        <Modal.Footer className="flex gap-x-2">
          <ModalButton
            label="Yes, Proceed"
            type="submit"
            style="danger"
            loading={btnLoader}
            onClick={() => updateTakeResponse()}
          />

          <ModalButton
            label="Cancel"
            onClick={() => setRemoveAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TakeCard;
