import axios from "axios";

export const getPaidBooths = (pageIndex) => {
  return axios.get(`/api/v1/settings/paid_booths?page=${pageIndex}`);
};

export const updateStripeDetails = (subscriptionId) => {
  return axios.put(
    `/api/v1/settings/paid_booths/${subscriptionId}/update_stripe_details`,
    {},
  );
};

export const cancelStripeSubscription = (subscriptionId) => {
  return axios.delete(`/api/v1/settings/paid_booths/${subscriptionId}/cancel`);
};

export const destroySubscription = (subscriptionId) => {
  return axios.delete(`/api/v1/settings/paid_booths/${subscriptionId}`);
};
