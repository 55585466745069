import React, { useState, useEffect } from "react";
import { Toastr } from "@bigbinary/neetoui";

import { createAttachment } from "@/apis/settings/attachments";
import { showToastrError } from "@/components/commons";

const ImageUploader = ({
  type,
  position,
  setPageLoader,
  getTakeListResponse,
}) => {
  const [uploaded, setUploaded] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();

  useEffect(() => {
    if (uploaded) {
      createAttachmentResponse();
    }
  }, [uploadedImage]);

  const handleChange = (event) => {
    const file = event.target.files[0];
    let reader = new FileReader();

    reader.onloadend = (e) => {
      setUploadedImage({
        file_data: e.target.result,
        file_name: file.name,
        position: position,
        caption: file.name,
        attachable_kind: type,
      });
    };
    reader.readAsDataURL(file);
    setUploaded(true);
  };

  const createAttachmentResponse = async () => {
    try {
      setPageLoader(true);
      await createAttachment({
        attachment: {
          file_data: uploadedImage.file_data,
          file_name: uploadedImage.file_name,
          attachable_kind: uploadedImage.attachable_kind,
          caption: uploadedImage.caption,
          position: uploadedImage.position,
        },
      });
      setUploaded(false);
      Toastr.success("Cover image has been successfully created.");
      setUploadedImage({});
      await getTakeListResponse([], 1, true);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setPageLoader(false);
    }
  };

  return (
    <input
      type="file"
      accept="image/jpg, image/jpeg, image/gif, image/png"
      className="pb-settings--chrome-window--body--uploader-input"
      onChange={(event) => handleChange(event)}
    />
  );
};

export default ImageUploader;
