import React from "react";
import { Edit, Delete, MenuVertical } from "@bigbinary/neeto-icons";
import { Toastr, Pagination, Dropdown } from "@bigbinary/neetoui";
import { DEFAULT_PAGE_SIZE } from "@/lib/constants";

const Table = ({
  disciplineList,
  totalRecords,
  setPageIndex,
  pageIndex,
  getDisciplineResponse,
  setDisciplineId,
  setDestroyDisciplineAlertOpen,
}) => {
  const { Menu, MenuItem } = Dropdown;

  return (
    <>
      <div className="pb-app--table-wrapper w-full flex flex-col rounded-lg bg-white px-8 py-6">
        <table className="pb-app--table">
          <thead>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {disciplineList.map((discipline) => {
              return (
                <tr key={discipline.id}>
                  <td>{discipline.name}</td>
                  <td>
                    <div className="flex justify-end">
                      <Dropdown
                        customTarget={
                          <div className="pb-app--table--menu-icon cursor-pointer bg-white p-1">
                            <MenuVertical size={16} />
                          </div>
                        }
                        onClose={function noRefCheck() {}}
                      >
                        <Menu>
                          <MenuItem.Button
                            className="flex items-center gap-x-3"
                            onClick={() => {
                              getDisciplineResponse(discipline.id);
                            }}
                          >
                            <Edit size={16} />
                            <span className="ml-2">Edit</span>
                          </MenuItem.Button>
                          <MenuItem.Button
                            className="flex items-center gap-x-3"
                            onClick={() => {
                              setDisciplineId(discipline.id);
                              setDestroyDisciplineAlertOpen(true);
                            }}
                          >
                            <Delete size={16} />
                            <span>Delete</span>
                          </MenuItem.Button>
                        </Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="pb-app--pagination-container">
          <Pagination
            count={totalRecords}
            navigate={(page) => {
              setPageIndex(page);
            }}
            pageNo={pageIndex}
            pageSize={DEFAULT_PAGE_SIZE}
          />
        </div>
      </div>
    </>
  );
};

export default Table;
