import React, { useState, useEffect } from "react";
import { Spinner, Select, Toastr } from "@bigbinary/neetoui";
import { ExternalLink } from "@bigbinary/neeto-icons";
import queryString from "query-string";
import CurrencyList from "currency-list";

import AnimatedPage from "@/components/commons/AnimatedPage";
import {
  Input,
  Button,
  Switch,
  Textarea,
} from "@/components/commons/SettingsFormUI";
import { LinkButton } from "@/components/commons/AppFormUI";
import { showToastrError } from "@/components/commons";
import {
  getPaidSubscription,
  createStripeAccount,
} from "@/apis/settings/paid_subscriptions";

import { updateUserPlan } from "@/apis/settings/user_plans";
import { updateUser } from "@/apis/users";
import { useAuthContext } from "@/lib/useAuthContext";
import UpgradeModal from "./UpgradeModal";

const PiadSubscription = () => {
  const { user } = useAuthContext();
  const [pageLoader, setPageLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [paidSubscription, setPaidSubscription] = useState();
  const [freeUserPlan, setFreeUserPlan] = useState();
  const [monthlyUserPlan, setMonthlyUserPlan] = useState();
  const [yearlyUserPlan, setYearlyUserPlan] = useState();

  const CURRENCY_OPTIONS = Object.keys(CurrencyList.getAll("en_US")).map(
    (currency) => {
      return { label: currency, value: currency };
    },
  );

  useEffect(() => {
    getPaidSubscriptionResponse();

    const queryParams = queryString.parse(location.search);

    if (queryParams.success && queryParams.show_plans !== "true") {
      Toastr.success(
        "Thanks for upgrading to a paid plan. Now, you can proceed to connect your account to stripe connect.",
      );
    }

    if (queryParams.failure && queryParams.show_plans) {
      setModalOpen(true);
      showToastrError("Payment has been failed. Please try again.");
    }
  }, []);

  const getPaidSubscriptionResponse = async () => {
    try {
      const response = await getPaidSubscription();
      setPaidSubscription(response.data.paid_subscription);

      response.data.paid_subscription.user_plans.map((plan) => {
        if (plan.interval === "free") {
          setFreeUserPlan(plan);
        } else if (plan.interval === "monthly") {
          setMonthlyUserPlan(plan);
        } else if (plan.interval === "yearly") {
          setYearlyUserPlan(plan);
        }
      });

      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createStripeAccountResponse = async () => {
    try {
      setBtnLoader(true);
      const response = await createStripeAccount();
      window.location.href = response.data.connect_url;
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateUserResponse = async (isEnabled) => {
    try {
      await updateUser(user.id, {
        user: { turn_on_paid: isEnabled },
      });
      await getPaidSubscriptionResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createStripePriceResponse = async () => {
    try {
      setBtnLoader(true);
      const payload = {
        user_plan: {
          custom_subscription_message:
            paidSubscription.custom_subscription_message,
          user_plans_attributes: [
            freeUserPlan,
            monthlyUserPlan,
            yearlyUserPlan,
          ],
        },
      };
      await updateUserPlan(user.id, payload);
      await getPaidSubscriptionResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const onValueChange = (value, index, plan, setPlan) => {
    const updatedBenefits = plan.benefits.map((item, i) => {
      if (i === index) {
        return value;
      } else {
        return item;
      }
    });

    setPlan({ ...plan, benefits: updatedBenefits });
  };

  if (pageLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-settings--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-settings--layout flex flex-col w-full">
        <h2 className="font-soehne-kraftig pb-9 text-[24px] font-medium leading-snug border-b border-gray-300 lg:text-[32px]">
          Paid subscriptions
        </h2>

        <div className="flex flex-col py-9 gap-9">
          {!paidSubscription.has_paid_plan && (
            <Button
              label="Upgrade to use this feature"
              className="!w-fit"
              onClick={() => setModalOpen(true)}
            />
          )}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            <div className="col-span-1 flex flex-col space-y-2 mb-2 md:mb-0">
              <label className="text-sm uppercase block">
                ENABLE PAID SUBSCRIPTIONS
              </label>
              <span className="block text-sm leading-5 text-lightBlack opacity-60">
                Allow your subscribers to pay for extra content.
              </span>
            </div>

            <div className="col-span-2 flex flex-col w-full lg:w-8/12">
              <Switch
                checked={paidSubscription.turn_on_paid}
                onChange={() => {
                  updateUserResponse(!paidSubscription.turn_on_paid);
                }}
                disabled={!paidSubscription.has_paid_plan}
              />
            </div>
          </div>

          {paidSubscription.turn_on_paid && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pb-9 border-b border-gray-300">
                <div className="col-span-1 flex flex-col space-y-2 mb-2 md:mb-0">
                  <label className="text-sm uppercase block">
                    STRIPE INTEGRATION
                  </label>

                  {!paidSubscription.stripe_account && (
                    <span className="block text-sm leading-5 text-lightBlack opacity-60">
                      Connect your Stripe account to begin taking payments.
                    </span>
                  )}
                </div>

                <div className="col-span-2 flex flex-col w-full lg:w-8/12">
                  {!paidSubscription.stripe_account ? (
                    <Button
                      label="Connect your stripe account"
                      className="!w-fit"
                      loading={btnLoader}
                      onClick={() => createStripeAccountResponse()}
                    />
                  ) : paidSubscription.stripe_account.active ? (
                    <div className="flex flex-col space-y-6">
                      <p className="text-sm leading-5">
                        Your stripe account is connected.
                      </p>

                      <p className="text-sm font-semibold leading-5">
                        {paidSubscription.stripe_email}
                      </p>

                      <LinkButton
                        label="View stripe account"
                        to="https://dashboard.stripe.com/login"
                        target="_blank"
                        icon={<ExternalLink className="ml-2" />}
                        style="onlyLink"
                        className="border border-solid border-black !w-fit !shadow-none"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-6">
                      <p className="text-sm leading-5">
                        Your stripe account onboarding has not been completed
                        yet.
                      </p>
                      <p>
                        If you have completed your Stripe onboarding and you
                        still see the ‘resume onboarding’ button, please verify
                        your email and then refresh this page or hit the ‘view
                        Stripe account’ button for more information. For new
                        accounts this can sometimes take between 5 and 10
                        minutes.
                      </p>

                      <p className="text-sm font-semibold leading-5">
                        {paidSubscription.stripe_email}
                      </p>

                      <div className="flex flex-col md:flex-row gap-3">
                        <Button
                          loading={btnLoader}
                          label="Resume Onboarding"
                          onClick={() => createStripeAccountResponse()}
                          className="!w-fit"
                        />

                        <LinkButton
                          label="View stripe account"
                          to="https://dashboard.stripe.com/login"
                          target="_blank"
                          icon={<ExternalLink className="ml-2" />}
                          style="onlyLink"
                          className="border border-solid border-black !w-fit !shadow-none"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {paidSubscription.stripe_account?.active && (
                <>
                  <div className="flex flex-col space-y-12 py-9 border-b border-gray-300">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                      <div className="col-span-1 flex flex-col space-y-2 mb-2 md:mb-0">
                        <label className="text-sm uppercase block">
                          PLAN PRICING
                        </label>

                        <span className="block text-sm leading-5 text-lightBlack opacity-60">
                          Your subscribers will have the opportunity to sign up
                          for a monthly or yearly subscription.
                        </span>
                      </div>

                      <div className="col-span-2 flex flex-col w-full lg:w-8/12">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="flex flex-col space-y-2">
                            <label className="text-sm">
                              Monthly Subscription
                            </label>

                            <div className="grid grid-cols-2 gap-2">
                              <Input
                                id="monthly_price"
                                name="monthly_price"
                                placeholder="AMOUNT"
                                value={monthlyUserPlan.unit_amount}
                                onChange={(e) =>
                                  setMonthlyUserPlan({
                                    ...monthlyUserPlan,
                                    unit_amount: e.target.value,
                                  })
                                }
                              />

                              <Select
                                cacheOptions
                                name="monthly_currency"
                                id="monthly_currency"
                                placeholder="CURRENCY"
                                className="currency-select"
                                value={CURRENCY_OPTIONS.find(
                                  (currency) =>
                                    monthlyUserPlan.currency === currency.value,
                                )}
                                onChange={(opt) =>
                                  setMonthlyUserPlan({
                                    ...monthlyUserPlan,
                                    currency: opt.value,
                                  })
                                }
                                options={CURRENCY_OPTIONS}
                              />
                            </div>
                          </div>

                          <div className="flex flex-col space-y-2">
                            <label className="text-sm">
                              Yearly Subscription
                            </label>

                            <div className="grid grid-cols-2 gap-2">
                              <Input
                                id="yearly_price"
                                name="yearly_price"
                                placeholder="AMOUNT"
                                value={yearlyUserPlan.unit_amount}
                                onChange={(e) =>
                                  setYearlyUserPlan({
                                    ...yearlyUserPlan,
                                    unit_amount: e.target.value,
                                  })
                                }
                              />

                              <Select
                                cacheOptions
                                name="yearly_currency"
                                id="yearly_currency"
                                placeholder="CURRENCY"
                                className="currency-select"
                                value={CURRENCY_OPTIONS.find(
                                  (currency) =>
                                    yearlyUserPlan.currency === currency.value,
                                )}
                                onChange={(opt) =>
                                  setYearlyUserPlan({
                                    ...yearlyUserPlan,
                                    currency: opt.value,
                                  })
                                }
                                options={CURRENCY_OPTIONS}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-12 py-9 border-b border-gray-300">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                      <div className="col-span-1 flex flex-col space-y-2 mb-2 md:mb-0">
                        <label className="text-sm uppercase block">
                          CUSTOM SUBSCRIPTION MESSAGE
                        </label>

                        <span className="block text-sm leading-5 text-lightBlack opacity-60">
                          Your subscribers will see this when they are prompted
                          to subscribe.
                        </span>
                      </div>

                      <div className="col-span-2 flex flex-col w-full lg:w-8/12">
                        <Textarea
                          id="subscription_message"
                          name="subscription_message"
                          placeholder="Enter your message"
                          rows={4}
                          value={paidSubscription.custom_subscription_message}
                          onChange={(e) =>
                            setPaidSubscription({
                              ...paidSubscription,
                              custom_subscription_message: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-12 py-9 border-b border-gray-300">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                      <div className="col-span-1 flex flex-col space-y-2 mb-2 md:mb-0">
                        <label className="text-sm uppercase block">
                          SUBSCRIPTION BENEFITS
                        </label>

                        <span className="block text-sm leading-5 text-lightBlack opacity-60">
                          Let your subscribers know what they will get by
                          signing up to your PencilBooth.
                        </span>
                      </div>

                      <div className="col-span-2 flex flex-col w-full lg:w-8/12">
                        <div className="flex flex-col w-full space-y-6">
                          <div className="flex flex-col w-full space-y-2">
                            <label className="text-sm">
                              Free subscription benefits
                            </label>

                            {freeUserPlan.benefits.map((benefit, index) => {
                              return (
                                <Input
                                  key={index}
                                  id={`free_benefit_${index + 1}`}
                                  name={`free_benefit_${index + 1}`}
                                  placeholder="Enter a benefit"
                                  value={benefit}
                                  onChange={(e) =>
                                    onValueChange(
                                      e.target.value,
                                      index,
                                      freeUserPlan,
                                      setFreeUserPlan,
                                    )
                                  }
                                />
                              );
                            })}
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label className="text-sm">
                              Monthly subscription benefits
                            </label>

                            {monthlyUserPlan.benefits.map((benefit, index) => {
                              return (
                                <Input
                                  key={index}
                                  id={`monthly_benefit_${index + 1}`}
                                  name={`monthly_benefit_${index + 1}`}
                                  placeholder="Enter a benefit"
                                  value={benefit}
                                  onChange={(e) =>
                                    onValueChange(
                                      e.target.value,
                                      index,
                                      monthlyUserPlan,
                                      setMonthlyUserPlan,
                                    )
                                  }
                                />
                              );
                            })}
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label className="text-sm">
                              Yearly subscription benefits
                            </label>

                            {yearlyUserPlan.benefits.map((benefit, index) => {
                              return (
                                <Input
                                  key={index}
                                  id={`yearly_benefit_${index + 1}`}
                                  name={`yearly_benefit_${index}`}
                                  placeholder="Enter a benefit"
                                  value={benefit}
                                  onChange={(e) =>
                                    onValueChange(
                                      e.target.value,
                                      index,
                                      yearlyUserPlan,
                                      setYearlyUserPlan,
                                    )
                                  }
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-end py-9">
                    <Button
                      type="submit"
                      label="Update"
                      loading={btnLoader}
                      onClick={() => createStripePriceResponse()}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </AnimatedPage>

      {!paidSubscription.has_paid_plan && (
        <UpgradeModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          stripePlanList={paidSubscription?.stripe_plans || []}
        />
      )}
    </div>
  );
};

export default PiadSubscription;
