import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import classnames from "classnames";
import { Popover } from "antd";
import { Loading } from "@bigbinary/neeto-icons";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  PasteCleanup,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

import {
  MoveUpIcon,
  MoveDownIcon,
  TrashIcon,
  LinkIcon,
  DisableMoveDownIcon,
  DisableMoveUpIcon,
} from "@/components/commons/PBIcons";

import { Button } from "@/components/commons/AppFormUI";
import {
  getLinkRollsPreview,
  updateLinkRoll,
  destroyLinkRoll,
  reorderLinkRoll,
} from "@/apis/link_rolls";
import { isValidUrl, withoutProtocol } from "@/lib/utils";
import { showToastrError } from "@/components/commons";
import {
  RICH_TEXT_EDITOR_INLINE_MODE,
  RICH_TEXT_EDITOR_TOOLBAR,
  PASTE_CLEANUP_STYLES,
} from "./../../constants";
import { LinkRollCard as ClientSpecificLinkRollCard } from "../../../Detail/LinkRollCard";

const LinkRollCard = ({ formik, linkRoll }) => {
  const linkRollRef = useRef(null);
  const [clicked, setClicked] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [captionText, setCaptionText] = useState("");
  const [newLink, setNewLink] = useState();
  const [btnLoader, setBtnLoader] = useState(false);
  const [initial, setInitial] = useState(true);
  const [editPopover, setEditPopover] = useState(false);
  const [deletePopover, setDeletePopover] = useState(false);
  const [linkError, setLinkError] = useState();

  useEffect(() => {
    if (linkRollRef.current !== null) {
      const handleClick = (event) => {
        if (!linkRollRef.current.contains(event.target)) {
          setClicked(false);
        }
      };
      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [linkRollRef]);

  useEffect(() => {
    if (initial) {
      setTitleText(linkRoll.title);
      setCaptionText(linkRoll.caption);
      setNewLink(linkRoll.link);
    }
  }, [linkRoll]);

  const updateLinkRollResponse = async () => {
    try {
      const { data } = await updateLinkRoll(formik.values.id, linkRoll.id, {
        link_roll: {
          caption: captionText,
          title: titleText,
        },
      });
      setInitial(false);
      formik.setFieldValue("link_rolls_attributes", data.link_rolls);
      setEditPopover(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const destroyLinkRollResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await destroyLinkRoll(formik.values.id, linkRoll.id);
      formik.setFieldValue("link_rolls_attributes", data.link_rolls);
      setDeletePopover(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const reorderLinkRollResponse = async (reorderType) => {
    try {
      const { data } = await reorderLinkRoll(
        formik.values.id,
        linkRoll.id,
        reorderType,
      );
      formik.setFieldValue("link_rolls_attributes", data.link_rolls);
      setInitial(true);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const handleLinkPreviewFetch = () => {
    let website = newLink;

    if (isValidUrl(website)) {
      if (withoutProtocol(website)) {
        website = `http://${website}`;
      }

      getLinkRollsPreviewResponse(website);
    }
  };

  const getLinkRollsPreviewResponse = async (website) => {
    try {
      setBtnLoader(true);

      const {
        data: { link_rolls_preview },
      } = await getLinkRollsPreview(formik.values.id, website);

      let payload = {
        link: link_rolls_preview.link,
        title: link_rolls_preview.title,
        description: link_rolls_preview.description,
        image_src: link_rolls_preview.image_src,
        username: link_rolls_preview.username,
        instagram_post: link_rolls_preview.instagram_post,
        twitter_post: link_rolls_preview.twitter_post,
      };

      const { data } = await updateLinkRoll(formik.values.id, linkRoll.id, {
        link_roll: payload,
      });

      formik.setFieldValue("link_rolls_attributes", data.link_rolls);
      setEditPopover(false);
    } catch (error) {
      setNewLink(linkRoll.link);
      showToastrError(error.response.data.message);
    } finally {
      setBtnLoader(false);
    }
  };

  const hasContent = () => {
    return titleText?.length > 0 || captionText?.length > 0 || clicked;
  };

  return (
    <div
      className="flex flex-col w-full max-w-[464px] relative mb-8"
      ref={linkRollRef}
    >
      {linkRoll.loading ? (
        <div className="flex justify-center items-center w-full">
          <div className="flex justify-center items-center w-full max-w-[464px] h-[156px] px-3.5 py-4 bg-[#F2F2F2] rounded-xl">
            <Loading size={30} className="animate-spin-slow" />
          </div>
        </div>
      ) : (
        <>
          <div
            className={classnames(
              "pb-linkroll-image cursor-pointer relative rounded-lg",
              {
                "pb-linkroll-image--selected": clicked,
              },
            )}
            onClick={() => setClicked(true)}
          >
            <ClientSpecificLinkRollCard
              linkRoll={linkRoll}
              withLink={false}
              showDetails={false}
            />

            {linkRoll.id && (
              <div className="absolute right-6 top-6">
                <div className="flex items-center px-2 py-1 bg-white rounded-[32px]">
                  <Popover
                    content={
                      <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                        <div className="flex flex-col space-y-2 w-full">
                          <span className="text-[14px]">Link</span>
                          <input
                            value={newLink}
                            onChange={(e) => {
                              setNewLink(e.target.value);
                            }}
                            className="pb-take-popover-input"
                            placeholder="Enter a link"
                          />
                          {linkError?.length > 0 && (
                            <p className="text-sm text-[#CB4446]">
                              {linkError}
                            </p>
                          )}
                        </div>

                        <div className="flex justify-end space-x-4">
                          <Button
                            label="Cancel"
                            className="!w-fit"
                            onClick={() => setEditPopover(false)}
                            style="link"
                          />

                          <Button
                            label="Insert"
                            loading={btnLoader}
                            className="!w-fit"
                            type="submit"
                            onClick={() => {
                              if (!isValidUrl(newLink)) {
                                setLinkError("Link is not valid.");
                              } else {
                                setLinkError("");
                                handleLinkPreviewFetch();
                              }
                            }}
                          />
                        </div>
                      </div>
                    }
                    trigger="click"
                    open={editPopover}
                    placement="bottom"
                    overlayClassName="w-[350px]"
                  >
                    <button
                      className="cursor-pointer px-2 py-1 pb-menu-border"
                      onClick={() => {
                        setEditPopover(true);
                        setDeletePopover(false);
                      }}
                    >
                      <LinkIcon />
                    </button>
                  </Popover>

                  <Popover
                    content={
                      <div className="flex flex-col space-y-4 w-full font-soehne-mono">
                        <div className="flex flex-col space-y-2 w-full">
                          <span className="text-[14px]">Delete Link Roll</span>
                          <p>Are you sure you want to delete the link roll?</p>
                        </div>

                        <div className="flex justify-end space-x-4">
                          <Button
                            label="Cancel"
                            className="!w-fit"
                            onClick={() => setDeletePopover(false)}
                            style="link"
                          />

                          <Button
                            label="Yes"
                            loading={btnLoader}
                            className="!w-fit"
                            type="submit"
                            onClick={() => destroyLinkRollResponse()}
                          />
                        </div>
                      </div>
                    }
                    trigger="click"
                    open={deletePopover}
                    placement="bottom"
                    overlayClassName="w-[350px]"
                  >
                    <button
                      className="cursor-pointer px-2 py-1 pb-menu-border"
                      onClick={() => {
                        setEditPopover(false);
                        setDeletePopover(true);
                      }}
                    >
                      <TrashIcon />
                    </button>
                  </Popover>

                  <button
                    className={`px-2 py-1 pb-menu-border ${linkRoll.in_top_position ? "cursor-not-allowed" : "cursor-pointer"}`}
                    onClick={() => {
                      if (!linkRoll.in_top_position) {
                        reorderLinkRollResponse("move_up");
                        setEditPopover(false);
                        setDeletePopover(false);
                      }
                    }}
                  >
                    {linkRoll.in_top_position ? (
                      <DisableMoveUpIcon />
                    ) : (
                      <MoveUpIcon />
                    )}
                  </button>

                  <button
                    className={`px-2 py-1 ${linkRoll.in_bottom_position ? "cursor-not-allowed" : "cursor-pointer"}`}
                    onClick={() => {
                      if (!linkRoll.in_bottom_position) {
                        reorderLinkRollResponse("move_down");
                        setEditPopover(false);
                        setDeletePopover(false);
                      }
                    }}
                  >
                    {linkRoll.in_bottom_position ? (
                      <DisableMoveDownIcon />
                    ) : (
                      <MoveDownIcon />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>

          <motion.div
            animate={{
              opacity: 1,
              height: hasContent() ? "auto" : 0,
              overflow: hasContent() ? "visible" : "hidden",
            }}
            exit={{ opacity: 0, height: 0 }}
            initial={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="flex flex-col space-y-3 w-full mt-6">
              <input
                value={titleText}
                onChange={(e) => setTitleText(e.target.value)}
                className="pb-take-title-input"
                placeholder="Give a title"
                onBlur={() => updateLinkRollResponse()}
              />

              <RichTextEditorComponent
                id={`link-roll-caption-editior-${linkRoll.id}`}
                inlineMode={RICH_TEXT_EDITOR_INLINE_MODE}
                toolbarSettings={RICH_TEXT_EDITOR_TOOLBAR}
                pasteCleanupSettings={PASTE_CLEANUP_STYLES}
                onBlur={() => updateLinkRollResponse()}
                change={(e) => setCaptionText(e.value)}
                value={captionText}
                placeholder="Write something about your link (optional)"
              >
                <Inject
                  services={[
                    Image,
                    Link,
                    QuickToolbar,
                    HtmlEditor,
                    Toolbar,
                    PasteCleanup,
                  ]}
                />
              </RichTextEditorComponent>
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default LinkRollCard;
