import axios from "axios";

export const getAttachment = (takeId, attachmentId) => {
  return axios.get(`/api/v1/takes/${takeId}/attachments/${attachmentId}`);
};

export const createAttachment = (takeId, payload, { onUploadProgress }) => {
  const config = {
    onUploadProgress: (progressEvent) => {
      const progress = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total),
      );

      if (progress == 100) {
        onUploadProgress(90);
      } else {
        onUploadProgress(progress);
      }
    },
  };
  return axios.post(`/api/v1/takes/${takeId}/attachments`, payload, config);
};

export const updateAttachment = (takeId, attachmentId, payload) => {
  return axios.put(
    `/api/v1/takes/${takeId}/attachments/${attachmentId}`,
    payload,
  );
};

export const destroyAttachment = (takeId, attachmentId) => {
  return axios.delete(`/api/v1/takes/${takeId}/attachments/${attachmentId}`);
};

export const reorderAttachment = (takeId, attachmentId, reorderType) => {
  return axios.get(
    `/api/v1/takes/${takeId}/attachments/${attachmentId}/reorder?reorder_type=${reorderType}`,
  );
};
