import React from "react";

import { LinkButton } from "@/components/commons/HomeFormUI";

import EmailIcon from "@/images/icons/email-icon.svg";

const EmptyPage = () => {
  return (
    <div className="flex flex-col items-center max-w-[1024px] w-full bg-white rounded-2xl p-6 md:p-10 space-y-6">
      <img src={EmailIcon} height={24} width={24} alt="email icon" />

      <p className="text-[16px] leading-[19.09px] font-soehne-kraftig m-0 text-center">
        There’s nothing here, create your first take now.
      </p>

      <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0">
        <LinkButton
          to="/takes/new"
          label="Create a take"
          className="order-1 md:order-2 md:ml-3"
        />

        <LinkButton
          to="/audience"
          label="Manage your audience"
          style="secondary"
          className="!border !border-[#969797] order-2 md:order-1 md:mr-3"
        />
      </div>
    </div>
  );
};

export default EmptyPage;
