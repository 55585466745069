import React from "react";

import { Link } from "react-router-dom";

import Accordion from "@/components/commons/Accordion";

const FAQs = () => (
  <div className="flex flex-col items-center w-full gap-y-[60px]">
    <h2 className="font-soehne-kraftig text-[48px] font-medium leading-[64px]">
      FAQs
    </h2>
    <Accordion className="flex flex-col w-full gap-y-4">
      <Accordion.Item title="What exactly is PencilBooth?">
        Pencilbooth is an email newsletter service which has been made
        specifically for visual artists and other creative types. Fed up by
        systems and social media platforms that don’t allow work to be seen by
        the people who actually want to see it, PencilBooth gets your stuff
        directly in front of your clients and followers directly by putting it
        straight in their email inbox without any middlemen or algorithms.
        <br />
        <br />
        While most other newsletter platforms cater for writers or content
        creators, PencilBooth takes a visuals-first approach to the newsletter
        ecosystem, giving you an easy way to share your work as simply and
        elegantly as possible with those who want to see it, while also allowing
        for discovery by new potential clients and peers.
        <br />
        <br />
        Each newsletter in PencilBooth is called a Take, and consists of a
        series of images with optional headlines, captions and outlinks. In your
        B-roll section, you can also add links to other things you’re
        reading/watching/loving and have it be automatically converted into a
        visual nugget to bookend yourTtake.
        <br />
        <br />
        Images from your Takes are saved to your visual archive and also
        published to our homepage.
        <br />
        <br />
        Your Booth is your home base, where you can sign up new subscribers and
        also customise it to your heart’s content and use it as a link in bio on
        your social platforms as a one-stop shop.
        <br />
        <br />
        PencilBooth is free forever for your first 100 true fans. Claim your
        booth now and check it out!
      </Accordion.Item>
      <Accordion.Item title="How do I set up my Booth and send my first take?">
        Lucky for you, we’ve written this handy guide{" "}
        <Link
          to="https://scribehow.com/shared/Getting_started_with_PencilBooth__RkUVRr_0SwqwrnnM4tp-Tw"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          here
        </Link>
        . Go read it! It takes four minutes and you'll be up and running.
      </Accordion.Item>
      <Accordion.Item title="Is it just for artists?">
        Artists are who we know and what we know, but that doesn’t mean it can’t
        be used by anyone and everyone. In fact, we’re really interested to see
        what other folks might do with this!
      </Accordion.Item>
      <Accordion.Item title="Does it cost anything?">
        PencilBooth is free for your first 100 true fans. Our first plan from
        there starts at 9.00 a month. Plans go up from there depending on how
        many subscribers you have or what features you want.
        <br />
        <br />
        We’re not a VC backed business looking for an exit, nor do we have any
        investors to satisfy. We made this as something we wanted to use
        ourselves and it seems like a whole lot of other people like it as well
        which is great.
        <br />
        <br />
        Your subscription fees pay for the hard costs of actually sending emails
        as well as covering our development fees for maintenance and new
        features.
      </Accordion.Item>
      <Accordion.Item title="What is the Visual Archive?">
        All images from your Takes will be automatically posted to your visual
        archive, a grid view to explore all your previous takes. It’s a great
        way to give subscribers a birds-eye view of what kind of stuff you’ll be
        sending them. Your images will also get cross-posted to the discover
        feed on our homepage, giving you a new avenue to get discovered by
        potential clients and peers.
        <br />
        <br />
        If you don’t want your images or Takes appearing on the archive or home
        page, you can easily opt-out when composing them.
      </Accordion.Item>
      <Accordion.Item title="I have a feature request / something is broken / can I just speak to someone please?">
        If you’re a current user, just drop us a line in our Slack group. The
        invite link will be in your welcome email. You can also DM us on
        Instagram, ping us on Threads, or Jizz us on Smackdog. For everything
        else, just email us at{" "}
        <Link
          to="mailto:team@pencilbooth.com"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          team@pencilbooth.com
        </Link>
        !
      </Accordion.Item>
      <Accordion.Item title="Who made this?">
        PencilBooth is a venture from{" "}
        <Link
          to="https://jackywinter.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          The Jacky Winter Group
        </Link>
        , a creative representation and production agency with offices in
        Australia, the US, and the UK. We have been in business for over
        eighteen years and we know a thing or two about promoting creative
        people and getting their work seen. PencilBooth was originally designed
        as a custom app to promote our own artists to our clients and to try to
        solve our own biggest challenge of continually shouting into the abyss
        of social media, but we figured it had more potential in the hands of
        artists themselves, so here we are! <br />
        <br />
        You can find out more about us at{" "}
        <Link
          to="https://jackywinter.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          jackywinter.com
        </Link>{" "}
        or hey, maybe{" "}
        <Link
          to="https://www.pencilbooth.com/jackywinter"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          subscribe to our Pencilbooth
        </Link>{" "}
        to find out more!
      </Accordion.Item>
      <Accordion.Item title="Can I import my subscribers from another app?">
        Yup!
      </Accordion.Item>
      <Accordion.Item title="Can I have some more technical information on how to prepare my images for my booth or my takes?">
        For your Booth background we suggest a landscape image that doesn't have
        a central focus (as it will be covered up by your sign-up box). When
        creating your Booth you will be able to preview both mobile and desktop
        versions to see how it will display.
        <br />
        <br />
        Your avatar should be a square ratio. The larger the file-size, the
        longer your booth will take to load, so we suggest optimizing your image
        before uploading.
        <br />
        <br />
        Animated gifs are supported in both booths and takes.
        <br />
        <br />
        Images and gifs in takes must be under 5mb. There are no required pixel
        dimensions. PencilBooth supports square, landscape or portrait formats
        and will never crop your images.
        <br />
        <br />
        There is no limit on pixel dimensions, however we suggest a minimum
        width of 800px if possible.
      </Accordion.Item>
      <Accordion.Item title="Can I take my subscribers with me if it's not for me?">
        Yup! You can export your subscribers anytime and take them to another
        platform. They are your audience, not ours.
      </Accordion.Item>
      <Accordion.Item title="Can I trust you with my data?">
        Yes. We take your data and privacy very seriously. Our whole business is
        built on this foundation and we have a pretty good track record in that
        area. If you want to get into the nitty gritty, check out our privacy
        policy{" "}
        <Link
          to="/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          here
        </Link>
        .
      </Accordion.Item>
      <Accordion.Item title="What is the first noble truth?">
        There is no relief or relaxation when we are in the samsaric world;
        there is always some kind of struggle going on. Even when we are
        supposedly enjoying life, there is still struggle and all kinds of
        discomfort. We may try to solve that problem by going out to restaurants
        or the cinema, or by enjoying our friends; nonetheless, nothing really
        helps. That is what is called the first noble truth, the truth of
        suffering. Seemingly we are trapped without hope or any way out. And
        once we are in that situation, we are always in that situation: we are
        in pain all the time…the more we understand our state of being, the more
        we will understand why we are in pain. What we find is that the more
        into ourselves we are, the more we suffer, and the less into ourselves
        we are, the less we suffer…the reality is that we are all trapped in
        samsaric neurosis, everybody, without exception. It is best that you
        work with reality rather than with ideals. That is a good place to
        begin.
        <br />
        <br />
        —Chögyam Trungpa Rinpoche
      </Accordion.Item>
    </Accordion>
  </div>
);

export default FAQs;
