import React, { useState } from "react";
import { Search, Close } from "@bigbinary/neeto-icons";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

import { useAuthContext } from "@/lib/useAuthContext";
import PencilBoothLogo from "@/images/pencilbooth-logo.svg";
import Hamburger from "@/images/icons/hamburger-icon.svg";

const getClassNames = (isActive, mobileMenu, primaryBtn) => {
  return classnames("pb-home--nav-btn", {
    "!bg-opacity-70": !isActive,
    "px-4 py-2": !mobileMenu,
    "px-6 py-3": mobileMenu,
    "pb-home--nav-btn--primary": primaryBtn,
  });
};

const NavItem = ({ label, to, mobileMenu, primaryBtn }) => {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        getClassNames(isActive, mobileMenu, primaryBtn)
      }
      to={to}
      onClick={() => document.body.classList.remove("!overflow-hidden")}
    >
      {label}
    </NavLink>
  );
};

const Header = ({ hideSearch = false, bgYellow = false }) => {
  const { setSearch } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={classnames("w-full px-6 py-5 lg:py-8", {
        "bg-primary": bgYellow,
        "bg-secondary": !bgYellow,
      })}
    >
      <div className="relative grid w-full grid-cols-1 lg:grid-cols-3">
        <div
          className="absolute left-0 top-0 flex items-center justify-center lg:hidden"
          onClick={() => {
            toggleMenu();
            document.body.classList.add("!overflow-hidden");
          }}
        >
          <img src={Hamburger} />
        </div>
        <div className="hidden items-center gap-x-2 lg:flex">
          <NavItem label="About" to="/about" />
          {!hideSearch && (
            <div
              className="flex cursor-pointer gap-x-2 pb-home--nav-btn !bg-opacity-70 px-4 py-2"
              onClick={() => {
                setSearch(true);
                document.body.classList.add("!overflow-hidden");
              }}
            >
              <Search size={16} />
              <span>Search</span>
            </div>
          )}
        </div>
        <NavLink className="justify-self-center" to="/">
          <img
            alt="Pencilbooth Logo"
            className="h-6 w-auto lg:h-8"
            src={PencilBoothLogo}
          />
        </NavLink>
        <div className="hidden items-center gap-x-2 lg:flex justify-end">
          <NavItem label="Login" to="/login" />
          <NavItem label="Sign Up" to="/signup" primaryBtn />
        </div>
        {!hideSearch && (
          <div
            className="absolute right-0 top-0 flex items-center justify-center lg:hidden"
            onClick={() => {
              setSearch(true);
              document.body.classList.add("!overflow-hidden");
            }}
          >
            <Search size={24} />
          </div>
        )}
      </div>

      {isOpen && (
        <div className="pb-home--mobile-nav-container absolute left-0 top-0 h-full w-full bg-primary z-10">
          <div
            className="absolute left-6 top-6 flex items-center justify-center"
            onClick={() => {
              toggleMenu();
              document.body.classList.remove("!overflow-hidden");
            }}
          >
            <Close size={24} />
          </div>

          <div className="flex justify-center w-full px-6 py-5">
            <NavLink to="/">
              <img
                alt="PencilBooth Logo"
                className="h-6 w-auto lg:h-8"
                src={PencilBoothLogo}
              />
            </NavLink>
          </div>

          <div className="flex flex-col items-center justify-center gap-y-4 h-full">
            <NavItem label="About" to="/about" mobileMenu />
            <NavItem label="Login" to="/login" mobileMenu />
            <NavItem label="Sign Up" to="/signup" mobileMenu primaryBtn />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
