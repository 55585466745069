import axios from "axios";

const getSubscriberFormSettingsUrl = () => {
  return `/api/v1/settings/subscriber_form_settings`;
};

export const getSubscriberFormSetting = () => {
  return axios.get(getSubscriberFormSettingsUrl());
};

export const updateSubscriberFormSetting = (payload) => {
  return axios.put(getSubscriberFormSettingsUrl(), payload);
};
