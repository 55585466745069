import React from "react";

import { LinkButton } from "@/components/commons/HomeFormUI";

import EmailIcon from "@/images/icons/email-icon.svg";

const EmptyPage = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      <div className="flex flex-col items-center justify-center h-[384px] w-full rounded-2xl p-8 space-y-6 border-2 border-dashed border-[#969797]">
        <img src={EmailIcon} height={24} width={24} alt="email icon" />

        <p className="text-[16px] leading-[19.09px] font-soehne-kraftig m-0 text-center">
          There’s nothing here, create your first take now.
        </p>

        <LinkButton to="/takes/new" label="Create a take" />
      </div>
    </div>
  );
};

export default EmptyPage;
