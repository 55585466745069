import * as Yup from "yup";

import { MAX_STRING_LENGTH, MAX_SLUG_LENGTH } from "@/lib/constants";

export const INITIAL_VALUE = {
  display_name: "",
  url_slug: "",
  personal_url: "",
  notification_enabled: false,
  location: "",
  follower_suggestion_ids: [],
};

export const VALIDATION_SCHEMA = Yup.object({
  display_name: Yup.string()
    .required("Display name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Display name must be at most ${MAX_STRING_LENGTH} characters.`,
    ),
  url_slug: Yup.string()
    .required("URL can't be blank.")
    .max(MAX_SLUG_LENGTH, `URL must be at most ${MAX_SLUG_LENGTH} characters.`),
  personal_url: Yup.string()
    .max(
      MAX_STRING_LENGTH,
      `Personal URL must be at most ${MAX_STRING_LENGTH} characters.`,
    )
    .nullable(),
});

export const USER_INITIAL_VALUE = {
  name: "",
  email: "",
};

export const USER_VALIDATION_SCHEMA = Yup.object({
  name: Yup.string()
    .required("Name can't be blank.")
    .max(
      MAX_STRING_LENGTH,
      `Name must be at most ${MAX_STRING_LENGTH} characters.`,
    ),
  email: Yup.string()
    .required("Email can't be blank.")
    .email("Email must be valid.")
    .max(
      MAX_STRING_LENGTH,
      `Email must be at most ${MAX_STRING_LENGTH} characters.`,
    ),
});

export const SUBSCRIBER_FORM_INITIAL_VALUE = {
  show_avatar: "",
  show_display_name: "",
  use_website_font: "",
  display_name_color: false,
  message_color: "",
  background_color: "",
  email_input_placeholder: "",
  submit_button_label: "",
  submit_button_color: "",
  submit_button_text_color: "",
  confirmation_message_title: "",
  message: "",
  confirmation_message: "",
};
