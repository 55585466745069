import React, { useState, useEffect } from "react";
import { Spinner } from "@bigbinary/neetoui";

import AnimatedPage from "@/components/commons/AnimatedPage";
import { getPaidBooths } from "@/apis/settings/paid_booths";
import { showToastrError } from "@/components/commons";

import Table from "./Table";
import EmptyPage from "./EmptyPage";

const Subscriptions = () => {
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalRecords, setTotalRecords] = useState();

  useEffect(() => {
    if (pageIndex) {
      getPaidBoothsResponse();
    }
  }, [pageIndex]);

  const getPaidBoothsResponse = async () => {
    try {
      const { data } = await getPaidBooths(pageIndex);
      setSubscriptionsList(data.subscriptions);
      setTotalRecords(data.total_records);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (listLoader) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <AnimatedPage className="flex flex-col items-center w-full gap-y-8">
      {subscriptionsList.length > 0 ? (
        <Table
          subscriptionsList={subscriptionsList}
          totalRecords={totalRecords}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
          getPaidBoothsResponse={getPaidBoothsResponse}
        />
      ) : (
        <EmptyPage />
      )}
    </AnimatedPage>
  );
};

export default Subscriptions;
