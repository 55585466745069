import React, { useState } from "react";
import classnames from "classnames";
import * as dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import advancedFormat from "dayjs/plugin/advancedFormat";
import ProgressBar from "@ramonak/react-progress-bar";
import { Modal, Tooltip, Typography } from "@bigbinary/neetoui";
import { LinkButton, Button } from "@/components/commons/HomeFormUI";
import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import {
  createStripeSubscription,
  updateStripeSubscription,
} from "@/apis/settings/subscriptions";
import { showToastrError } from "@/components/commons";
import { cancelBilling } from "@/apis/settings/billings";

const DesktopPricing = ({
  activePlan,
  subscriberCount,
  completed,
  aboutPage,
  billing,
  setCancelBillingAlert,
  getBillingResponse,
}) => {
  dayjs.extend(advancedFormat);
  const location = useLocation();
  const stripe_subscription = billing?.stripe_subscription;
  const [btnLoader, setBtnLoader] = useState(false);
  const [priceId, setPriceId] = useState();
  const [planChangeAlertOpen, setPlanChangeAlertOpen] = useState(false);

  const plans = billing?.stripe_plans || [
    {
      serial: 1,
      id: null,
      name: "Soft Lead",
      price: "$0/mo",
      key: "soft",
      limit: 100,
    },
    {
      serial: 2,
      id: null,
      name: "Medium Lead",
      price: "$9/mo",
      key: "medium",
      limit: 1000,
    },
    {
      serial: 3,
      id: null,
      name: "Hard Lead",
      price: "$29/mo",
      key: "hard",
      limit: 5000,
    },
    {
      serial: 4,
      id: null,
      name: "Graphite",
      price: "$79/mo",
      key: "graphite",
      limit: 10000,
    },
    {
      serial: 5,
      id: null,
      name: "Pen Privileges",
      price: "Contact Us",
      key: "custom",
      limit: 100000,
    },
  ];

  const renderMark = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-check"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    );
  };

  const handlePlanChange = () => {
    if (billing.upgraded_to_paid) {
      updateStripeSubscriptionResponse();
    } else {
      createStripeSubscriptionResponse();
    }
  };

  const createStripeSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      const { data } = await createStripeSubscription({
        stripe_subscription: {
          price_id: priceId,
          redirect_to: location.pathname,
        },
      });
      window.location.href = data.checkout_url;
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  const updateStripeSubscriptionResponse = async () => {
    try {
      setBtnLoader(true);
      if (priceId === billing.free_plan_id) {
        await cancelBilling(billing.stripe_subscription.id);
      } else {
        await updateStripeSubscription({
          stripe_subscription: { price_id: priceId },
        });
      }

      setPlanChangeAlertOpen(false);
      getBillingResponse();
    } catch (error) {
      console.log(error);
      showToastrError(error.response.data.errors);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className="flex-col w-full gap-y-8 hidden lg:flex">
      {activePlan && (
        <div className="flex flex-col w-full rounded-lg p-6 space-y-3 bg-white">
          <div className="flex w-full justify-between">
            <div className="flex flex-col shrink-0">
              <h4 className="text-[16px] leading-[19.06px] m-0 font-sf-pro font-semibold mb-1">
                Current plan
              </h4>

              <h2 className="text-[24px] leading-[28.64px] font-soehne-kraftig font-normal m-0">
                {activePlan.name}
              </h2>
            </div>

            {billing.pro_plan_needed && !stripe_subscription && (
              <LinkButton label="Activate" to={billing.checkout_url} />
            )}

            {billing.pro_plan_needed && stripe_subscription?.cancelled_at && (
              <div className="flex flex-col space-y-1">
                {!billing.stripe_subscription_active && (
                  <div className="w-40 flex justify-end w-full">
                    <LinkButton label="Reactivate" to={billing.checkout_url} />
                  </div>
                )}

                {stripe_subscription && (
                  <Tooltip
                    content={`Your subscription has been cancelled, you will be able to send takes until ${stripe_subscription?.current_period_end_date} date. After that date you will either need to resubscribe or reduce your subscriber count`}
                    position="bottom-end"
                  >
                    <p className="text-[9px] text-gray-400 font-bold text-right m-0">
                      {dayjs(
                        stripe_subscription.current_period_start_date,
                      ).format(ORDINALIZED_DATE_FORMAT)}{" "}
                      -{" "}
                      {dayjs(
                        stripe_subscription.current_period_end_date,
                      ).format(ORDINALIZED_DATE_FORMAT)}
                    </p>
                  </Tooltip>
                )}
              </div>
            )}

            {stripe_subscription && !stripe_subscription.cancelled_at && (
              <div className="flex flex-col space-y-1">
                <Button
                  label="Cancel plan"
                  onClick={() => setCancelBillingAlert(true)}
                />

                <p className="text-[9px] text-gray-400 font-bold text-right m-0">
                  {dayjs(stripe_subscription.current_period_start_date).format(
                    ORDINALIZED_DATE_FORMAT,
                  )}{" "}
                  -{" "}
                  {dayjs(stripe_subscription.current_period_end_date).format(
                    ORDINALIZED_DATE_FORMAT,
                  )}
                </p>
              </div>
            )}
          </div>

          <ProgressBar
            completed={completed}
            baseBgColor="#f3eee6"
            height="16px"
            borderRadius="9999px"
            bgColor="#ffc303"
            labelSize="12px"
            labelColor="rgba(29, 31, 33, 1)"
            isLabelVisible={false}
          />

          <div className="flex flex-col w-full space-y-3 divide-y divide-gray-300">
            <h4 className="text-[16px] font-soehne-kraftig font-normal m-0">
              You have {subscriberCount} subscribers
            </h4>

            <p className="text-[16px] leading-[19.06px] pt-3 m-0">
              You are currently on the PencilBooth {activePlan.name} plan, which
              allows full use of the service for your first {activePlan.limit}{" "}
              true fans. Go get 'em!
            </p>
          </div>
        </div>
      )}

      <table
        className={classnames("w-full text-[16px]", {
          "mt-[60px]": !aboutPage,
        })}
      >
        <tr>
          <td></td>
          {plans.map((plan) => {
            return (
              <td
                className={classnames("text-center rounded-t-lg", {
                  "bg-white": activePlan?.key === plan.key,
                })}
                key={plan.key}
              >
                <span className="inline-block font-soehne-kraftig pb-2 pt-6">
                  {plan.name}
                </span>
              </td>
            );
          })}
        </tr>

        <tr>
          <td></td>
          {plans.map((plan) => {
            return (
              <td
                className={classnames("text-center", {
                  "bg-white": activePlan?.key === plan.key,
                })}
                key={plan.key}
              >
                <span
                  className={classnames(
                    "inline-block rounded-full my-2 py-1.5 px-4",
                    {
                      "bg-white": activePlan?.key !== plan.key,
                      "bg-primary": activePlan?.key === plan.key,
                    },
                  )}
                >
                  {plan.price}
                </span>
              </td>
            );
          })}
        </tr>

        {!aboutPage && (
          <tr>
            <td></td>
            {plans.map((plan) => {
              return (
                <td
                  className={classnames("text-center", {
                    "bg-white": activePlan?.key === plan.key,
                  })}
                  key={plan.key}
                >
                  <span className="flex justify-center w-full mt-2 mb-6">
                    {activePlan?.key === plan.key ? (
                      "Current Plan"
                    ) : (
                      <>
                        {plan.key !== "custom" && (
                          <Button
                            label={
                              activePlan.limit < plan.limit
                                ? "Upgrade"
                                : "Downgrade"
                            }
                            onClick={() => {
                              setPriceId(plan.id);
                              setPlanChangeAlertOpen(true);
                            }}
                          />
                        )}
                      </>
                    )}
                  </span>
                </td>
              );
            })}
          </tr>
        )}

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">Subscribers</td>
          <td
            className={classnames("text-center p-4", {
              "bg-[#F3F1EC]": activePlan?.key === "soft",
            })}
          >
            Up to 100
          </td>
          <td
            className={classnames("text-center p-4", {
              "bg-[#F3F1EC]": activePlan?.key === "medium",
            })}
          >
            Up to 1,000
          </td>
          <td
            className={classnames("text-center p-4", {
              "bg-[#F3F1EC]": activePlan?.key === "hard",
            })}
          >
            Up to 5,000
          </td>
          <td
            className={classnames("text-center p-4", {
              "bg-[#F3F1EC]": activePlan?.key === "graphite",
            })}
          >
            Up to 10,000
          </td>
          <td
            className={classnames("text-center p-4", {
              "bg-[#F3F1EC]": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            Over 10,000
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">Image-led composer</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">
            Pencilbooth Homepage Placement
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">
            Enhanced Booth / Link in Bio
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">The B-Roll</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">Schedule Send</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">Recommendation Engine</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">
            Embeddable Subscriber forms
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">Fan Importer</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">Visual Archive</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">Tags</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">Analytics</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">Paid Subscriptions~</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">Polls**</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">
            Hide PencilBooth Branding**
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">GifMaker 3000***</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr
          className={classnames({
            "bg-[#E4E1DA]": !aboutPage,
            "bg-[#0000000d]": aboutPage,
          })}
        >
          <td className="pl-6 py-4 rounded-l-lg">Custom domain support**</td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>

        <tr>
          <td className="pl-6 py-4 rounded-l-lg">Affiliate Program**</td>
          <td
            className={classnames("p-4 rounded-b-lg", {
              "bg-white": activePlan?.key === "soft",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4 rounded-b-lg", {
              "bg-white": activePlan?.key === "medium",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4 rounded-b-lg", {
              "bg-white": activePlan?.key === "hard",
            })}
          >
            <div className="flex justify-center"></div>
          </td>
          <td
            className={classnames("p-4 rounded-b-lg", {
              "bg-white": activePlan?.key === "graphite",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
          <td
            className={classnames("p-4", {
              "bg-white rounded-b-lg": activePlan?.key === "custom",
              "rounded-r-lg": activePlan?.key !== "custom",
            })}
          >
            <div className="flex justify-center">{renderMark()}</div>
          </td>
        </tr>
      </table>

      <Modal
        isOpen={planChangeAlertOpen}
        onClose={() => setPlanChangeAlertOpen(false)}
        className="pb-modal"
      >
        <Modal.Header>
          <h4>Plan Change</h4>
        </Modal.Header>
        <Modal.Body className="space-y-4">
          <Typography style="body2">
            Are you sure to change the plan?
          </Typography>
        </Modal.Body>

        <Modal.Footer className="flex gap-x-2">
          <Button
            label="Yes"
            loading={btnLoader}
            type="submit"
            onClick={() => handlePlanChange()}
          />

          <Button
            label="Cancel"
            onClick={() => setPlanChangeAlertOpen(false)}
            style="modal"
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DesktopPricing;
