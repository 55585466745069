import React from "react";

import LinklistBanner from "@/images/LinklistBanner.png";
import LinklistImage1 from "@/images/LinklistImage1.png";
import LinklistImage2 from "@/images/LinklistImage2.png";

const LinkList = () => {
  return (
    <div className="relative mb-[100px]">
      <div className="flex flex-col items-start space-y-[40px] lg:space-y-[60px] lg:w-[500px] xl:w-[600px]">
        <h4 className="text-2xl font-medium leading-[1.2] font-soehne-kraftig w-full lg:text-5xl lg:leading-[1.2]">
          Create and customize your booth with bio links.
        </h4>
        <p className="w-full text-[19px] leading-[1.6]">
          Easily turn your subscriber form into a powerful link in bio tool and
          manage all your social links in one place. Included in all plans.
        </p>
      </div>

      <div className="relative mt-[400px] mb-[300px] lg:mt-[200px] lg:mb-[250px]">
        <img
          alt="linklist 1"
          src={LinklistImage1}
          className="w-[80vw] max-w-[400px] object-cover rounded-xl absolute right-0 left-0 text-center mx-auto -top-[350px] lg:text-right lg:mr-[50px] xl:mr-[100px] lg:-top-[450px]"
        />
        <img
          alt="linklist banner"
          src={LinklistBanner}
          className="h-[1100px] lg:h-[750px] w-auto object-cover rounded-3xl"
        />
        <img
          alt="linklist 2"
          src={LinklistImage2}
          className="w-[80vw] max-w-[400px] object-cover rounded-xl absolute right-0 left-0 text-center mx-auto -bottom-[350px] lg:text-left lg:ml-[50px] xl:ml-[100px] lg:-bottom-[350px]"
        />
      </div>
    </div>
  );
};

export default LinkList;
