import React from "react";

import { LinkButton } from "@/components/commons/HomeFormUI";

import EmailIcon from "@/images/icons/email-icon.svg";

const EmptyPage = () => {
  return (
    <div className="flex flex-col items-center max-w-[1024px] w-full bg-white rounded-2xl p-6 md:p-10 space-y-6">
      <img src={EmailIcon} height={24} width={24} alt="email icon" />

      <p className="text-[16px] leading-[19.09px] font-soehne-kraftig m-0 text-center">
        There’s nothing here, subcribe your first paid booth now.
      </p>

      <LinkButton to="/featured_booth" label="Subscribe to a booth" />
    </div>
  );
};

export default EmptyPage;
