import React, { useState, useEffect } from "react";
import { Spinner, Toastr, Modal } from "@bigbinary/neetoui";
import { useFormik } from "formik";
import SearchIcon from "@/images/icons/search-icon.svg";
import {
  getDisciplines,
  createDiscipline,
  updateDiscipline,
  destroyDiscipline,
  getDiscipline,
} from "@/apis/super_admin/disciplines";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { Button } from "@/components/commons/SettingsFormUI";
import { Button as ModalButton } from "@/components/commons/AppFormUI";
import NoDataPage from "@/components/commons/NoDataPage";
import FormModal from "./FormModal";
import Table from "./Table";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

const Disciplines = () => {
  const [searchTerm, setSearchTerm] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [disciplineList, setDisciplineList] = useState([]);
  const [listLoader, setListLoader] = useState(true);
  const [totalRecords, setTotalRecords] = useState();
  const [discipline, setDiscipline] = useState();
  const [disciplineModalOpen, setDisciplineModalOpen] = useState(false);
  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);
  const [destroyBtnLoader, setDestroyBtnLoader] = useState(false);
  const [destroyDisciplineAlertOpen, setDestroyDisciplineAlertOpen] =
    useState(false);
  const [disciplineId, setDisciplineId] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: discipline ? discipline : INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      if (discipline) {
        updateDisciplineResponse();
      } else {
        createDisciplineResponse();
      }
    },
  });

  useEffect(() => {
    if (pageIndex) {
      getDisciplineListResponse();
    }
  }, [pageIndex]);

  const getDisciplineListResponse = async (page = pageIndex) => {
    try {
      const { data } = await getDisciplines(page, searchTerm);
      setDisciplineList(data.disciplines);
      setTotalRecords(data.total_records);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createDisciplineResponse = async () => {
    try {
      setSubmitBtnLoader(true);
      const { data } = await createDiscipline({ discipline: formik.values });
      Toastr.success(data.message);
      getDisciplineListResponse();
      setDisciplineModalOpen(false);
      setSubmitBtnLoader(false);
      formik.resetForm();
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const updateDisciplineResponse = async () => {
    try {
      setSubmitBtnLoader(true);
      const { data } = await updateDiscipline(discipline.id, {
        discipline: formik.values,
      });
      Toastr.success(data.message);
      setDisciplineModalOpen(false);
      setSubmitBtnLoader(false);
      getDisciplineListResponse();
      formik.resetForm();
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const getDisciplineResponse = async (disciplineId) => {
    try {
      const { data } = await getDiscipline(disciplineId);
      setDiscipline(data.discipline);
      formik.resetForm({ values: data.discipline })
      setDisciplineModalOpen(true);
      getDisciplineListResponse();
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const destroyDisciplineResponse = async () => {
    try {
      setDestroyBtnLoader(true);
      const { data } = await destroyDiscipline(disciplineId);
      getDisciplineListResponse();
      setDestroyBtnLoader(false);
      setDestroyDisciplineAlertOpen(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      getDisciplineListResponse(1);
    }
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-app--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-app--layout pb-app--layout--croppedWidth flex flex-col items-center w-full">
        <div className="flex flex-col items-center mb-10 max-w-3xl">
          <h1 className="font-soehne-kraftig text-center text-[24px] leading-[28.64px] font-normal m-0">
            Disciplines
          </h1>
        </div>

        <div className="flex w-full">
          <div className="flex items-center w-full gap-x-3 rounded-lg border border-black bg-white px-4 py-3 mb-3 mr-2">
            <div className="w-full flex">
              <img src={SearchIcon} alt="search icon" className="mr-2" />
              <input
                name="search"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full"
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>

          <div>
            <Button
              label="Add new"
              className="w-40 !py-3"
              onClick={() => {
                formik.resetForm({ values: {}});
                setDisciplineModalOpen(true);
              }}
            />
          </div>
        </div>

        {disciplineList.length > 0 ? (
          <Table
            disciplineList={disciplineList}
            totalRecords={totalRecords}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            getDisciplineResponse={getDisciplineResponse}
            destroyBtnLoader={destroyBtnLoader}
            setDestroyDisciplineAlertOpen={setDestroyDisciplineAlertOpen}
            setDisciplineId={setDisciplineId}
          />
        ) : (
          <NoDataPage />
        )}

        <FormModal
          disciplineModalOpen={disciplineModalOpen}
          formik={formik}
          setDisciplineModalOpen={setDisciplineModalOpen}
          btnLoader={submitBtnLoader}
        />

        <Modal
          isOpen={destroyDisciplineAlertOpen}
          onClose={() => setDestroyDisciplineAlertOpen(false)}
          className="pb-alert"
        >
          <Modal.Header>
            <h4>Destroy Discipline</h4>
          </Modal.Header>
          <Modal.Body className="space-y-4">
            <p>Are you sure you want to destroy the discipline?</p>
          </Modal.Body>
          <Modal.Footer className="flex gap-x-2">
            <ModalButton
              label="Yes, Proceed"
              type="submit"
              style="danger"
              onClick={() => destroyDisciplineResponse()}
              loading={destroyBtnLoader}
            />

            <ModalButton
              label="Cancel"
              onClick={() => setDestroyDisciplineAlertOpen(false)}
              style="modal"
            />
          </Modal.Footer>
        </Modal>
      </AnimatedPage>
    </div>
  );
};

export default Disciplines;
