import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import Visa from "@/images/visa.png";
import Amex from "@/images/amex.png";
import Mastercard from "@/images/mastercard.png";
import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import { LinkButton } from "@/components/commons/HomeFormUI";
import { Tag } from "@bigbinary/neetoui";
import { CreditCard } from "@bigbinary/neeto-icons";

const PaymentDetails = ({ billing }) => {
  dayjs.extend(advancedFormat);
  const stripe_subscription = billing.stripe_subscription;

  const cardLogo = () => {
    if (stripe_subscription?.card_brand == "Visa") {
      return <img alt="Visa" className="h-6 w-auto lg:h-8" src={Visa} />;
    } else if (stripe_subscription?.card_brand === "Amex") {
      return <img alt="Amex" className="h-6 w-auto lg:h-8" src={Amex} />;
    } else if (stripe_subscription?.card_brand == "Mastercard") {
      return <img src={Mastercard} className="h-6 w-auto lg:h-9" />;
    } else {
      return <CreditCard />;
    }
  };

  return (
    <div className="flex flex-col rounded-lg bg-white p-6 justify-between">
      <div className="flex flex-col">
        <div className="flex justify-between">
          <h3 className="font-soehne-kraftig mb-4 text-base">
            Payment Details
          </h3>
          {billing.stripe_subscription &&
            !billing.stripe_subscription.cancelled_at && (
              <LinkButton label="Edit" to={billing.checkout_url} />
            )}
        </div>

        {stripe_subscription && (
          <div className="flex">
            {cardLogo()}

            <div className="ml-2">
              <p>•••• •••• •••• {stripe_subscription.card_last4}</p>
              <p className="text-gray-400 font-bold">
                {stripe_subscription?.card_brand} - Expires{" "}
                {stripe_subscription?.card_exp_month} /{" "}
                {stripe_subscription?.card_exp_year}
              </p>
            </div>
          </div>
        )}
      </div>
      <div>
        <p className="text-sm text-gray-400 font-bold">
          Billed on of each month
        </p>
        <p className="text-sm text-gray-400 font-bold">
          Next Billing on{" "}
          {dayjs(stripe_subscription.current_period_end_date).format(
            ORDINALIZED_DATE_FORMAT,
          )}
        </p>

        <p
          style={{
            color: stripe_subscription.cancelled_at ? "#EC4139" : "#00BD4C",
          }}
        >{`${
          stripe_subscription.cancelled_at ? "Inactive" : "Active"
        } - ${dayjs(stripe_subscription.created_at).format(
          ORDINALIZED_DATE_FORMAT,
        )}`}</p>
      </div>
    </div>
  );
};

export default PaymentDetails;
