import React from "react";
import classnames from "classnames";

const Switch = ({
  className = "",
  disabled = false,
  error = "",
  helpText = "",
  ...otherProps
}) => {
  return (
    <div className="flex flex-col w-full gap-y-2">
      <input
        type="checkbox"
        {...otherProps}
        disabled={disabled}
        className={classnames(["pb-settings--switch", className], {
          "!cursor-not-allowed": disabled,
        })}
      />

      {!!helpText && (
        <p className="text-sm leading-5 opacity-60 w-max">{helpText}</p>
      )}

      {!!error && (
        <p className="text-sm leading-5 text-red-500 opacity-60">{error}</p>
      )}
    </div>
  );
};

export default Switch;
