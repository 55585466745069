import React from "react";

const ThreadCard = ({ linkRoll, withLink }) => {
  const renderContent = () => {
    return (
      <div className="flex flex-col w-full max-w-[486px] bg-[#F1F1F1] dark:bg-[#333333] dark:shadow-none shadow-md rounded-lg">
        <div className="flex justify-between items-center p-3">
          <div className="flex items-center space-x-2 text-[#1D1F21] dark:text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              class="bi bi-threads"
              viewBox="0 0 16 16"
            >
              <path d="M6.321 6.016c-.27-.18-1.166-.802-1.166-.802.756-1.081 1.753-1.502 3.132-1.502.975 0 1.803.327 2.394.948s.928 1.509 1.005 2.644q.492.207.905.484c1.109.745 1.719 1.86 1.719 3.137 0 2.716-2.226 5.075-6.256 5.075C4.594 16 1 13.987 1 7.994 1 2.034 4.482 0 8.044 0 9.69 0 13.55.243 15 5.036l-1.36.353C12.516 1.974 10.163 1.43 8.006 1.43c-3.565 0-5.582 2.171-5.582 6.79 0 4.143 2.254 6.343 5.63 6.343 2.777 0 4.847-1.443 4.847-3.556 0-1.438-1.208-2.127-1.27-2.127-.236 1.234-.868 3.31-3.644 3.31-1.618 0-3.013-1.118-3.013-2.582 0-2.09 1.984-2.847 3.55-2.847.586 0 1.294.04 1.663.114 0-.637-.54-1.728-1.9-1.728-1.25 0-1.566.405-1.967.868ZM8.716 8.19c-2.04 0-2.304.87-2.304 1.416 0 .878 1.043 1.168 1.6 1.168 1.02 0 2.067-.282 2.232-2.423a6.2 6.2 0 0 0-1.528-.161" />
            </svg>

            <span className="font-soehne-kraftig">{linkRoll.username}</span>
          </div>
        </div>

        <div className="p-5 bg-[#D5D5D5] dark:bg-[#4F4F4F] text-[#1D1F21] dark:text-white">
          <p className="m-0 text-xs">{linkRoll.description}</p>
        </div>

        <a
          href={linkRoll.formatted_link}
          target="_blank"
          className="text-center p-3"
        >
          <span className="text-[#6EA8FE]">View more on Threads</span>
        </a>
      </div>
    );
  };

  return withLink ? (
    <a href={linkRoll.formatted_link} target="_blank">
      {renderContent()}
    </a>
  ) : (
    renderContent()
  );
};

export default ThreadCard;
