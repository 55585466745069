import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as R from "ramda";
import * as dayjs from "dayjs";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "@bigbinary/neetoui";
import { toast, Zoom } from "react-toastify";

import { useAuthContext } from "@/lib/useAuthContext";
import {
  getTake,
  createTake,
  updateTake,
  fetchStripeSubscription,
} from "@/apis/takes";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import { INITIAL_VALUE, VALIDATION_SCHEMA } from "./constants";

import TakeForm from "./TakeForm";
import SubscriptionModal from "./SubscriptionModal";
import ConfirmationModal from "./ConfirmationModal";
import ScheduleFormModal from "./ScheduleFormModal";

const CreateTake = () => {
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { setHideHeader, setUser, user } = useAuthContext();
  const redirectPath = "/takes";

  const [scheduleFormOpen, setScheduleFormOpen] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [sendConfirmAlertOpen, setSendConfirmAlertOpen] = useState(false);

  const [takeDetail, setTakeDetail] = useState();
  const [takeDetailLoading, setTakeDetailLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);

  const [sendNowBtnLoader, setSendNowBtnLoader] = useState(false);
  const [updateBtnLoader, setUpdateBtnLoader] = useState(false);
  const [draftBtnLoader, setDraftBtnLoader] = useState(false);
  const [scheduleBtnLoader, setScheduleBtnLoader] = useState(false);

  const [btnDisabled, setBtnDisabled] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: takeDetail?.id ? takeDetail : INITIAL_VALUE,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: () => {
      if (formik.values.id) {
        updateTakeResponse();
      } else {
        createTakeResponse();
      }
    },
  });

  useEffect(() => {
    setHideHeader(true);
    getStripeSubscriptionResponse();

    return () => {
      setHideHeader(false);
    };
  }, []);

  useEffect(() => {
    if (!id && user && !formik.values.subject) {
      formik.setFieldValue(
        "subject",
        `${user.display_name} - PencilBooth Take #${user.takes_count + 1}`,
      );
    }
  }, [user]);

  useEffect(() => {
    if (
      id &&
      location.pathname.split("/").pop() === "edit" &&
      takeDetail?.non_editable
    ) {
      navigate(`/takes`);
    }
  }, [takeDetail]);

  useEffect(() => {
    if (id) {
      getTakeResponse();
    }
  }, [id]);

  const activateSpinner = () => {
    if (formik.values.draft) {
      setDraftBtnLoader(true);
    } else if (formik.values.schedule) {
      setScheduleBtnLoader(true);
    } else if (formik.values.update) {
      setUpdateBtnLoader(true);
    } else if (formik.values.send_now) {
      setSendNowBtnLoader(true);
    }
  };

  const resetStateVariables = () => {
    setSendNowBtnLoader(false);
    setUpdateBtnLoader(false);
    setDraftBtnLoader(false);
    setScheduleBtnLoader(false);

    setTakeDetailLoading(false);
    setScheduleFormOpen(false);
    setSendConfirmAlertOpen(false);
    setSendConfirmAlertOpen(false);
  };

  const getTakeResponse = async () => {
    try {
      if (location?.state && location.state.pageLoad == false) {
        setTakeDetailLoading(false);
      } else {
        setTakeDetailLoading(true);
      }
      const { data } = await getTake(id, true);
      setTakeDetail(data.take);
      setTakeDetailLoading(false);
      setBtnDisabled(data.take.under_review || data.take.rejected_at);
    } catch (error) {
      if (error.response.status === 404) {
        navigate("/not_found");
      }
      showToastrError(error.response.data.errors);
    }
  };

  const getStripeSubscriptionResponse = async () => {
    try {
      const { data } = await fetchStripeSubscription();
      setUser((user) => {
        return {
          ...user,
          subscriberSize: data.subscriberSize,
          takes_count: data.takes_count,
          activeStripeSubscription: data.active_stripe_subscription,
          take_header_attachment: data.default_take_header_attachment_url,
          freeSubscriberSize: data.free_subscriber_size,
          paidSubscriberSize: data.paid_subscriber_size,
        };
      });
      setPageLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  const createTakeResponse = async () => {
    try {
      activateSpinner();

      if (formik.values.scheduled_at) {
        formik.values.scheduled_at = dayjs(formik.values.scheduled_at)
          .utc()
          .format();
      }

      const { data } = await createTake({ take: formik.values });
      formik.setFieldValue("id", data.id);
      navigate(`/takes/${data.id}/edit`, {
        state: {
          pageLoad: false,
        },
      });
      toast("💾", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Zoom,
        closeButton: false,
      });
    } catch (error) {
      showToastrError(error.response.data.errors);
    } finally {
      resetStateVariables();
    }
  };

  const updateTakeResponse = async () => {
    try {
      activateSpinner();

      if (formik.values.scheduled_at) {
        formik.values.scheduled_at = dayjs(formik.values.scheduled_at)
          .utc()
          .format();
      }
      const { data } = await updateTake(formik.values.id, {
        take: { ...formik.values, cover_image_attributes: {} },
      });

      if (data.take.sent_at && !formik.values.update) {
        navigate(`/takes`);
        toast("Take Sent!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
        });
      }

      if (data.take.under_review) {
        navigate(`/takes`);
        showToastrError(
          "Hello! We’re just manually reviewing your take at the moment. Please hold tight take will be sent shortly.",
        );
      }
    } catch (error) {
      formik.setFieldValue("send_now", false);
      formik.setFieldValue("schedule", false);
      formik.setFieldValue("scheduled_at", null);
      formik.setFieldValue("draft", true);
      showToastrError(error.response.data.errors);
    } finally {
      resetStateVariables();
    }
  };

  const handleSubmitWithStatus = () => {
    if (formik.values.schedule) {
      handleSubmit("schedule");
    } else if (formik.values.send_now) {
      handleSubmit("update");
    } else {
      handleSubmit("draft");
    }
  };

  const handleSubmit = async (action) => {
    switch (action) {
      case "draft":
        await formik.setFieldValue("draft", true);
        await formik.setFieldValue("schedule", false);
        await formik.setFieldValue("send_now", false);
        await formik.setFieldValue("update", false);
        break;
      case "schedule":
        await formik.setFieldValue("draft", false);
        await formik.setFieldValue("schedule", true);
        await formik.setFieldValue("send_now", false);
        await formik.setFieldValue("update", false);
        break;
      case "update":
        await formik.setFieldValue("draft", false);
        await formik.setFieldValue("schedule", false);
        await formik.setFieldValue("send_now", true);
        await formik.setFieldValue("update", true);
        break;
      default:
        await formik.setFieldValue("draft", false);
        await formik.setFieldValue("schedule", false);
        await formik.setFieldValue("send_now", true);
        await formik.setFieldValue("update", false);
    }

    formik.handleSubmit();
  };

  const handleCancel = () => {
    navigate(redirectPath, { replace: true });
  };

  const handleSchedule = () => {
    if (!formik.values.schedule) {
      if (user?.activeStripeSubscription) {
        if (
          !R.isEmpty(formik.values.subject) &&
          !R.isNil(formik.values.subject)
        ) {
          setScheduleFormOpen(true);
        }
      } else {
        setSubscriptionModalOpen(true);
      }
    } else {
      formik.setFieldValue("schedule", false);
      formik.setFieldValue("scheduled_at", null);
      handleSubmit("draft");
    }
  };

  const handleDraft = () => {
    handleSubmit("draft");
  };

  const handleUpdate = () => {
    handleSubmit("update");
  };

  const handleSendNow = () => {
    if (user?.activeStripeSubscription) {
      if (R.isEmpty(formik.values.subject)) {
        handleSubmit();
      } else {
        setSendConfirmAlertOpen(true);
      }
    } else {
      setSubscriptionModalOpen(true);
    }
  };

  if (takeDetailLoading || pageLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <AnimatedPage>
      <div className="h-screen w-full overflow-hidden">
        <TakeForm
          takeDetail={takeDetail}
          formik={formik}
          handleCancel={handleCancel}
          handleDraft={handleDraft}
          handleSchedule={handleSchedule}
          handleSendNow={handleSendNow}
          handleUpdate={handleUpdate}
          draftBtnLoader={draftBtnLoader}
          updateBtnLoader={updateBtnLoader}
          location={location}
          handleSubmitWithStatus={handleSubmitWithStatus}
          btnDisabled={btnDisabled}
          takeDetailLoading={takeDetailLoading}
          setTakeDetail={setTakeDetail}
          user={user}
        />
      </div>

      <SubscriptionModal
        subscriptionModalOpen={subscriptionModalOpen}
        setSubscriptionModalOpen={setSubscriptionModalOpen}
      />

      <ScheduleFormModal
        formik={formik}
        scheduleFormOpen={scheduleFormOpen}
        setScheduleFormOpen={setScheduleFormOpen}
        scheduleBtnLoader={scheduleBtnLoader}
        handleSubmit={handleSubmit}
      />

      <ConfirmationModal
        sendConfirmAlertOpen={sendConfirmAlertOpen}
        setSendConfirmAlertOpen={setSendConfirmAlertOpen}
        sendNowBtnLoader={sendNowBtnLoader}
        handleSubmit={handleSubmit}
        subscribersType={formik.values.subscribers_type}
      />
    </AnimatedPage>
  );
};

export default CreateTake;
