import axios from "axios";

const getTagsUrl = () => {
  return `/api/v1/tags`;
};

export const getTags = (searchParams, pageIndex) => {
  let searchQuery = ""
  if (searchParams?.length > 0) searchQuery = `q[name_i_cont_any]=${searchParams}`;

  return axios.get(`${getTagsUrl()}?page=${pageIndex}&${searchQuery}`);
};

export const createTag = (payload) => {
  return axios.post(getTagsUrl(), payload);
};
