import axios from "axios";

const getUsersUrl = (id) => {
  if (id) {
    return `/api/v1/super_admin/users/${id}`;
  }

  return `/api/v1/super_admin/users`;
};

export const getUsers = (
  pageIndex,
  searchTermString,
  searchTermNumber,
  minSearchDate,
  maxSearchDate,
) => {
  let searchQuery = "";

  if (searchTermString?.length > 0) searchQuery = `search_string=${searchTermString}`;
  if (searchTermNumber?.length > 0) searchQuery += `&search_number=${searchTermNumber}`;
  if (minSearchDate) searchQuery += `&search_date_gteq=${minSearchDate}`;
  if (maxSearchDate) searchQuery += `&search_date_lteq=${maxSearchDate}`;

  return axios.get(`${getUsersUrl()}?page=${pageIndex}&${searchQuery}`);
};

export const getUser = (userId) => {
  return axios.get(getUsersUrl(userId));
};

export const updateUser = (userId, payload) => {
  return axios.put(getUsersUrl(userId), payload);
};

export const destroyUser = (userId) => {
  return axios.delete(getUsersUrl(userId));
};
