import axios from "axios";

const getSubscriptionsUrl = (id) => {
  if (id) {
    return `/api/v1/subscriptions/${id}`;
  }

  return `/api/v1/subscriptions`;
};

export const getSubscriptionList = (pageIndex, searchTerm, status, emailStatus) => {
  let searchQuery = "";

  if (searchTerm?.length > 0) searchQuery = `q[unaccented_email_i_cont_any]=${searchTerm}`;
  if (status?.length > 0) searchQuery += `&${status}`
  if (emailStatus?.length > 0) searchQuery += `&last_email_status=${emailStatus}`

  return axios.get(`${getSubscriptionsUrl()}?page=${pageIndex}&${searchQuery}`);
};

export const createSubscription = (payload) => {
  return axios.post(getSubscriptionsUrl(), payload);
};

export const updateSubscription = (subscriptionId, payload) => {
  return axios.put(getSubscriptionsUrl(subscriptionId), payload);
};

export const exportSubscriptionList = () => {
  return axios.get(`${getSubscriptionsUrl()}/export`);
};

export const importSubscriptionList = (payload) => {
  return axios.post(`${getSubscriptionsUrl()}/import`, payload);
};

export const downloadCsv = () => {
  return axios.get(`${getSubscriptionsUrl()}/csv_download`);
};
