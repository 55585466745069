import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

const LinkButton = ({
  className = "",
  label = "Button",
  style = "primary",
  to,
  rounded,
  fullWidth,
  cta,
  ...otherProps
}) => {
  const btnClassNames = classnames(
    "pb-home--btn",
    {
      "pb-home--btn--primary": style === "primary",
      "pb-home--btn--secondary": style === "secondary",
      "pb-home--btn--link": style === "link",
      "pb-home--btn--danger": style === "danger",
    },
    {
      "pb-home--btn--rounded": rounded,
    },
    {
      "pb-home--btn--fullWidth": fullWidth,
    },
    {
      "pb-home--btn--cta": cta,
    },
  );

  return (
    <Link
      to={to}
      className={classnames([btnClassNames, className])}
      {...otherProps}
    >
      {label}
    </Link>
  );
};

export default LinkButton;
