import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { ORDINALIZED_DATE_FORMAT } from "@/lib/constants";
import { amountInCurrency } from "@/lib/utils";
import { RightArrow } from "@bigbinary/neeto-icons";

const BillingHistory = ({ billing }) => {
  dayjs.extend(advancedFormat);

  return (
    <div className="flex flex-col rounded-lg bg-white p-6 h-96">
      <h3 className="font-soehne-kraftig text-base mb-9">Billing History</h3>

      <div className="overflow-auto">
        <table className="w-full">
          {billing?.stripe_invoices.map((invoice) => {
            return (
              <tbody key={invoice.id}>
                <tr>
                  <td className="flex justify-between mx-3 mb-2">
                    <div>
                      {dayjs(invoice.date).format(ORDINALIZED_DATE_FORMAT)}
                    </div>
                    <div className="flex">
                      <p className="mr-7 text-gray-400 font-bold">
                        {amountInCurrency(invoice.amount)}
                      </p>
                      <a href={invoice.stripe_url} target="_blank">
                        <div className="flex items-center">
                          <p className="mr-2">Invoice </p>
                          <RightArrow size={17} />
                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default BillingHistory;
