import React, { useState, useEffect } from "react";
import { Spinner } from "@bigbinary/neetoui";

import { getTakes } from "@/apis/super_admin/takes";
import { showToastrError } from "@/components/commons";
import AnimatedPage from "@/components/commons/AnimatedPage";
import NoDataPage from "@/components/commons/NoDataPage";

import Table from "./Table";

const UnderReviewTakes = () => {
  const [takeList, setTakeList] = useState([]);
  const [listLoader, setListLoader] = useState(true);

  useEffect(() => {
    getTakeListResponse();
  }, []);

  const getTakeListResponse = async () => {
    try {
      const { data } = await getTakes();
      setTakeList(data.takes);
      setListLoader(false);
    } catch (error) {
      showToastrError(error.response.data.errors);
    }
  };

  if (listLoader) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="pb-app--layout-wrapper flex flex-col items-center w-full pt-[64px] lg:pt-[98px]">
      <AnimatedPage className="pb-app--layout pb-app--layout--croppedWidth flex flex-col items-center w-full">
        <div className="flex flex-col items-center mb-10 max-w-3xl">
          <h1 className="font-soehne-kraftig text-center text-[24px] leading-[28.64px] font-normal m-0">
            Under Review Takes
          </h1>
        </div>

        {takeList.length > 0 ? <Table takeList={takeList} /> : <NoDataPage />}
      </AnimatedPage>
    </div>
  );
};

export default UnderReviewTakes;
