import React, { useState, useEffect } from "react";

import classnames from "classnames";
import { isEmpty } from "ramda";

import Item from "./Item";

const ACCORDION_STYLES = {
  primary: "primary",
  secondary: "secondary",
};

const Accordion = ({
  children,
  accordionTab = null,
  padded = false,
  style = ACCORDION_STYLES.primary,
  className = "",
}) => {
  const [openTab, setOpenTab] = useState(accordionTab);

  useEffect(() => {
    setOpenTab(accordionTab);
  }, [accordionTab]);

  return (
    <div
      className={classnames({
        "pb-accordions-outer-wrapper": padded,
        "bg-gray-100": style === ACCORDION_STYLES.secondary,
        [className]: className,
      })}
    >
      {React.Children.map(children, (child, index) => {
        const isSingleOrLastChild =
          isEmpty(children) || index === children.length - 1;

        return React.cloneElement(child, {
          id: index,
          key: index,
          isOpen: openTab === index,
          className: classnames(child.props.className, {
            "pb-accordion__wrapper--last-item": isSingleOrLastChild,
          }),
          onClick: () => {
            setOpenTab(openTab === index ? null : index);
            child.props.onClick && child.props.onClick();
          },
        });
      })}
    </div>
  );
};

Accordion.Item = Item;

export default Accordion;
