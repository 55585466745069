import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import FacebookIcon from "@/images/icons/facebook-icon.svg";
import InstagramIcon from "@/images/icons/instagram-icon.svg";
import YoutubeIcon from "@/images/icons/youtube-icon.svg";
import DribbleIcon from "@/images/icons/dribble-icon.svg";
import TwitterIcon from "@/images/icons/twitter-icon.svg";
import BehanceIcon from "@/images/icons/behance-icon.svg";
import ThreadsIcon from "@/images/icons/threads-icon.svg";
import TiktokIcon from "@/images/icons/tiktok-icon.svg";

const UserLink = ({ link }) => {
  return (
    <Link
      to={link.url}
      className="font-medium leading-6 px-12 py-3 rounded-3xl flex justify-center w-full bg-[#F5F5F5] link-tree-tag text-center"
      onClick={() => window.scrollTo(0, 0)}
    >
      {link.name}
    </Link>
  );
};

const EmptyUserLink = () => {
  return (
    <div className="font-medium leading-6 px-12 py-3 rounded-3xl flex justify-center w-full bg-[#F5F5F5] link-tree-tag text-center h-[48px]"></div>
  );
};

const SocialLink = ({ link, icon }) => {
  return (
    <Link
      to={link}
      className="w-12 h-12 overflow-hidden bg-[#F5F5F5] rounded-full flex justify-center items-center profile-link"
    >
      <img src={icon} alt="icon" class="h-6 w-6" />
    </Link>
  );
};

const EmptySocialLink = () => {
  return (
    <div className="w-12 h-12 overflow-hidden bg-[#F5F5F5] rounded-full flex justify-center items-center profile-link"></div>
  );
};

const LinkCard = ({
  user,
  hideControls = true,
  setSocialModalOpen = () => null,
  setLinkTreeModalOpen = () => null,
  brandingPage,
  mobileForm,
  subscriberForm,
}) => {
  return (
    <div
      className={classnames(
        "flex flex-col items-center border-[#E8E8E8] gap-y-12 px-6 sm:px-12",
        {
          "!px-6": mobileForm,
          "border-t py-12":
            brandingPage ||
            user.link_trees.length > 0 ||
            user.social_links_present,
          "pt-6 pb-12": brandingPage || user.link_trees.length === 0,
          "!pt-12 pb-12":
            brandingPage ||
            (user.link_trees.length === 0 && user.social_links_present),
        },
      )}
    >
      {user.link_trees.length > 0 ? (
        <div className="flex flex-col items-center gap-y-3 w-full">
          {user.link_trees.map((link, index) => (
            <UserLink link={link} key={index} />
          ))}

          {!hideControls && (
            <span
              className="text-sm leading-[24px] underline cursor-pointer"
              onClick={() => setLinkTreeModalOpen(true)}
            >
              Edit bio links
            </span>
          )}
        </div>
      ) : (
        !subscriberForm && (
          <div className="flex flex-col items-center gap-y-3 w-full">
            <EmptyUserLink />
            <EmptyUserLink />

            {!hideControls && (
              <span
                className="text-sm leading-[24px] underline cursor-pointer"
                onClick={() => setLinkTreeModalOpen(true)}
              >
                Add bio links
              </span>
            )}
          </div>
        )
      )}

      {user.social_links_present ? (
        <div className="flex flex-col items-center gap-y-4">
          <div className="flex justify-center flex-wrap gap-6">
            {user.facebook_url && (
              <SocialLink
                link={`https://facebook.com/${user.facebook_url}`}
                icon={FacebookIcon}
              />
            )}
            {user.instagram_url && (
              <SocialLink
                link={`https://instagram.com/${user.instagram_url}`}
                icon={InstagramIcon}
              />
            )}
            {user.you_tube_url && (
              <SocialLink
                link={`https://youtube.com/channel/${user.you_tube_url}`}
                icon={YoutubeIcon}
              />
            )}
            {user.dribble_url && (
              <SocialLink
                link={`https://dribbble.com/${user.dribble_url}`}
                icon={DribbleIcon}
              />
            )}
            {user.twitter_url && (
              <SocialLink
                link={`https://twitter.com/${user.twitter_url}`}
                icon={TwitterIcon}
              />
            )}
            {user.behance_url && (
              <SocialLink
                link={`https://behance.net/${user.behance_url}`}
                icon={BehanceIcon}
              />
            )}
            {user.threads_url && (
              <SocialLink
                link={`https://threads.net/${user.threads_url}`}
                icon={ThreadsIcon}
              />
            )}
            {user.tiktok_url && (
              <SocialLink
                link={`https://tiktok.com/${user.tiktok_url}`}
                icon={TiktokIcon}
              />
            )}
          </div>

          {!hideControls && (
            <span
              className="text-sm leading-[24px] underline cursor-pointer"
              onClick={() => setSocialModalOpen(true)}
            >
              Edit social links
            </span>
          )}
        </div>
      ) : (
        !subscriberForm && (
          <div className="flex flex-col items-center gap-y-4">
            <div className="flex justify-center flex-wrap gap-6">
              <EmptySocialLink />
              <EmptySocialLink />
              <EmptySocialLink />
            </div>

            {!hideControls && (
              <span
                className="text-sm leading-[24px] underline cursor-pointer"
                onClick={() => setSocialModalOpen(true)}
              >
                Add social links
              </span>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default LinkCard;
