import React from "react";

import AnimatedPage from "@/components/commons/AnimatedPage";
import Footer from "@/components/commons/Footer";
import Header from "@/components/commons/Header";

const PrivacyPolicy = () => (
  <div className="pb-home--layout-wrapper flex flex-col items-center w-full bg-secondary">
    <Header hideSearch />

    <AnimatedPage className="pb-home--layout flex flex-col items-center w-full space-y-12 md:space-y-20">
      <h2 className="text-[28px] lg:text-[42px] font-medium leading-snug font-soehne-kraftig text-center">
        Privacy Policy
      </h2>
      <div className="flex flex-col gap-y-6">
        <p className="text-sm font-normal leading-normal md:text-base">
          This Privacy Policy details how Pencilbooth (ABN 27 628 752 705)
          (referred to in this policy as “<strong>we</strong>”, “
          <strong>us</strong>” or “<strong>our</strong>”) use, collect, store,
          and disclose information supplied by you to us through your use of the
          Pencilbooth application and associated products and services,
          including our website located at&nbsp;
          <a
            className="underline"
            href="/"
            target="undefined"
            rel="noopener noreferrer"
            data-href=""
          >
            https://pencilbooth.com/
          </a>
          &nbsp;(referred to in this policy collectively, the “
          <strong>Services</strong>”).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          This Privacy Policy details how we comply with the&nbsp;
          <em>Privacy Act 1988</em>&nbsp;(Cth) (“
          <strong>Privacy Act</strong>”), the Australian Privacy Principles (“
          <strong>APPs</strong>”), and, to the extent that it applies, the EU
          General Data Protection Regulation 2016/679 (“
          <strong>GDPR</strong>”).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We may amend this Privacy Policy from time to time by posting an
          updated Privacy Policy on our website, and you acknowledge that we do
          not have any obligation to provide you with individual notice of such
          changes. Your continued use of the Services following the publication
          of any amended Privacy Policy will be taken as your acceptance of that
          amended Privacy Policy, except where we are otherwise required by law
          to seek your direct consent.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          <strong>
            Please read this Privacy Policy carefully before using the Services.
            If you do not agree with any part of this Privacy Policy, please do
            not use the Services.
          </strong>
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          <strong>
            Your Mailing List is your property, and will remain your property
            even if you decide to stop using our Services. Pencilbooth will not
            use your Mailing List for any direct marketing communications, and
            will only have access to your Mailing List for the purpose of
            providing technical support and the Services to you. If you have any
            questions about how we collect and use your data (including Mailing
            Lists), please email our Privacy Officer as per the details at the
            end of this policy.
          </strong>
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>WHAT INFORMATION DO WE COLLECT?</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          This Privacy Policy covers all information collected by us via your
          use of the Services. The information we may collect from you can be
          broken down into the following types:
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          “<strong>Personal Information</strong>” means information that can be
          used to personally identify you such as your name, login details,
          email address, profile pictures, external URLs/links. We do not
          knowingly collect or process the Personal Information of anyone under
          the age of 18 without the express consent of their parent or guardian.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          “<strong>Usage Information</strong>” means anonymous aggregate data
          that may be automatically collected through your use of the Services.
          This includes information that identifies your device, your operating
          system, your IP address, and dates and times that you access and use
          the Services. This information is used to resolve any technical issues
          that may arise, or for statistical analysis to help us to improve the
          Services.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          The GDPR recognises that Usage Information, whilst for the most part
          anonymous, can be cumulatively used to directly or indirectly identify
          you. Usage Information that can be used to identify you in any way,
          together with your Personal Information, will collectively be referred
          to in this Privacy Policy as “<strong>Personal Data</strong>”.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>HOW WE COLLECT YOUR INFORMATION</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          Personal Information may be collected directly from you when you:
        </p>
        <ul className="px-6 text-sm list-disc">
          <li className="py-1">buy our products or services;</li>
          <li className="py-1">create a Pencilbooth account;</li>
          <li className="py-1">contact us via our website, phone or email;</li>
          <li className="py-1">
            complete a survey or questionnaire provided by us; or
          </li>
          <li className="py-1">
            access, browse, use, or otherwise interact with the application or
            website.
          </li>
        </ul>
        <p className="text-sm font-normal leading-normal md:text-base">
          It is your choice to provide Personal Information to us. Wherever it
          is lawful and practicable, you have the option not to identify
          yourself when interacting with us. Please be aware that it may be
          necessary for us to collect your Personal Information to enable us to
          provide the Services to you. As such, if you do not wish to provide
          your Personal Information, this may limit our ability to provide the
          Services to you.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We may collect Personal Data about you from sources other than you
          directly. Examples include where:
        </p>
        <ul className="px-6 text-sm list-disc">
          <li className="py-1">
            you have consented for a third party to share information with us
            (including by subscribing to a Mailing List hosted by us);
          </li>
          <li className="py-1">
            where we are authorised by law to collect the information from a
            third party;
          </li>
          <li className="py-1">
            where it is unreasonable or impracticable to collect the information
            from you personally.
          </li>
        </ul>
        <p className="text-sm font-normal leading-normal md:text-base">
          If we collect your Personal Data from third parties in circumstances
          where you may not be aware that we have collected such Personal Data,
          we will take reasonable steps to notify you of the collection and
          circumstances surrounding the collection.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>COOKIE POLICY</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          We may from time to time collect anonymous Usage Information from the
          Services, by utilising cookies, pixel tags and other tracking
          technologies (collectively “<strong>Cookies</strong>”). Cookies are
          small packets of data that are downloaded onto your device when you
          access a website. Cookies hold specific information that helps a
          website ‘remember’ your actions and preferences over time. These are
          the types of Cookies that we may use to operate our Services:
        </p>
        <ul className="px-6 text-sm list-disc">
          <li className="py-1">
            Strictly Necessary Cookies – these Cookies are essential to ensure
            that the Services work correctly, and record information that allows
            you to move around our applications and websites and navigate their
            features;
          </li>
          <li className="py-1">
            Performance Cookies – these Cookies collect information about how
            you use the Services, such as how often you access the Services and
            if you encounter any errors;
          </li>
          <li className="py-1">
            Functionality Cookies – these Cookies allow the Services to remember
            the choices you make to provide a more personalised experience;
          </li>
          <li className="py-1">
            Targeting/Advertising Cookies – these Cookies deliver targeted
            advertising to you based on your interests and use of the Services.
          </li>
        </ul>
        <p className="text-sm font-normal leading-normal md:text-base">
          Cookies can stay on your device temporarily (“
          <strong>Session Cookies</strong>”) or until you manually delete them
          (“<strong>Persistent Cookies</strong>”). You can adjust your browser
          settings at any time to block Cookies, however please note that doing
          so may limit our ability to provide the Services to you in a fully
          operational form.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          To request a full list of the individual Cookies and tracking
          technologies we use, please email our Privacy Officer (per the details
          at the end of this Privacy Policy).
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>THIRD PARTY SITES</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          The Services may contain plug-ins and links to third party sites to
          enhance your user experience (“
          <strong>Third Party Sites</strong>”). We do not have access to, or
          control over, the technologies that third parties (including the Third
          Party Sites) may use to collect information about you. Please refer
          directly to the privacy policies and statements of the operator of any
          Third Party Sites to obtain information regarding their data
          collection, use, and disclosure policies. We disclaim all liability in
          connection with the services of any Third Party Sites integrated or
          otherwise linked to the Services, and we encourage you to reach out to
          them directly should you have any questions in connection with their
          services.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>HOW WE USE YOUR INFORMATION</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          You agree that we may use your Personal Data for legitimate purposes
          including, but not limited to, the following:
        </p>
        <ul className="px-6 text-sm list-disc">
          <li className="py-1">to confirm your identity;</li>
          <li className="py-1">
            to provide our products and/or services to you;
          </li>
          <li className="py-1">to respond to requests submitted by you;</li>
          <li className="py-1">
            to maintain and improve the functionality of the Services;
          </li>
          <li className="py-1">
            to prevent, detect and investigate potential illegal activities,
            security breaches and fraud;
          </li>
          <li className="py-1">
            to comply with legal and regulatory requirements;
          </li>
          <li className="py-1">
            to contact you via email or other instant messaging platforms to
            provide you with periodical promotional messages about new products
            or other information which we think you may find interesting, only
            where you have opted in to receiving such communication and until
            you ‘opt out’.
          </li>
        </ul>
        <p className="text-sm font-normal leading-normal md:text-base">
          For the avoidance of doubt, we will only use your Personal Data for
          purposes that you would reasonably expect us to use your Personal Data
          for in connection with providing the Services to you, or where we are
          required by law to collect your Personal Data.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We will retain your Personal Data for the period necessary to fulfill
          the purposes outlined in this Privacy Policy unless a longer retention
          period is required or permitted by law. How long we retain your
          Personal Data depends on the type of data and the legitimate purpose
          for which we process and/or retain the data.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          ‘Opt Out’
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          We recognise your right under the&nbsp;<em>Spam Act 2003</em>
          &nbsp;(Cth) and the GDPR to opt out from direct marketing, and as such
          these consents can be modified at any time by emailing our Privacy
          Officer or clicking unsubscribe on any direct marketing
          communications.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          Please note certain non-marketing related correspondence from us,
          including messages relating to payment, will be automatically sent to
          you by virtue of your use of the Services and you may not have the
          option to unsubscribe from receiving this correspondence.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>WHO WE DISCLOSE YOUR INFORMATION TO</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          You agree and consent to us disclosing your Personal Data to:
        </p>
        <ul className="px-6 text-sm list-disc">
          <li className="py-1">
            our directors, officers, employees, consultants, contractors and
            agents;
          </li>
          <li className="py-1">
            our Related Bodies Corporate (as that term is defined in the&nbsp;
            <em>Corporation Act 2001</em>&nbsp;(Cth) or other entities owned
            and/or controlled by our directors and/or shareholders;
          </li>
          <li className="py-1">potential investors and financiers;</li>
          <li className="py-1">
            debt collection agencies and credit reporting bodies;
          </li>
          <li className="py-1">
            external counsel for the purpose of obtaining professional advice;
          </li>
          <li className="py-1">
            service providers with whom we have entered into an agreement with
            to help us provide the Services, including marketing agencies,
            financial service providers, and technical support;
          </li>
          <li className="py-1">
            any third party with your express approval; and
          </li>
          <li className="py-1">
            where we are required to disclose such information by law or to
            otherwise prevent harm.
          </li>
        </ul>
        <p className="text-sm font-normal leading-normal md:text-base">
          We will take reasonable steps to ensure that these third parties are
          bound by Australian privacy laws.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          You can withdraw your consent for us to share your Personal Data with
          third parties at any time by emailing our Privacy Officer (per the
          details at the end of this Privacy Policy), but please note that
          withdrawal of such consents may affect your ability to access and use
          the Services.
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>YOUR RIGHTS TO YOUR INFORMATION</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          You have a general right to access any Personal Information that is
          held about you by us, unless a valid exception applies. You can
          request this at any time by contacting our Privacy Officer. Please
          note that we cannot modify your Personal Information. You can modify
          your own information at any time in the Pencilbooth application or by
          emailing the Privacy Officer (per the details at the end of this
          Privacy Policy).
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          You acknowledge and agree that you remain solely responsible for
          maintaining the truth, accuracy, and completeness of your information
          at all times, and we shall have no liability to you or any third party
          arising from your failure to do the same.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          In accordance with the GDPR, we acknowledge the additional rights of
          EU subjects to:
        </p>
        <ul className="px-6 text-sm list-disc">
          <li className="py-1">
            have their data erased that is no longer being used for a legitimate
            purpose;
          </li>
          <li className="py-1">
            request a copy of all Personal Data held about them in a readable
            format, along with supplementary information to verify that such
            Personal Data is being processed lawfully; and
          </li>
          <li className="py-1">
            request restricted processing of their Personal Data whilst any
            complaints or concerns are being resolved.
          </li>
        </ul>
        <p className="text-sm font-normal leading-normal md:text-base">
          To erase, request, or restrict processing of your Personal Data,
          please email the Privacy Officer (per the details at the end of this
          Privacy Policy).
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>SECURITY</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          We protect your Personal Data through technical security measures i.e.
          firewalls, encryption that limit the risk of loss, disclosure, or
          unauthorised access. No security measures are, however, 100% secure so
          we cannot guarantee the security of your information or data at any
          time. To the extent permitted by law, we accept no liability for any
          breach of security, or direct hacking of our security measures, or any
          unintentional disclosure, loss or misuse of any information or data,
          or for the actions of any third parties that may obtain any
          information or data.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          Notwithstanding the above, we acknowledge our obligation to report any
          data breach that is likely to risk the rights and freedoms of natural
          persons to the Australian Information Commissioner and, where our data
          breach involves the information of EU subjects, report to the European
          Data Protection Supervisor. We will also inform you, where possible,
          if your data has been breached in the circumstance where it poses a
          risk of serious harm or your rights and freedoms.
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          We also train our personnel who may have access to your Personal Data
          about this Privacy Policy and our obligations under the Privacy Act,
          APPs, and GDPR. For more information on our internal policies, email
          the Privacy Officer (per the details at the end of this Privacy
          Policy).
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>OVERSEAS DISCLOSURE</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          We may, in the course of providing the services to you, transfer your
          Personal Data to overseas countries that are deemed by the EU
          Commission as having an ‘adequate’ level of Personal Data protection.
          Where we transfer data to a third party in a country where no adequacy
          decision has been made, we will take reasonable steps to ensure person
          or entity handling your data in those countries are bound under
          contract to meet the requirements of the Privacy Act, APPs, and GDPR
          (as applicable).
        </p>
        <h5 className="mt-10 text-base font-soehne-kraftig md:text-lg">
          <strong>CONTACT</strong>
        </h5>
        <p className="text-sm font-normal leading-normal md:text-base">
          Thank you for taking the time to read our Privacy Policy. If you have
          any questions regarding our Privacy Policy, you can contact our
          Privacy Officer at:
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          Pencilbooth (ABN 27 628 752 705)
          <br />
          Att: Privacy Officer
          <br />
          52 Budd Street
          <br />
          Collingwood
          <br />
          Victoria 3066
          <br />
          Email:&nbsp;
          <a
            className="underline"
            href="mailto:privacy@pencilbooth.com"
            target="undefined"
            rel="noopener noreferrer"
          >
            privacy@pencilbooth.com
          </a>
        </p>
        <p className="text-sm font-normal leading-normal md:text-base">
          If you are not satisfied with our handling of your Personal Data, or
          have any other concern over our Privacy Policy, then you may lodge a
          formal complaint with the Office of the Australian Information
          Commissioner (for more information, please see&nbsp;
          <a
            className="underline"
            href="http://www.oaic.gov.au/"
            target="undefined"
            rel="noopener noreferrer"
          >
            www.oaic.gov.au
          </a>
          ) or with the European Data Protection Supervisor (for more
          information, please see&nbsp;
          <a
            className="underline"
            href="https://edps.europa.eu/"
            target="undefined"
            rel="noopener noreferrer"
          >
            https://edps.europa.eu
          </a>
          ).
        </p>
      </div>
      <Footer />
    </AnimatedPage>
  </div>
);

export default PrivacyPolicy;
